import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cookies } from "react-cookie-consent";
import { toast } from 'react-toastify';
import axios from '../api/axios';
import axiosOriginal from "axios";
import useProfile from './useProfile';

const useGetProfile = () => {
  const profile = useProfile()
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getProfile = async () => {
    setLoading(true);

    const bearerToken = Cookies.get('token');
    const config = {
      headers: { Authorization: `Bearer ${bearerToken}` }
    };

    try {
      const response = await axios.get('/user/profile', config);
      profile?.setAccount(response.data);

    } catch (error: any) {
      if (error.response) {
        if(axiosOriginal.isAxiosError(error)) {
          switch (error.response.status) {
            case 401:
              // Unauthorized error
              toast.error(error.response.data.error || "Benutzerauthentifizierung erforderlich. Bitte melden Sie sich erneut an.", {
                position: toast.POSITION.TOP_RIGHT
              });
              navigate("/login");
              break;
            case 404:
              // Not Found error
              toast.error(error.response.data.error || "404 - Not Found", {
                position: toast.POSITION.TOP_RIGHT
              });
              break;
            default:
              toast.error(error.response.data.error || "Ein unerwarteter Fehler ist aufgetreten.", {
                position: toast.POSITION.TOP_RIGHT
              });
              break;
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return { profile, loading, getProfile };
};

export default useGetProfile;