import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
  useJsApiLoader
} from "@react-google-maps/api";
import { useState, useEffect } from "react";
import { TamburiBox as TamburiBoxInterface } from "../interfaces/TamburiBox";
import axiosOriginal from "axios";
import MarkerImg from '../../images/marker.png';
import MarkerSelectedImg from '../../images/marker_selected.png';
import startMarker from '../../images/my-pin.png';

interface MapComponentProps {
  address: string
  city: string
  streetData: {address: string, lat: number, lon: number},
  setMarker: (marker: SelectedMarker) => void
}

interface SelectedMarker {
  id: number,
  box: TamburiBoxInterface
}

export const Map: React.FC<MapComponentProps> = ({ address, streetData, city, setMarker }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
  });
  const [mapRef, setMapRef] = useState<google.maps.Map | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<SelectedMarker | null>(null);
  const [locations, setLocations] = useState<TamburiBoxInterface[]>([]);

  const [directionsResponse, setDirectionsResponse] = useState<google.maps.DirectionsResult | null>(null);
  const [distance, setDistance] = useState<string>('');
  const [duration, setDuration] = useState<string>('');

  const mapStyles: React.CSSProperties = {
    height: '500px',
    width: '100%',
  };

  const center: google.maps.LatLngLiteral = { lat: 48.210033, lng: 16.363449 };
  const zoom: number = 12;

  const searchBoxes = async () => {
    try {
      const response = await axiosOriginal.get(`${process.env.REACT_APP_API_TAMBURI_URL}/api/remote_location/b2c?address=${city}+${address}`);
      setLocations(response.data)
    } 
    catch(error) {
      console.error("Error fetching Tamburi boxes: ", error);
    }
  }

  useEffect(() => {
    if(isLoaded && locations.length > 0 && mapRef) {
      const bounds = new google.maps.LatLngBounds();
      locations?.forEach((box) =>
        bounds.extend(new google.maps.LatLng(box.coordinateX, box.coordinateY))
      );
      mapRef?.fitBounds(bounds);
    }
  }, [locations])

  const onMapLoad = (map: any) => {
    setMapRef(map);
    map.setCenter(center);
    map.setZoom(zoom);
    searchBoxes();
  };

  const handleMarkerClick = async (id: number, box: TamburiBoxInterface) => {
    mapRef?.panTo({ lat: box.coordinateX , lng: box.coordinateY });
    const marker = {id: id, box: box};
    setSelectedMarker(marker);
    setMarker({id: id, box: box})
    setIsOpen(true);
    await calculateRoute(marker);
  };

  const calculateRoute = async (marker: SelectedMarker) => {
    // console.log("Calculating route...");
    // console.log("Address:", address);
    // console.log("Selected Marker:", marker);

    if (address === '' || marker === null) {
      // console.log("Return")
      return;
    }

    //const originLatLng = { lat: selectedMarker?.box.coordinateX, lng: selectedMarker?.box.coordinateY };
    if(marker && marker.box) {
      const originAddress = new google.maps.LatLng(streetData.lat, streetData.lon);
      const originDestination = new google.maps.LatLng(marker?.box.coordinateX, marker?.box.coordinateY);

      const directionService = new google.maps.DirectionsService();
      const results = await directionService.route({
        origin: originAddress,
        destination: originDestination,
        travelMode: google.maps.TravelMode.WALKING
      })

      // console.log("Results: " + JSON.stringify(results))

      // Extract distance and duration from the results, and update state
      if (results && results.routes && results.routes.length > 0) {
        const route = results.routes[0];
        if (route && route.legs && route.legs.length > 0) {
          const leg = route.legs[0];

          setDirectionsResponse(results);
          leg.distance ? setDistance(leg.distance.text) : setDistance("Fehler");
          leg.duration ? setDuration(leg.duration.text) : setDistance("Fehler");
        }
      }
    }

  }

  if (loadError) {
    return <div>Error loading maps</div>;
  }
  
  return (
    <div>
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerStyle={mapStyles}
          onLoad={onMapLoad}
          /* onClick={() => setIsOpen(false)} */
        >
          <MarkerClusterer
            maxZoom={15}
            /* styles={[
              {
                textColor: "", 
                url: "",
                height: 40, 
                width: 40
              },
            ]} */
          >
            {(clusterer) => (
              <>
                <Marker 
                  position={{ lat: streetData.lat, lng: streetData.lon }}
                  icon={{
                    url: startMarker,
                  }}
                />
                {locations.map((box, ind) => (
                  <Marker
                    key={ind}
                    position={{ lat: box.coordinateX, lng: box.coordinateY }}
                    icon={{
                      url: selectedMarker?.id === ind ? MarkerSelectedImg : MarkerImg,
                    }}
                    onClick={() => {
                      handleMarkerClick(ind, box);
                    }}
                    clusterer={clusterer} // Provide the clusterer instance to each location marker
                  >
                    {isOpen && selectedMarker?.id === ind && (
                      <InfoWindow
                        onCloseClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        <div>
                          <h2>{selectedMarker.box.locationName}</h2>
                          <p>{selectedMarker.box.street} {selectedMarker.box.number}</p>
                          <p>{selectedMarker.box.zipCode} {selectedMarker.box.cityName}</p>
                          <p>{selectedMarker.box.locationDescription}</p>
                          <p>Entfernung: {distance}</p>
                          <p>Fußweg: {duration}</p>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                ))}
              </>
            )}
          </MarkerClusterer>
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} options={{suppressMarkers: true}}/>
          )}
        </GoogleMap>
      )}
    </div>
  );
};