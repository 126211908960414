import { ButtonGroup, OutlinedInput, TextField, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.mode === "light" ? "#115427" : "#66bb6a"}`,
  /* '& .MuiAppBar-root': {
    borderBottom: 'solid 2px green',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  }, */
}));

export const TypographyHeading = styled(Typography)(({ theme }) => ({
  float: "left",
  fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
  fontWeight:"600",
  fontSize: "20px",
  color:`${theme.palette.mode === "light" ? "#115427" : "#66BB6A"}`
}));

export const TypographySubheading = styled(Typography)(({ theme }) => ({
  float: "left",
  fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
  fontWeight:"700",
  fontSize: "16px",
  color:`${theme.palette.mode === "light" ? "#115427" : "#66BB6A"}`
}));

export const TypographyTableCell = styled(Typography)(({ theme }) => ({
  fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
  fontSize: "14px",
}));

export const TypographyPlus= styled(Typography)(({ theme }) => ({
  fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
}));

export const ButtonAutocomplete = styled(Button)(({ theme }) => ({
  fontSize: "10px",
  height: "100%",
  borderRadius: "0px",
  border: "1px solid rgba(0, 0, 0, 0.23);",
  borderLeft: "none",
  padding: "0px",
  ":hover": {
    border: "1px solid black;"
  }
}));

export const CreditButton = styled(Button)(({ theme }) => ({
  ":disabled": {
      color: `${theme.palette.mode === "light" ? "green" : "#66bb6a"}`,
    }
}));

export const LogoutButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#ccc" : "#E8E226"}`,
  ":hover": {
    border: "1px solid #8c8c8c;"
  },
  ":disabled": {
    border: "1px solid #848884;"
  }
}));

export const NavButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  "& .MuiButtonGroup-lastButton": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px"
  }
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: `${theme.palette.mode === "light" ? "#fff" : "#121212"}`,
  '& .MuiFormControl-root': {
    paddingTop: "10px"
  },
  '& .MuiFormLabel-root': {
    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-seri",
    fontSize: "14px",
    paddingTop: "2px"
  },

  '& .MuiOutlinedInput-input': {
    fontSize: "14px",
    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-seri",
    padding: "10px"
  },
}));

export const AutocompleteTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-seri",
    fontSize: "14px",
    paddingTop: "2px"
  },

    '& .MuiOutlinedInput-input': {
    fontSize: "14px",
    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-seri",
  },

  '& .MuiOutlinedInput-root': {
    padding: "7.5px !important"
  },
}));

export const BookingTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFormControl-root': {
    paddingTop: "10px"
  },
  '& .MuiFormLabel-root': {
    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-seri",
    fontSize: "14px",
    paddingTop: "2px"
  },

  '& .MuiOutlinedInput-input': {
    fontSize: "14px",
    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-seri",
    padding: "10px"
  },
}));

export const AutocompleteBookingTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFormControl-root': {
    paddingTop: "10px"
  },
  '& .MuiFormLabel-root': {
    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-seri",
    fontSize: "14px",
    paddingTop: "2px"
  },

  '& .MuiOutlinedInput-input': {
    fontSize: "14px",
    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-seri",
    padding: "1px"
  },
}));

export const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  backgroundColor: `${theme.palette.mode === "light" ? "#fff" : "#121212"}`
}));

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  backgroundColor: `${theme.palette.mode === "light" ? "#fff" : "#121212"}`
}));

export const DataGridStyled = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-root': {
    "& .MuiDataGrid-main": {
      backgroundColor: 'black',
      borderColor: "black"
    },
      backgroundColor: 'black', // Change background color of the DataGrid
      borderColor: "black"
    },
}));