import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from '../api/axios';

interface EmailVerificationProps {}

export const EmailVerification: React.FC<EmailVerificationProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() =>  {
    const verifyUser = async () => {
      const params = new URLSearchParams(location.search);
      const verificationToken = encodeURIComponent(params.get("token") as string);
      const user = params.get("p");
      
      if(verificationToken && user) {
        // console.log("verificationToken: " + verificationToken);
        // console.log("User: " + user);

        const url = "/auth/Verify?token=" + verificationToken + "&p=" + user;

        try {
          await axios.post(url, null, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
          toast.success("E-Mail wurde erfolgreich bestätigt", {
            position: toast.POSITION.TOP_RIGHT
          });
        } catch (error: any) {
          toast.error(error.response.data.error || "Bestätigungtoken ist abgelaufen", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }

      navigate("/login")
    }
    verifyUser();
  }, [location.search])

  return (
    <div></div>
  );
};