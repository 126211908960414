import Grid from "@mui/material/Grid"
import { OrderTypeBox } from "./OrderTypeBox"
import { TypographySubheading } from "../../common/StyledMuiComponents";
import { produce } from "immer";
import useContent from "../../hooks/useContent";

interface OrderTypesProps {
}

export const TimeframeSelection: React.FC<OrderTypesProps> = () => {
  const TimeframeData = [
    { id: 1, content: "9 - 12 Uhr", height: "50"},
    { id: 2, content: "12 - 15 Uhr", height: "50"},
    { id: 3, content: "15 - 18 Uhr", height: "50"}
  ];

  const content = useContent();

  const handleTimeframeClick = (id: number) => {
    content?.setSelectedTimeframe(id);

    const timeframes: {[key: number]: { from: string, to: string }} = {
      1: { from: "09:00:00", to: "12:00:00" },
      2: { from: "12:00:00", to: "15:00:00" },
      3: { from: "15:00:00", to: "18:00:00" }
    };

    const { from, to } = timeframes[id];

    content?.setOrder(prev => produce(prev, draft => {
      draft.fromPickupTimeFrame = from;
      draft.toPickupTimeFrame = to;
    }))
  }

  return <>
    <Grid item xs={12}>
      <Grid item>
        <TypographySubheading sx={{float: "none"}}>
          Zeitfenster
        </TypographySubheading>
      </Grid>
      <Grid container item spacing={2} p={3} sx={{justifyContent: "center"}}>
        {TimeframeData.map((card, id) => (
          <Grid item key={card.id} xs={12} sm={2}>
            <OrderTypeBox
              id={card.id}
              content={card.content}
              selected={content?.selectedTimeframe === card.id}
              height={card.height}
              onClick={() => handleTimeframeClick(card.id)}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  </>
}