import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { Cookies } from "react-cookie-consent";
import useAuth from "../hooks/useAuth";

interface MyToken extends JwtPayload {
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': string;
  email: string;
}

export const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const authProps = useAuth();

  useEffect(() => {
    const setToken = () => {
      const bearerToken = Cookies.get('token');

      if(bearerToken !== undefined && bearerToken !== null && bearerToken !== "undefined") {
        const decode: MyToken = jwtDecode(bearerToken !== null ? bearerToken : "");
        const role = decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] !== undefined ? 
          decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] : "User";
        // console.log(" TOKEN: " + JSON.stringify(decode));

        authProps?.setAuth((prev: any) => {
          return {...prev, token: bearerToken, roles: role}
        })
      } else {
        authProps?.setAuth((prev: any) => {
          return {...prev, token: bearerToken, roles: ""}
        })
      }

      setIsLoading(false);
    }

    !authProps?.auth?.token ? setToken() : setIsLoading(false);
  }, [])

  return (
    <>
      {isLoading
        ? <CircularProgress color="secondary"/>
        : <Outlet />
      }
    </>
  )
}