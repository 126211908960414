import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PopupTypesEnum } from "../interfaces/PopupTypes";
import { MapView } from "../maps/MapView";
import { CreditNeededPopup } from "../popups/CreditNeededPopup";
import { produce} from "immer"
import { usePopup } from "../context/PopupProvider";
import { ClientComponent } from "./common/Client";
import { OrderTypesComponent } from "./common/OrderTypes";
import { PackageTypeComponent } from "./common/PackageType";
import { AddressBlockOSTextil } from "./AddressBlockOSTextil";
import { DatePicker } from "./common/DatePicker";
import { EmtyOrder } from "./NewPackageEmty";
import { TimeframeSelection } from "./common/TimeframeSelection";
import { OrderFlags } from "../interfaces/OrderFlags";
import { TypographyHeading } from "../common/StyledMuiComponents";
import axiosOriginal from "axios";
import useAxios from "../hooks/useAxios";
import useValidation from "../hooks/useValidation";
import useGetProfile from "../hooks/useGetProfile";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from "@mui/material/CircularProgress";
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from "dayjs";
import useContent from "../hooks/useContent";

const OrderTypeData = [
  { id: 1, content: "Abholung von Hotel", height: "100"},
  { id: 2, content: "Zustellung an Hotel", height: "100"},
  { id: 3, content: "Abholung von Einzelkunde", height: "100"},
  { id: 4, content: "Zustellung an Einzelkunde", height: "100"}
];

export const BookingOSTextil = () => {
  // const [order, setOrder] = useState<Order>(EmtyOrder);
  const [geoData, setGeoData] = useState({address: "", lat: 0, lon: 0});
  // const [disabled, setDisabled] = useState<boolean>(false);
  const [hangingClothes, setHangingClothes] = useState<boolean>(false);
  const [isLoadingFinishOrder, setIsLoadingFinishOrder] = useState(false);
  const { open, onOpenPopup, onClosePopup } = usePopup();
  const { profile, getProfile } = useGetProfile();
  const { validateEmail } = useValidation();
  const { error: pickupEmailError, errorMsg: pickupEmailErrorMsg, validateEmail: pickupEmailValidation } = useValidation();
  const { error: deliveryEmailError, errorMsg: deliveryEmailErrorMsg, validateEmail: deliveryEmailValidation } = useValidation();
  const { postData: postFinishOrder } = useAxios();
  // const { fetchData: getFinishOrder } = useAxios();
  // const { postData: rememberBox } = useAxios();
  const navigate = useNavigate();
  const content = useContent();

  /* const [saveData, setSaveData] = useState<{fromBox: boolean, toBox: boolean, fromStore: boolean, toStore: boolean}>({
    fromBox: false, 
    toBox: false,
    fromStore: false,
    toStore: false
  }); */

  useEffect(() => {
    content?.setOrder(prev => produce(prev, draft => {
      draft.client.name = profile?.account?.firstName && profile?.account?.lastName ? `${profile.account.firstName} ${profile.account.lastName}` : "";
      draft.client.email = profile?.account?.email || "";
      draft.client.phoneNumber =  profile?.account?.phoneNumber || "";
      draft.client.plz = profile?.account?.plz || 0;
      draft.client.city = profile?.account?.city || "";
      draft.client.address = profile?.account?.address || ""
    }))
  }, [profile]);

  const chooseMapView = (addressType: string) => {
    addressType === "from" ? content?.setIsFromAddress(true) : content?.setIsFromAddress(false);
    onOpenPopup(PopupTypesEnum.map);
  }

  const closeMapView = () => onClosePopup(PopupTypesEnum.map);

/*   const handleGetSelectedFromBox = (box: TamburiBoxInterface | undefined) => {
    content?.setSelectedFromBox(box);
    content?.setOrder(prev => ({ ...prev, pickupType: 0 })); // Tamburi
    content?.setDeliveryDate(dayjs());
    content?.setMinDate(dayjs());
    content?.setSelectedTimeframe(null);

    if(box){
      content?.setOrder(prev => produce(prev, draft => {
        draft.orderInfo.fromLocationId = box.locationId;
        draft.orderInfo.fromAddress = `${box.street} ${box.number}`;
        draft.orderInfo.fromPLZ = parseInt(box.zipCode, 10);
        draft.orderInfo.fromCity = box.cityName;
        draft.orderInfo.fromAddressSupplement = "";
        draft.orderInfo.fromPickupTimeFrame = null;
        draft.orderInfo.toPickupTimeFrame = null;
        draft.orderInfo.dateToDeliver = dayjs().format('YYYY-MM-DD');
      }))
    }
  }

  const handleGetSelectedToBox = (box: TamburiBoxInterface | undefined) => {
    content?.setSelectedToBox(box);
    content?.setOrder(prev => ({ ...prev, deliveryType: 0 })); // Tamburi
    content?.setDeliveryDate(dayjs());
    content?.setMinDate(dayjs());
    content?.setSelectedTimeframe(null);

    if(box){
      content?.setOrder(prev => produce(prev, draft => {
        draft.orderInfo.toLocationId = box.locationId;
        draft.orderInfo.toAddress = `${box.street} ${box.number}`;
        draft.orderInfo.toPLZ = parseInt(box.zipCode, 10);
        draft.orderInfo.toCity = box.cityName;
        draft.orderInfo.toAddressSupplement = "";
        draft.orderInfo.fromPickupTimeFrame = null;
        draft.orderInfo.toPickupTimeFrame = null;
        draft.orderInfo.dateToDeliver = dayjs().format('YYYY-MM-DD');
      }))
    }
  } */

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // console.log(newPackage)

    if (content?.selectedOrderType === null) {
      return;
    }

    if (!validateEmail(content?.order?.client?.email || "")) {
      return;
    }

    /* if (!hangingClothes && (selectedOrderType === 3 || selectedOrderType === 4) && !endemailValidation(newPackage.orderInfo?.endemail)) {
      return;
    } */

    if (content?.order?.deliveryEmail !== null && content?.order?.deliveryEmail !== "" && !deliveryEmailValidation(content?.order?.deliveryEmail || "")) {
      return;
    }

    if (content?.order?.pickupEmail !== null && content?.order?.pickupEmail !== "" && !pickupEmailValidation(content?.order?.pickupEmail || "")) {
      return;
    }

    if(content?.order.packages[0].packageType === null) {
      toast.error("Bitte wählen Sie eine Paketgröße aus!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    if(!content?.order.dateToDeliver) {
      toast.error("Lieferdatum ist erforderlich!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    onFinishOrder();
  }

  const handleOrderTypeClick = (id: number) => {
    if (id === 3) {
      content?.setDeliveryDate(dayjs().add(1, "day"));
      content?.setMinDate(dayjs().add(1, "day"));
      content?.setOrder(prev => produce(prev, draft => {
        draft.toName = "Bsp. OS Textil Reinigung";
        draft.toAddress = "Wagramer Straße 118";
        draft.toCity = "Wien";
        draft.toContactNumber = "+4312345678";
        draft.toPLZ = 1210;
        draft.dateToDeliver = dayjs().add(1, "day").format('YYYY-MM-DD');
        draft.orderFlags = OrderFlags.PrivatePickup;
        draft.packages[0].deliveryType = 1;
        draft.packages[0].packageType = 4;
      }))
    } else if (id === 4) {
      content?.setDeliveryDate(dayjs().add(1, "day"));
      content?.setMinDate(dayjs().add(1, "day"));
      content?.setOrder(prev => produce(prev, draft => {
        draft.fromName = "Bsp. OS Textil Reinigung";
        draft.fromAddress = "Wagramer Straße 118";
        draft.fromCity = "Wien";
        draft.fromContactNumber = "+4312345678";
        draft.fromPLZ = 1210;
        draft.dateToDeliver = dayjs().add(1, "day").format('YYYY-MM-DD');
        draft.orderFlags = OrderFlags.PrivateDelivery;
        draft.packages[0].pickupType = 1;
        draft.packages[0].packageType = 4;
      }))
    } else if ((id === 1 || id === 2)) {
      content?.setOrder(prev => produce(prev, draft => {
        draft.packages[0].pickupType = 1;
        draft.packages[0].deliveryType = 1;
        draft.packages[0].packageType = 4;
        id === 1 ? draft.orderFlags = OrderFlags.HotelPickup : draft.orderFlags = OrderFlags.HotelDelivery
      }))
    }
  }

/*   const handleTimeframeClick = (id: number) => {
    content?.setSelectedTimeframe(id);

    const timeframes: {[key: number]: { from: string, to: string }} = {
      1: { from: "09:00:00", to: "12:00:00" },
      2: { from: "12:00:00", to: "15:00:00" },
      3: { from: "15:00:00", to: "18:00:00" }
    };

    const { from, to } = timeframes[id];

    content?.setOrder(prev => produce(prev, draft => {
      draft.orderInfo.fromPickupTimeFrame = from;
      draft.orderInfo.toPickupTimeFrame = to;
    }))
  } */

  // const setDisabledToTrue = () => setDisabled(true);

  // const setDisabledToFalse = () => setDisabled(false);

/*   const handleSaveDataChange = (save: boolean, fromBox: boolean) => {
    console.log("handleSaveDataChange")
    const name = fromBox ? "fromBox" : "toBox";
    setNewPackage(prev => produce(prev, draft => {
      fromBox ? draft.pickupType = 0 : draft.deliveryType = 0;
    }))
    setSaveData(prevState => ({
      ...prevState,
      [name]: save
    }));
  }; */
  
  /* const CheckTime = () => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();

    return currentHour >= 15
  } */

  //
  // BOOK ORDER START
  //
  const onSuccessFinishOrder = (response: any) => {
    onOpenPopup(PopupTypesEnum.redirectPayment)
    window.location.href = response;
    setIsLoadingFinishOrder(false);
    content?.setOrder(EmtyOrder);
    content?.setSelectedTimeframe(null);
    content?.setSelectedOrderType(null);
    content?.setSelectedFromBox(undefined);
    content?.setSelectedToBox(undefined);
    getProfile();
  }

  const onFailure = (error: any) => {
    setIsLoadingFinishOrder(false);
    if(axiosOriginal.isAxiosError(error)) {
      if (error.response) {
        if(error.response.status === 401) {
          toast.error(error.response.data.error || "Benutzerauthentifizierung erforderlich. Bitte melden Sie sich erneut an.", {
            position: toast.POSITION.TOP_RIGHT
          });
          navigate('/login');
        } else if(error.response.status === 404) {
          toast.error(error.response.data.error || "404 - Not Found", {
            position: toast.POSITION.TOP_RIGHT
          });
        } else if(error.response.data.error === "Unzurecheindes Guthaben") {
          onOpenPopup(PopupTypesEnum.creditRecharge)
        } else {
          toast.error(error.response.data.error || "Ein unerwarteter Fehler ist aufgetreten.", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }
    }
  }

  const onFinishOrder = async () => {
    setIsLoadingFinishOrder(true);

    await postFinishOrder(
      `/order/finish?useCredit=false`,
      {'Content-Type': 'application/json'},
      content?.order,
      false,
      onSuccessFinishOrder,
      onFailure
    );

    setIsLoadingFinishOrder(false);
  }

  /* const isActiveOrder = () => {
    if (profile && profile.account.activeOrder != null && profile.account.activeOrder !== undefined) {
      return true
    }
    return false;
  } */

  /* const saveChoosenBoxes = async () => {
    if(saveData.fromBox) {
      await rememberBox("/address/RememberTamburiBox", { 'Content-Type': 'application/json' }, content?.selectedFromBox, true, () => {}, () => {});
    }

    if(saveData.toBox) {
      await rememberBox("/address/RememberTamburiBox", { 'Content-Type': 'application/json' }, content?.selectedToBox, true, () => {}, () => {});
    }
  } */
  
  //
  // BOOK ORDER END
  //

  return <>
    <Container component="main" maxWidth="md" disableGutters>
      <CssBaseline />
      <Box 
        component="form"
        onSubmit={handleSubmit}
        sx={{
          marginTop: 4
        }}
      >
        <Grid container spacing={2}>
          <Grid container item xs={12} sx={{marginLeft: "30px", marginRight: "30px", marginBottom: "15px"}} justifyContent="space-between">
            <TypographyHeading mb={1}>
              Auftrag buchen
            </TypographyHeading>

            {/* <Button onClick={fillOrder} color="success">
              Fill Form
            </Button> */}
          </Grid>

          <ClientComponent disabled={false}/>

          <OrderTypesComponent handleOrderTypeClick={handleOrderTypeClick} OrderTypeData={OrderTypeData}/>

          <Grid item container xs={12} sx={{justifyContent: "center"}}>
            {/* <ValueOfGoodComponent newPackage={newPackage} setNewPackage={setNewPackage}/> */}
            
            {(content?.selectedOrderType === 3 || content?.selectedOrderType === 4) &&
              <Grid item xs={12} sm={3}>
                <FormControlLabel 
                  control={
                    <Checkbox 
                      name="hangingClothes"
                      color="success"
                      checked={hangingClothes}
                      onChange={(e) => {
                        setHangingClothes(e.target.checked)
                        if(e.target.checked) {
                          content?.setSelectedFromBox(undefined);
                          content?.setSelectedToBox(undefined);
                          content?.setDeliveryDate(dayjs().add(1, "day"));
                          content?.setMinDate(dayjs().add(1, "day"));
                          content?.setOrder(prev => produce(prev, draft => {
                            draft.orderFlags |= OrderFlags.HangingGood;
                            draft.packages[0].deliveryType = 1;
                            draft.packages[0].pickupType = 1;
                            draft.toLocationId = 0;
                            draft.fromLocationId = 0;
                            draft.packages[0].packageType = 4;
                            draft.dateToDeliver = dayjs().add(1, "day").format('YYYY-MM-DD');
                          }))
                        } else {
                          content?.setOrder(prev => produce(prev, draft => {
                            draft.orderFlags &= ~OrderFlags.HangingGood;
                          }))
                        }
                      }}
                    />
                  }
                  label="Hängende Kleidung"
                />
              </Grid>
            }
          </Grid>

          {content?.selectedOrderType !== null ? 
            <AddressBlockOSTextil
              setGeoData={setGeoData}
              hangingClothes={hangingClothes}
              chooseMapView={chooseMapView}
              pickupEmailError={pickupEmailError}
              pickupEmailErrorMsg={pickupEmailErrorMsg}
              deliveryEmailError={deliveryEmailError}
              deliveryEmailErrorMsg={deliveryEmailErrorMsg}
            /> : ""
          }

          {content?.selectedOrderType !== null ? (
              <Grid item xs={12}>
                <DatePicker />
              </Grid>
          ) : "" }

          { (!hangingClothes && ((content?.selectedOrderType === 3 && content?.order.packages[0].pickupType === 0) || (content?.selectedOrderType === 4 && content?.order.packages[0].deliveryType === 0))) && 
            <PackageTypeComponent />
          }

          {/* { (selectedOrderType !== 3 && selectedOrderType !== 4) && 
            ((newPackage.orderInfo.toAddress && newPackage.orderInfo.toAddress !== "") || (newPackage.orderInfo.fromAddress && newPackage.orderInfo.fromAddress !== "")) ? (
            <SelectedBoxesComponent 
              newPackage={newPackage}
              selectedFromBox={selectedFromBox}
              selectedToBox={selectedToBox}
              handleSaveDataChange={handleSaveDataChange}
            />
          ) : "" } */}

          { (content?.selectedOrderType === 3 && content?.order.packages[0].pickupType !== 0) || (content?.selectedOrderType === 4 && content?.order.packages[0].deliveryType !== 0) ? (
            <Grid item xs={12} mt={3}>
              <TimeframeSelection/>
            </Grid>
          ) : "" }
          
          <Grid item xs={12} sm={12} textAlign={"center"} sx={{marginLeft: "25px", marginRight: "25px"}}>
            <Button
              type="submit"
              color="yellow"
              variant="contained"
              disabled={isLoadingFinishOrder}
              sx={{ 
                mt: 3,
                mb: 2,
                width: 200,
                height: 50,
                textAlign: "right"
              }}
              >
                { isLoadingFinishOrder ? <CircularProgress size={24} color="inherit"/> :  "Auftrag buchen"} 
            </Button>
          </Grid>
        </Grid>

        {/* { CheckTime() ?  
          <Grid container item xs={12} sm={3} alignItems="center" mt={2} textAlign="start" sx={{verticalAlign:"end", marginLeft: "30px", marginRight: "30px"}}>
            <Typography component="h1" variant="body2" >
              {"Die Lieferung erfolgt am nächsten Werkstag zwischen 8 und 12 Uhr"}
            </Typography>
            <Typography component="h1" variant="body2" sx={{marginLeft:"6px"}}>
              {"Zustellung bis 21 Uhr"}
            </Typography>
          </Grid>
        : ""} */}

        <MapView 
          open={open.map}
          streetData={geoData}
          onClose={closeMapView}
        />

        <CreditNeededPopup 
          open={open.creditNeeded}
          onClose={() => onClosePopup(PopupTypesEnum.creditNeeded)}
        />
      </Box>
    </Container>
  </>
}