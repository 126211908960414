import dayjs from "dayjs";
import { Order } from "../interfaces/Order";

export const EmtyOrder = {
  id: 0,
  userId: "",
  client: {
    id: 0,
    name: "",
    address: "",
    city: "",
    plz: 0,
    email: "",
    phoneNumber: ""
  },
  fromAddress: "",
  toAddress: "",
  fromPLZ: 0,
  toPLZ: 0,
  fromCity: "",
  toCity: "",
  fromAddressSupplement: "",
  toAddressSupplement: "",
  fromContactNumber: "",
  toContactNumber: "",
  fromName: "",
  toName: "",
  fromLocationId: null,
  toLocationId: null,
  orderId: "",
  refOrderId: "",
  veloceOrderNumber: "",
  dateToDeliver: dayjs().format('YYYY-MM-DD'),
  paidPrice: 0,
  status: 0,
  pickupEmail: "",
  deliveryEmail: "",
  orderFlags: 0,
  fromPickupTimeFrame: null,
  toPickupTimeFrame: null,
  fromDeliveryTimeFrame: null,
  toDeliveryTimeFrame: null,
  comment: "",
  creationDateTime: new Date(),
  packages: [{
    pickupType: 1, // Tamburi = 0, Veloce = 1,
    deliveryType: 1, // Tamburi = 0, Veloce = 1,
    packageType: 4,
    packageId: "",
    valueOfGood: 0,
    reservationId: 0,
    deliveryCode: "",
    deliveryPin: "",
    pickupCode: "",
    pickupPin: "",
    price: 0,
  }]
}

export const fillNewOrder = (order: Order | undefined) => {
  let newOrder = order ? { ...order } : undefined;
  if(newOrder) {
    newOrder.client = {
      id: 0,
      address: "",
      city: "Wien",
      email: "test@gt.am",
      name: "Test Client",
      phoneNumber: "+4315115285",
      plz: 1110
    };
    newOrder.comment = "test";
    newOrder.deliveryEmail = "d.email@gt.am";
    newOrder.fromCity = "Wien";
    newOrder.fromContactNumber = "+4311515112";
    newOrder.fromName = "Absender Test Name";
    newOrder.fromPLZ = 1100;
    newOrder.pickupEmail = "e.email.@gt.am";
    newOrder.refOrderId = "123456";
    newOrder.toCity = "Wien";
    newOrder.toContactNumber = "+4318545151";
    newOrder.toName = "Empfänger Test Name";
    newOrder.toPLZ = 1180;
  }
  return newOrder;
}