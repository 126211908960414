import { Dialog, DialogContent, DialogTitle, IconButton, ThemeProvider, Typography, styled, useTheme } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

interface RedirectToPaymentProps {
  open: boolean;
  onClose: () => void;
}

export const RedirectToPayment: React.FC<RedirectToPaymentProps> = ({open, onClose }) => {
  const globalTheme = useTheme();

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
  
  return <>
    <ThemeProvider theme={globalTheme}>
      <BootstrapDialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Weiterleitung zur externen Zahlungsplattform
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            Sie werden zur externen Zahlungsplattform weitergeleitet. Bitte haben Sie einen Moment Geduld...
          </Typography>
        </DialogContent>

      </BootstrapDialog>
    </ThemeProvider>
  </>
}