import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableOrderInfo } from '../interfaces/TableOrderInfo';
import { visuallyHidden } from '@mui/utils';
import { LogoutButton, StyledTextField, TypographyTableCell } from '../common/StyledMuiComponents';
import { DateSpanPicker } from '../main/common/DateSpanPicker';
import { Order } from '../interfaces/Order';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import useAxios from '../hooks/useAxios';
import dayjs, { Dayjs } from "dayjs";
import useContent from '../hooks/useContent';

interface SearchInputState {
  refOrderId: string;
  creationDateTime: string;
  fromAddress: string;
  toAddress: string;
  fromCity: string;
  status: string;
}

const initialSearchState: SearchInputState = {
  refOrderId: '',
  creationDateTime: '',
  fromAddress: '',
  toAddress: '',
  fromCity: '',
  status: ''
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type SortOrder = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: SortOrder,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Order;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  /* {
    id: 'refOrderId',
    numeric: false,
    disablePadding: false,
    label: 'Referenznummer',
  }, */
  {
    id: 'creationDateTime',
    numeric: false,
    disablePadding: false,
    label: 'Buchungsdatum',
  },
  {
    id: 'fromAddress',
    numeric: false,
    disablePadding: true,
    label: 'Absender',
  },
  {
    id: 'toAddress',
    numeric: false,
    disablePadding: true,
    label: 'Empfänger',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'fromCity',
    numeric: false,
    disablePadding: true,
    label: '',
  }
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Order) => void;
  order: SortOrder;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Order) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar() {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 }
      }}
    >
      <Typography
        id="tableTitle"
        variant="body2"
        component="div"
      >
        Track & Trace
      </Typography>
    </Toolbar>
  );
}

export default function OrderViewTable() {
  const [searchInput, setSearchInput] = useState<SearchInputState>(initialSearchState);
  const [order, setOrder] = useState<SortOrder>('desc');
  const [orders, setOrders] = useState<Order[]>([]);
  const [orderBy, setOrderBy] = useState<keyof Order>('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [fromTime, setFromTime] = useState<Dayjs | null>(dayjs().subtract(7, 'day'));
  const [toTime, setToTime] = useState<Dayjs | null>(dayjs());
  // const { open, onOpenPopup, onClosePopup } = usePopup();
  const { fetchData: getWayBill } = useAxios();
  const { fetchData: getOrders } = useAxios();
  const { fetchData: getOrderById } = useAxios();
  const content = useContent();
  const navigate = useNavigate();

  useEffect(() => {
    getOrders("User/Orders", { }, true, onSuccessGetOrders, () => {})
  }, [])

  const onCompleteOrder = (orderId: number) => {
    getOrderById("/Order?orderId=" + orderId, {'Content-Type': 'application/json'}, true, onSuccessGetOrder, () => {});
  }

  const onSuccessGetOrder = (response: any) => {
    const order = response?.order;
    const fromRemoteLocationInfo = response?.fromRemoteLocationInfo;
    const toRemoteLocationInfo = response?.toRemoteLocationInfo;
    order && content?.setOrder(order);
    fromRemoteLocationInfo && content?.setSelectedFromBox(fromRemoteLocationInfo);
    toRemoteLocationInfo && content?.setSelectedToBox(toRemoteLocationInfo);
    content?.setSelectedOrderType(1);
    navigate("/");
  }

  const onSuccessGetOrders = (response: any) => {
    setOrders(response);
  }

  const rows = orders.map(order => ({
    id: order.id,
    userId: order.userId,
    client: "",
    fromName: order.fromName,
    toName: order.toName,
    fromAddress: order.fromAddress,
    toAddress: order.toAddress,
    fromPLZ: order.fromPLZ ? order.fromPLZ : "",
    toPLZ: order.toPLZ ? order.toPLZ : "",
    fromCity: order.fromCity,
    toCity: order.toCity,
    fromAddressSupplement: order.fromAddressSupplement,
    toAddressSupplement: order.toAddressSupplement,
    fromContactNumber: order.fromContactNumber,
    toContactNumber: order.toContactNumber,
    fromLocationId: order.fromLocationId ? order.fromLocationId : 0,
    toLocationId: order.toLocationId ? order.toLocationId : 0,
    orderId: order.orderId,
    refOrderId: order.refOrderId,
    dateToDeliver: order.dateToDeliver ? order.dateToDeliver : "",  
    status: order.status,
    veloceOrderNumber: order.veloceOrderNumber,
    creationDateTime: order.creationDateTime ? order.creationDateTime.toString() : "",
    paidPrice: order.paidPrice,
    pickupEmail: order.pickupEmail,
    deliveryEmail: order.deliveryEmail,
    orderFlags: order.orderFlags,
    fromPickupTimeFrame: order.fromPickupTimeFrame ? order.fromPickupTimeFrame : "",
    toPickupTimeFrame:  order.toPickupTimeFrame ? order.toPickupTimeFrame : "",
    fromDeliveryTimeFrame: order.fromDeliveryTimeFrame ? order.fromDeliveryTimeFrame : "",
    toDeliveryTimeFrame: order.toDeliveryTimeFrame ? order.toDeliveryTimeFrame : "",
    comment:order.comment,
    packages: ""
  })) || [];

  // Update search input state
  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, columnName: keyof SearchInputState) => {
    setSearchInput({
      ...searchInput,
      [columnName]: event.target.value,
    });
  };

  // const onCancelOrder = () => onOpenPopup(PopupTypesEnum.cancelOrder)

  const onWayBill = (orderId: number) => {
    getWayBill("Order/WayBill?orderId=" + orderId, { 'Content-Type': 'application/pdf' }, true, onSuccess, () => {})
  }

  const onSuccess = (result: any) => {
    try{
      const binaryString = atob(result);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([bytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      window.open(url, '_blank');
    } catch(e) {
      toast.error("Ein unerwarteter Fehler ist aufgetreten.", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const handleRequestSort = (e: React.MouseEvent<unknown>, property: keyof Order) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (e: React.MouseEvent<unknown>, id: number, packageData: TableOrderInfo) => {
    /* let userPackages: Order[] = orders.filter((v) => {
      return v.orderInfo.id === packageData.id;
    }) || []

    navigate("/orders/" + id, { state: { packageData: userPackages[0]} }) */
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter rows based on search inputs
  const filteredRows = rows.filter(row => {
    const fromAddress = `${row?.fromName} ${row?.fromPLZ} ${row?.fromCity} ${row?.fromAddress} ${row?.fromContactNumber}`;
    const toAddress = `${row?.toName} ${row?.toPLZ} ${row?.toCity} ${row?.toAddress} ${row?.toContactNumber}`;
    const status = row?.status !== undefined ? 
      (row.status === 0 ? "Ausstehend" : 
        (row.status === 1 ? "Bezahlt" : 
          (row.status === 2 ? "Bezahlung Ausstehend" : 
            ( row.status === 3 ? "Abgebrochen" : "")
          )
        )
      ) : ""
    
    return (
      /* (row.refOrderId !== null ? row?.refOrderId?.toLowerCase().includes(searchInput.refOrderId.toLowerCase()) : row) && */
      (row.creationDateTime !== null ? new Date(row.creationDateTime).toLocaleDateString().includes(searchInput.creationDateTime) : row) &&
      (row.fromAddress !== null ? fromAddress.toLowerCase().includes(searchInput.fromAddress.toLowerCase()): row)  &&
      (row.toAddress !== null ? toAddress.toLowerCase().includes(searchInput.toAddress.toLowerCase()) : row) &&
      (row.status !== null ? status.toLowerCase().includes(searchInput.status.toLowerCase()) : row) &&
      (row.creationDateTime !== null ? 
        (
          dayjs(row.creationDateTime).isAfter(dayjs(fromTime).subtract(1, 'day')) && 
          dayjs(row.creationDateTime).isBefore(dayjs(toTime))
        ) : row
      )
    );
  });

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [filteredRows, order, orderBy, page, rowsPerPage],
  );

  return (
    <Container component="main">
      <CssBaseline/>
      <Box sx={{ width: '100%', marginTop: 8 }}>

        <DateSpanPicker 
          fromTime={fromTime}
          toTime={toTime}
          setFromTime={setFromTime}
          setToTime={setToTime}
        />

        <Paper 
          sx={{ 
            width: '100%',
            mt: 2,
            mb: 2,
            backgroundColor: "transparent",
            border: "1px solid #DDDDDD"
          }}
          elevation={0}
        >
          <EnhancedTableToolbar/>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="trackAndTraceTable"
              size="small"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {/* Search input fields */}
                <TableRow>
                  {/* <TableCell padding="normal" width="90px">
                    <StyledTextField
                      label="Suche Refnr."
                      size="small"
                      color="success"
                      value={searchInput.refOrderId}
                      onChange={(e) => handleSearchInputChange(e, 'refOrderId')}
                    />
                  </TableCell> */}
                  <TableCell padding="normal" width="90px">
                    <StyledTextField
                      label="Suche Datum"
                      size="small"
                      color="success"
                      value={searchInput.creationDateTime}
                      onChange={(e) => handleSearchInputChange(e, 'creationDateTime')}
                    />
                  </TableCell>
                  <TableCell padding="normal" >
                    <StyledTextField
                      label="Suche Absender"
                      size="small"
                      color="success"
                      value={searchInput.fromAddress}
                      onChange={(e) => handleSearchInputChange(e, 'fromAddress')}
                    />
                  </TableCell>
                  <TableCell padding="normal">
                    <StyledTextField
                      label="Suche Empfänger"
                      size="small"
                      color="success"
                      value={searchInput.toAddress}
                      onChange={(e) => handleSearchInputChange(e, 'toAddress')}
                    />
                  </TableCell>
                  <TableCell padding="normal">
                    <StyledTextField
                      label="Status"
                      size="small"
                      color="success"
                      value={searchInput.status}
                      onChange={(e) => handleSearchInputChange(e, 'status')}
                    />
                  </TableCell>
                  <TableCell width="300px"></TableCell>
                </TableRow>

                {visibleRows.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      onClick={(e) => handleClick(e, row.id, row)}
                      tabIndex={-1}
                      key={row.id}
                      sx={{ cursor: 'pointer' }}
                    >
                      {/* <TableCell width="90px" sx={{maxWidth:"90px", overflow: "hidden"}}>{row.refOrderId}</TableCell> */}

                      <TableCell align="left">{row?.creationDateTime && new Date(row.creationDateTime).toLocaleDateString()}</TableCell>

                      <TableCell align="left">
                        <Grid component="span">
                          <TypographyTableCell variant="body1" color="text.primary">
                            {`${row.fromName}`}<br></br>
                          </TypographyTableCell>
                          <TypographyTableCell variant="body1" color="text.primary">
                            {`${row.fromPLZ} ${row.fromCity} ${row.fromAddress}`}<br></br>
                          </TypographyTableCell>
                          <TypographyTableCell variant="body1" color="text.primary">
                            { row.fromContactNumber }
                          </TypographyTableCell>
                        </Grid>
                      </TableCell>

                      <TableCell align="left">
                        <Grid component="span">
                          <TypographyTableCell variant="body1" color="text.primary">
                            {`${row.toName}`}<br></br>
                          </TypographyTableCell>
                          <TypographyTableCell variant="body1" color="text.primary">
                            {`${row.toPLZ} ${row.toCity} ${row.toAddress}`}<br></br>
                          </TypographyTableCell>

                          <TypographyTableCell variant="body1" color="text.primary">
                            { row.toContactNumber }
                          </TypographyTableCell>
                          
                        </Grid>
                      </TableCell>

                      <TableCell sx={{maxWidth:"90px", overflow: "hidden"}}>
                        {row?.status !== undefined ? 
                          (row.status === 0 ? "Ausstehend" : 
                            (row.status === 1 ? "Bezahlt" : 
                              (row.status === 2 ? "Bezahlung Ausstehend" : 
                                ( row.status === 3 ? "Abgebrochen" : "")
                              )
                            )
                          ) : ""
                        }
                      </TableCell>

                      <TableCell>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <LogoutButton 
                              variant="contained"
                              size="small"
                              fullWidth
                              disableElevation
                              color="yellow"
                              style={{marginRight: "10px"}}
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate("/trackAndTrace", { state: { orderId: row?.id}})}
                              }
                            >
                              Status
                            </LogoutButton>
                          </Grid>
                          <Grid item xs={6}>
                            <LogoutButton
                              variant="contained"
                              size="small"
                              fullWidth
                              disableElevation
                              color="yellow"
                              style={{marginRight: "10px"}}
                              onClick={(e) => {
                                e.stopPropagation();
                                onWayBill(row?.id)
                                console.log(row)
                              }}
                            >
                              Frachtschein
                            </LogoutButton>
                          </Grid>
                          {(row?.status === 0 || row?.status === 2) && 
                            <Grid item xs={12}>
                              <LogoutButton
                                variant="contained"
                                fullWidth
                                disableElevation
                                size="small"
                                color="yellow"
                                style={{marginRight: "10px"}}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onCompleteOrder(row?.id)
                                }}
                              >
                                Auftrag abschließen
                              </LogoutButton>
                            </Grid>
                          }
                        </Grid>
                        {/* <Button variant="contained" color="yellow" onClick={() => {
                            setUserDataPackages(row);
                            onCancelOrder();
                          }}>
                          Stornieren
                        </Button> */}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Container>
  );
}
