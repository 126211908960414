import { isTokenExpired } from "./CheckToken";
import { NavLayoutNoAuth } from "./NavLayoutNoAuth";
import { NavLayoutAuth } from "./NavLayoutAuth";

export const NavLayout = () => {
  return <>
    { isTokenExpired() ?
      <NavLayoutNoAuth/> : <NavLayoutAuth/>
    }
  </>
}