import Grid from "@mui/material/Grid"
import { OrderTypeBox } from "./OrderTypeBox"
import useProfile from "../../hooks/useProfile";
import useContent from "../../hooks/useContent";
import dayjs from "dayjs";
import { produce } from "immer";

interface OrderTypesProps {
  OrderTypeData: {id: number, content: string, height: string}[],
  handleOrderTypeClick: (id: number) => void
}

export const OrderTypesComponent: React.FC<OrderTypesProps> = ({OrderTypeData, handleOrderTypeClick}) => {
/*   const OrderTypeData = [
    { id: 1, content: "Abholung von Hotel", height: "100"},
    { id: 2, content: "Zustellung an Hotel", height: "100"},
    { id: 3, content: "Abholung von Einzelkunde", height: "100"},
    { id: 4, content: "Zustellung an Einzelkunde", height: "100"}
  ]; */

  const profile = useProfile();
  const content = useContent();

  const handleOrder = (id: number) => {
    if(content?.selectedOrderType === id) {
      return;
    }

    // Set default values
    content?.setSelectedOrderType(id);
    content?.setSelectedFromBox(undefined);
    content?.setSelectedToBox(undefined);
    content?.setSelectedTimeframe(null);
    content?.setDeliveryDate(dayjs())
    content?.setMinDate(dayjs());
    content?.setOrder(prev => produce(prev, draft => {
      draft.toName = "";
      draft.toAddress = "";
      draft.toCity = "";
      draft.toContactNumber = "";
      draft.toPLZ = 0;
      draft.fromName = "";
      draft.fromAddress = "";
      draft.fromCity = "";
      draft.fromContactNumber = "";
      draft.fromPLZ = 0;
      draft.toLocationId = 0;
      draft.fromLocationId = 0;
      draft.dateToDeliver = dayjs().format('YYYY-MM-DD');
      draft.fromPickupTimeFrame = null;
      draft.toPickupTimeFrame = null;
      // draft.deliveryType = 1;
      // draft.packageType = 4;
    }))

    handleOrderTypeClick(id);
  }

  return <>
    <Grid item xs={12}>
      <Grid container item spacing={2} pl={4} pr={4} pt={2} sx={{justifyContent: "center"}}>
        {OrderTypeData.map((card, id) => (
          <Grid item key={card.id} xs={12} sm={profile?.colorMode === "dark" ? 2.9 : 3}>
            <OrderTypeBox
              id={card.id}
              content={card.content}
              selected={content?.selectedOrderType === card.id}
              height={card.height}
              onClick={() => handleOrder(card.id)}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  </>
}