import { useState } from 'react';

const useValidation = () => {
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const validateEmail = (email: string) => {
    if(!email || email === "") {
      setError(true);
      setErrorMsg("Es muss eine E-Mail-Adresse angegeben werden");
      return;
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(email);
      setError(!isValid);
      setErrorMsg("Das Format dieser E-Mail ist scheinbar ungültig");
      return isValid;
    }
  };

  const validatePassword = (password: string) => {
    if(password.length < 8) {
      setError(true);
      setErrorMsg("Das Passwort muss mindestens 8 Zeichen lang sein");
      return false;
    } else if(/^[a-zA-Z0-9]+$/.test(password)) {
      setError(true);
      setErrorMsg("Das Passwort muss mindestens ein Sonderzeichen enthalten.");
      return false;
    } else if(!/\d/.test(password)) {
      setError(true);
      setErrorMsg("Das Passwort muss mindestens eine Zahl (0-9) enthalten.");
      return false;
    } else if(!/[A-Z]/.test(password)) {
      setError(true);
      setErrorMsg("Das Passwort muss mindestens einen Großbuchstaben enthalten.");
      return false;
    }
    setError(false);
    return true;
  };

  const validateUIDNumber = (uidNumber: string) => {
    if(uidNumber.length !== 11) {
      setError(true);
      setErrorMsg("Eine österreichische UID-Nummer startet mit 'ATU' mit 8 weiterfolgenden Stellen.");
      return false;
    } else if(!/^ATU/.test(uidNumber)) {
      setError(true);
      setErrorMsg("Eine österreichische UID-Nummer startet mit 'ATU' mit 8 weiterfolgenden Stellen.");
      return false;
    }
    setError(false);
    return true;
  }

  return { error, errorMsg, validateEmail, validatePassword, validateUIDNumber };
};

export default useValidation;