import App from './App';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from './components/context/AuthProvider';
import { ProfileProvider } from './components/context/ProfileProvider';
import { PopupProvider } from './components/context/PopupProvider';
import { ContentProvider } from './components/context/ContentProvider';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <BrowserRouter>
      <AuthProvider>
        <ProfileProvider>
          <ContentProvider>
            <PopupProvider>
              <Routes>
                <Route path="/*" element={<App />} />
              </Routes>
            </PopupProvider>
          </ContentProvider>
        </ProfileProvider>
      </AuthProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
