import { RememberedStores } from "../interfaces/UserData"
import useProfile from "../hooks/useProfile"
import React from "react"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"

export const SavedStores = () => {
  const profile = useProfile()
  return <>
    <Container component="main">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography component="h1" variant="h5" sx={{float:"left"}}>
            Gespeicherte Stores
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} mb={5}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {profile?.account?.userRememberedStores.map((value: RememberedStores, index) => {
              return (
                <ListItem
                  key={index}
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={() => {}} dense>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography variant="h6" color="text.primary" gutterBottom>
                            { value.name }
                          </Typography>
                        </React.Fragment>}
                      secondary={
                        <React.Fragment>
                          <Grid container component="span" spacing={2}>
                            <Grid item component="span" xs={12} sm={4}>
                              <Typography variant="body1" color="text.primary" component="span">
                                {`${value.plz} ${value.city}`}<br></br>
                              </Typography>

                              <Typography variant="body1" color="text.primary" component="span">
                                {`${value.address}`}<br></br>
                              </Typography>

                              <Typography variant="body1" color="text.primary" component="span">
                                {`${value.contactNumber}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </Container>
  </>
}