import { useState, createContext, ReactNode } from "react";
import { UserData } from "../interfaces/UserData";
import { PaletteMode } from "@mui/material";

interface ProfileContextProps {
  account: UserData;
  setAccount: React.Dispatch<React.SetStateAction<UserData>>;
  colorMode: PaletteMode | undefined;
  setColorMode: React.Dispatch<"light" | "dark">;
}

interface ProfileProviderProps {
  children: ReactNode;
}

const ProfileContext = createContext<ProfileContextProps | null>(null);

// where in the hell is the update state of this ffs
export const ProfileProvider: React.FC<ProfileProviderProps> = ({children}) => {
  const initialAccountState: UserData = {
    firstName: '',
    lastName: '',
    address: '',
    email: '',
    phoneNumber: "",
    emailConfirmed: false,
    company: null,
    newCustomer: true,
    balance: 0,
    plz: 0,
    city: "",
    userFlags: 0,
    savedRemoteLocations: [],
    userRememberedStores: [],
    activeOrder: null,
    availableDeliveryDates: []
  };

  const [account, setAccount] = useState<UserData>(initialAccountState);
  const [colorMode, setColorMode] = useState("light" as PaletteMode);

  return <ProfileContext.Provider value={{ account, setAccount, colorMode, setColorMode }}>
          {children}
      </ProfileContext.Provider>;
}

export default ProfileContext;