import React, { ReactNode } from 'react';
import Card from "@mui/material/Card"
import CardActionArea from "@mui/material/CardActionArea"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import { CssBaseline } from '@mui/material';
import useProfile from '../../hooks/useProfile';

interface PackageTypeCardProps {
  title: string;
  kg: ReactNode;
  size: ReactNode;
  tarif: ReactNode;
  selected: boolean;
  styling: {},
  image: any,
  height: string,
  onClick: () => void;
}

const PackageTypeCard: React.FC<PackageTypeCardProps> = ({ title, kg, size, tarif, selected, onClick, styling, image, height }) => {
  const profile = useProfile();
  return (
    <div>
    <CssBaseline />
    <Card
      variant={'outlined'}
      onClick={onClick}
      style={styling}
      sx={{
        maxHeight: "220px",
        cursor: 'pointer',
        color: profile?.colorMode === "light" ? "black" : (selected ? "#66BB6A" : "white"),
        border: selected ? '2px solid black' : '1px solid rgba(0, 0, 0, 0.2)',
        borderColor: profile?.colorMode === "light" ? (selected ? "black" : "rgba(0, 0, 0, 0.2)") : (selected ? "#66BB6A" : "rgba(255, 255, 255, 0.23)"),
        backgroundColor: profile?.colorMode === "light" ? (selected ? "#E8E226" : "white") : (selected ? "#121212" : "#121212"),
        boxShadow: selected ? '2px 2px 4px rgba(0, 0, 0, 0.1)' : 'none',
        ":hover": {
          color: profile?.colorMode === "light" ? "black" : "#66BB6A",
          borderColor: profile?.colorMode === "dark" ? "#66BB6A" : "",
          backgroundColor: profile?.colorMode === "light" ? "#E8E226" : ""
        }
      }}
    >
      <CardActionArea>
        <CardMedia 
          component="img"
          height="100px"
          image={image}
          alt="package-small-img"
          sx={{objectFit: "contain"}}
        />
        <CardContent>
          <Typography sx={{fontSize: "16px", fontWeight: "bold", fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif"}}>
            {title}
          </Typography>
          <Typography sx={{fontSize: "13px", fontWeight: "bold", fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif"}}>
            {kg}
          </Typography>
          <Typography sx={{fontSize: "13px", fontWeight: "bold", fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif"}}>
            {size}
          </Typography>
          {/* <Typography variant="body2" color="text.secondary">
            {tarif}
          </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
    </div>
  );
};

export default PackageTypeCard;