import { CssBaseline } from "@mui/material";
import "../styles/PrivacyPolicy.css";

export const PrivacyPolicy = () => {
    return <>
    <CssBaseline/>
    <div className="privacy-policy-container">
            <h1 className="bigger-header">Datenschutzerklärung</h1>
            <section>
                <p><b>Zuletzt aktualisiert: </b>06. Juli 2023</p>
                <p>Vielen Dank für Ihr Interesse an den Informationen auf unserer Website!</p>
                <p>
                    Mit Hilfe dieser Datenschutzerklärung möchten wir die Nutzer unserer Website über die Art, 
                    den Umfang und die Zwecke der Verarbeitung von personenbezogenen Daten informieren. Personenbezogene 
                    Daten in diesem Zusammenhang sind alle Informationen, mit denen Sie als Nutzer unserer Website 
                    (theoretisch, ggf. über Umwege oder mittels Verknüpfung diverser Daten) persönlich identifiziert 
                    werden können u.a. auch Ihre IP-Adresse. Informationen, welche in Cookies abgelegt werden, sind grundsätzlich 
                    nicht bzw. nur in Ausnahmefällen personenbezogen; diese werden allerdings von einer Spezialregelung erfasst,
                    welche die Zulässigkeit des Cookie-Einsatzes abhängig von deren Zweck weitgehend von einer aktiven Einwilligung 
                    des Nutzers abhängig macht.
                </p>
                <p>
                    In einem allgemeinen Abschnitt in dieser Datenschutzerklärung, erteilen wir Ihnen Informationen zum Datenschutz, 
                    die generell für unsere Verarbeitung von Daten einschließlich der Datenerfassung auf unserer Website gelten. 
                    Insbesondere werden Sie als betroffene Personen über die Ihnen zustehenden Rechte aufgeklärt.
                </p>
                <p>
                    Die in unserer Datenschutzerklärung verwendeten Begriffe und unsere Datenschutzpraxis richten sich nach den 
                    Bestimmungen der Datenschutz-Grundverordnung der EU ("DSGVO") sowie den sonstigen einschlägigen nationalen Gesetzesbestimmungen.
                </p>
            </section>
            <h1>Verantwortlicher</h1>
            <section>
                <p>
                    Jumug Vehicles GmbH<br/>FN FN 574169t<br/>Döblerhofstraße 16<br/>1110 Wien<br/>Österreich
                    <br/><br/>
                    <b>E:</b> <a href="mailto:office@jumug.at">office@jumug.at</a><br/>
                    <b>T:</b> +43 1 99 322
                </p>
            </section>
            <h1>Datenerhebung auf unserer Website</h1>
            <section>
                <p>
                    Personenbezogene Daten von Ihnen werden einerseits erhoben, wenn Sie uns diese ausdrücklich mitteilen, andererseits werden Daten 
                    insbesondere technische Daten automatisch beim Besuch unserer Website erfasst. Ein Teil dieser Daten wird erhoben, 
                    um eine fehlerfreie Funktion unserer Website zu gewährleisten. Andere Daten können zu Analysezwecken verwendet werden. 
                    Sie können unsere Website grundsätzlich jedoch nutzen, ohne dass Sie Angaben zu Ihrer Person machen müssen.
                </p>
            </section>
            <h1>Technologien auf unserer Website Cookies und Local Storage</h1>
            <section>
                <p>
                    Auf unserer Website setzen wir Cookies ein, um unseren Internetauftritt nutzerfreundlicher und funktioneller zu gestalten. 
                    Einige Cookies bleiben dabei auf Ihrem Endgerät gespeichert.
                </p>
                <p>
                    Cookies sind kleine Datenpakete, die beim Besuch unserer Website zwischen Ihrem Browser und dem/unserem Webserver ausgetauscht werden. 
                    Diese richten keinerlei Schaden an und dienen lediglich der Wiedererkennung der Website-Besucher:innen. Cookies können nur Informationen 
                    speichern, die von Ihrem Browser geliefert werden, d.h. Informationen die Sie selbst in den Browser eingegeben haben oder auf der Website 
                    vorhanden sind. Cookies können keinen Code ausführen und können nicht verwendet werden, um auf Ihr Endgerät zuzugreifen.
                </p>
                <p>
                    Bei Ihrem nächsten Aufruf unserer Website mit demselben Endgerät können die in Cookies gespeicherten Informationen in weiterer Folge 
                    entweder an uns („Erstanbieter-Cookie“) oder an eine Webanwendung der Dritthersteller, zu der das Cookie gehört („Drittanbieter-Cookie“), 
                    zurückgesandt werden. Durch die gespeicherten und zurückgesandten Informationen erkennt die jeweilige Webanwendung, dass Sie die Website 
                    mit dem Browser Ihres Endgerätes bereits aufgerufen und besucht haben.
                </p>
                <p>Cookies enthalten dabei die folgenden Informationen:</p>
                <ul>
                    <li>Cookie Name</li>
                    <li>Name des Servers, von dem das Cookie ursprünglich stammt</li>
                    <li>Cookie-ID-Nummer</li>
                    <li>Ein Datum, zu dem das Cookie automatisch gelöscht wird</li>
                </ul>
                <p>Je nach Verwendungszweck und Funktion unterteilen wir Cookies in folgende Kategorien:</p>
                <ul>
                    <li>Technisch notwendige Cookies, um den technischen Betrieb und grundlegende Funktionen unserer Website sicherzustellen. 
                        Diese Art von Cookies wird z.B. verwendet, um Ihre Einstellungen beizubehalten, während Sie auf der Website navigieren; 
                        oder sie können dafür sorgen, dass wichtige Informationen während der gesamten Sitzung erhalten bleiben (z.B. Login, Warenkorb).
                    </li>
                    <li>Statistik-Cookies, um zu verstehen, wie Besucher:innen mit unserer Website interagieren, indem Informationen lediglich anonym 
                        gesammelt und analysiert werden. Dadurch gewinnen wir wertvolle Erkenntnisse, um sowohl die Website als auch unsere Produkte 
                        und Dienstleistungen zu optimieren.
                    </li>
                    <li>Marketing-Cookies, um für Benutzer:innen auf unserer Website gezielte Werbeaktivitäten zu setzen.</li>
                    <li>Nicht klassifizierte Cookies sind Cookies, die wir gerade gemeinsam mit Anbieter:innen von individuellen Cookies zu klassifizieren versuchen.</li>
                </ul>
                <p>
                    Je nach Speicherdauer unterteilen wir Cookies auch in Sitzungs- und permanente Cookies. Sitzungs-Cookies speichern Informationen, 
                    die während Ihrer aktuellen Browser-Sitzung verwendet werden. Diese Cookies werden beim Schließen des Browsers automatisch gelöscht. 
                    Dabei bleiben keinerlei Informationen auf Ihrem Endgerät. Permanente Cookies speichern Informationen zwischen zwei Besuchen der Website. 
                    Anhand dieser Informationen werden Sie beim nächsten Besuch als wiederkehrende:r Besucher:innen erkannt und die Website reagiert entsprechend. 
                    Die Lebensdauer eines permanenten Cookies wird von den Anbieter des Cookies bestimmt.
                </p>
                <p>
                    Die Rechtsgrundlage zur Verwendung von technisch notwendigen Cookies beruht auf unserem berechtigten Interesse am technisch einwandfreien 
                    Betrieb und an der reibungslosen Funktionalität unserer Website gemäß Art 6 Abs. 1 lit. f DSGVO. Unsere Website kann ohne diese Cookies 
                    nicht richtig funktionieren. Die Verwendung von Statistik- und Marketing-Cookies benötigt Ihre Einwilligung gemäß Art 6 Abs. 1 lit. a DSGVO. 
                    Sie können Ihre Einwilligung zur Nutzung von Cookies gemäß Art 7 Abs. 3 DSGVO jederzeit für die Zukunft widerrufen. Die Einwilligung ist freiwillig. 
                    Wird sie nicht erteilt, entstehen keine Nachteile. Weitere Informationen über die von uns tatsächlich verwendeten Cookies (insbesondere über ihren 
                    Zweck und ihre Speicherdauer) finden Sie in dieser Datenschutzerklärung und in den Informationen über die von uns verwendeten Cookies in unserem Cookie-Banner.
                </p>
                <p>
                    Sie können weiters auch Ihren Internet-Browser so einstellen, dass das Speichern von Cookies generell auf Ihrem Endgerät verhindert wird bzw. 
                    Sie jedes Mal gefragt werden, ob Sie mit dem Setzen von Cookies einverstanden sind. Einmal gesetzte Cookies können Sie jederzeit wieder löschen. 
                    Wie all dies im Einzelnen funktioniert, erfahren Sie in der Hilfe-Funktion Ihres Browsers.
                </p>
                <p>Bitte beachten Sie, dass eine generelle Deaktivierung von Cookies gegebenenfalls zu Funktionseinschränkungen auf unserer Website führen kann.</p>
                <p>
                    Auf unserer Website verwenden wir auch sogenannte Local-Storage-Funktionen (auch „Lokaler Speicher“ genannt). Dabei werden Daten lokal im Cache 
                    Ihres Browsers gespeichert, die auch nach dem Schließen des Browsers – soweit sie den Cache nicht löschen oder es sich um den Session Storage handelt - 
                    weiterhin bestehen und ausgelesen werden können.
                </p>
                <p>
                    Auf die im Local Storage gespeicherten Daten können Dritte nicht zugreifen. Soweit spezielle Plugins oder Tools die Local-Storage-Funktionen verwenden, 
                    ist dies beim jeweiligen Plugin oder Tool beschrieben.
                </p>
                <p>
                    Wenn Sie nicht wünschen, dass Plugins oder Tools Local-Storage-Funktionen einsetzen, dann können Sie das in den Einstellungen Ihres jeweiligen 
                    Browsers steuern. Wir weisen darauf hin, dass es dann möglicherweise zu Funktionseinschränkungen kommen kann.
                </p>
            </section>
            <h1>Hosting</h1>
            <section>
                <p>
                    Im Rahmen des Hostings unserer Website werden sämtliche im Zusammenhang mit dem Betrieb unserer Website zu verarbeitenden Daten gespeichert. 
                    Dies ist notwendig, um den Betrieb der Website zu ermöglichen. Die Daten verarbeiten wir daher entsprechend auf der Grundlage unseres berechtigten 
                    Interesses gemäß Art 6 Abs. 1 lit. f DSGVO an der Optimierung unseres Webseitenangebotes. Zur Bereitstellung unseres Onlineauftritts nutzen wir 
                    Dienste von Webhosting-Anbietern, denen wir die oben genannten Daten im Rahmen einer Auftragsverarbeitung gemäß Art 28 DSGVO zur Verfügung stellen.
                </p>
            </section>
            <h1>Kontaktaufnahme</h1>
            <section>
                <p>
                    Im Rahmen der Kontaktaufnahme mit uns werden Ihre Angaben zur Bearbeitung der Kontaktanfrage und deren Abwicklung im Rahmen der Erfüllung 
                    vorvertraglicher Rechte und Pflichten gemäß Art. 6 Abs. 1 lit. b DSGVO genutzt. Die Verarbeitung Ihrer Daten ist zur Bearbeitung und 
                    Beantwortung Ihrer Anfrage erforderlich, widrigenfalls wir Ihre Anfrage nicht oder allenfalls nur eingeschränkt beantworten können. 
                    Die Angaben können auf Basis unseres berechtigten Interesses gem. Art 6 Abs. 1 lit. f DSGVO am Direktmarketing in einer Kunden- und 
                    Interessentendatenbank gespeichert werden.
                </p>
                <p>
                    Wir löschen Ihre Anfrage und Ihre Kontaktdaten, sofern Ihre Anfrage abschließend beantwortet wurde und der Löschung keinerlei gesetzlichen 
                    Aufbewahrungsfristen entgegenstehen, z.B. im Rahmen einer nachfolgenden Vertragsabwicklung. Zumeist ist dies der Fall, wenn drei Jahre durchgehend 
                    kein Kontakt mehr mit Ihnen bestanden hat.
                </p>
            </section>
            <h1>Piwik Pro / Matomo</h1>
            <section>
                <p>Auf unserer Website verwenden wir zur Webanalyse den Dienst Piwik Pro / Matomo des Anbieter Piwik PRO GmbH, Kurfürstendamm 21, 10719 Berlin, Deutschland.</p>
                <p>
                    Den Dienst Piwik Pro / Matomo verwenden wir als Analyse- und Kundendatenplattform insbesondere um die Benutzer-Erfahrung zu optimieren, indem wir 
                    Ihnen auf Sie zugeschnittene Produkte, Inhalte oder Services anbieten. Wir sammeln im Rahmen dieses Dienstes daher First-Party-Daten über 
                    Website-Besucher auf der Grundlage von Cookies, IP-Nummern und sog. Browser-Fingerprints; wir erstellen Benutzerprofile auf der Grundlage des 
                    Browserverlaufs und berechnen Metriken in Bezug auf die Website-Nutzung, wie Bounce-Rate, Intensität der Besuche, Seitenaufrufe etc.  Die Analyse 
                    erfasst Daten, welche Inhalte, Seiten und Funktionen Sie auf unserer Website nutzen.
                    <br/>
                    Weiter Infos hierzu finden Sie unter: <a href="https://piwikpro.de/datenschutz/" target="blank" rel="noreferrer noopener">https://piwikpro.de/datenschutz/</a>
                </p>
                <p>
                    Die Verarbeitung Ihrer Daten insbesondere das Tracking im Rahmen dieses Dienstes basiert auf Ihre Einwilligung gemäß Artikel 6 Abs. 1 lit. a DSGVO. 
                    Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen.
                </p>
            </section>
            <h1>Server-Log-Files</h1>
            <section>
                <p>
                    Aus technischen Gründen, insbesondere zur Gewährleistung eines funktionellen und sicheren Internetauftritts verarbeiten wir technisch notwendige 
                    Daten über Zugriffe auf unsere Website in so genannten Server-Log Files, die Ihr Browser automatisch an uns übermittelt.
                </p>
                <p>Zu den Zugriffsdaten, die wir verarbeiten gehören:</p>
                <ul>
                    <li>Name der abgerufenen Website</li>
                    <li>verwendeter Browsertyp inkl. Version</li>
                    <li>verwendetes Betriebssystem der Besucher:innen</li>
                    <li>die zuvor besuchte Seite der Besucher:innen (Referrer URL)</li>
                    <li>Uhrzeit der Serveranfrage</li>
                    <li>übertragene Datenmenge</li>
                    <li>Hostname des zugreifenden Rechners (verwendete IP-Adresse)</li>
                </ul>
                <p>
                    Diese Daten werden keinen natürlichen Personen zugeordnet und dienen lediglich statistischen Auswertungen sowie zum Betrieb und Verbesserung 
                    unserer Website als auch zur Sicherheit und Optimierung unseres Internetangebotes. Eine Übermittlung dieser Daten erfolgt lediglich an unseren 
                    Websitehoster. Eine Verbindung oder Zusammenführung dieser Daten mit anderen Datenquellen erfolgt nicht. Falls der Verdacht auf eine 
                    rechtswidrige Nutzung unserer Website besteht, behalten wir uns vor, diese Daten nachträglich zu prüfen. Die Datenverarbeitung stützt sich 
                    dabei auf unser berechtigtes Interesse gemäß Art 6 Abs. 1 lit. f DSGVO an der technisch fehlerfreien Darstellung und der Optimierung unserer Website.
                </p>
                <p>
                    Die Zugriffsdaten werden kurzfristig nach Erledigung des Zweckes, zumeist nach wenigen Tagen wieder gelöscht, soweit keine weitere Aufbewahrung 
                    zu Beweiszwecken erforderlich ist. Andernfalls werden die Daten bis zur endgültigen Klärung eines Vorfalls aufbewahrt.
                </p>
            </section>
            <h1>WebCare</h1>
            <section>
                <p>
                    Um eine datenschutzkonforme Einwilligung für die Nutzung von Cookies und Tools auf unserer Website einzuholen, nutzen wir den Consent Banner 
                    von DataReporter WebCare. Dies ist ein Dienst der seitens der DataReporter GmbH, Zeileisstraße 6, 4600 Wels, Österreich ("DataReporter") zur 
                    Verfügung gestellt wird. 
                </p>
                <p>
                    Nähere Informationen zu diesem Unternehmen erhalten Sie unter www.datareporter.eu. Der Consent Banner erfasst und speichert die Entscheidung 
                    der jeweiligen Benutzer unserer Webseite. Es wird durch unseren Consent Banner gewährleistet, dass statistische und marketingtechnische Cookies 
                    erst dann gesetzt werden, wenn der Nutzer seine ausdrückliche Einwilligung zur Nutzung erklärt hat. 
                </p>
                <p>
                    Wir speichern dafür Informationen, inwieweit der Nutzer die Verwendung von Cookies bestätigt hat. Die Entscheidung des Nutzers ist dabei jederzeit 
                    widerrufbar, indem die Einstellung zu den Cookies aufgerufen und die Einwilligungserklärung verwaltet wird. Bestehende Cookies werden nach Widerruf 
                    der Einwilligung gelöscht. Für die Speicherung der Information über den Status der Einwilligung des Nutzers wird ebenfalls ein Cookie gesetzt, auf 
                    das in den Cookie Details hingewiesen wird. Weiters wird für den Aufruf dieses Dienstes die IP-Adresse des jeweiligen Nutzers an Server von 
                    DataReporter übertragen. Die IP-Adresse wird dabei weder gespeichert noch mit irgendwelchen anderen Daten des Nutzers in Verbindung gebracht, sie wird 
                    lediglich für die korrekte Ausführung des Services verwendet. Die Nutzung der oben angeführten Daten beruht daher auf unserem berechtigten Interesse 
                    an der rechtskonformen Ausgestaltung unseres Internetauftrittes gemäß Art 6 Abs. 1 lit. f DSGVO.
                </p>
                <p>
                    Weitere Informationen finden Sie in der Datenschutzerklärung von DataReporter unter <a href="https://www.datareporter.eu/de/privacystatement.html" 
                    target="_blank" rel="noreferrer noopener">https://www.datareporter.eu/de/privacystatement.html</a> Anfragen zu diesem Service können Sie gerne an 
                    office@datareporter.eu richten.
                </p>
            </section>
            <h1 className="bigger-header">Allgemeine Information zum Datenschutz</h1>
            <section>
                <p>
                    Die folgenden Bestimmungen gelten in Ihren Grundsätzen nicht nur für die Datenerhebung auf unserer Website, sondern auch generell für die sonstige 
                    Verarbeitung von personenbezogenen Daten.
                </p>
                <h1>Personenbezogene Daten</h1>
                <section>
                    <p>
                        Personenbezogene Daten sind Informationen, die Ihnen individuell zugeordnet werden können. Beispiele hierfür sind u.a. Ihre Adresse, Ihr Name 
                        sowie Ihre Postanschrift, E-Mail Adresse oder Telefonnummer. Angaben wie z.B. die Anzahl der Nutzer, die eine Website besuchen sind keine 
                        personenbezogenen Daten, weil sie keine Zuordnung zu einer einzelnen Person ermöglichen.
                    </p>
                </section>
                <h1>Rechtsgrundlagen für die Verarbeitung von personenbezogenen Daten</h1>
                <section>
                    <p>
                        Sofern in dieser Datenschutzerklärung (z.B. bei den eingesetzten Technologien) nicht speziellere Informationen angeführt werden, können wir auf 
                        Basis folgender Rechtsgrundlagen personenbezogene Daten von Ihnen verarbeiten:
                    </p>
                    <ul>
                        <li>
                            <b>Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO</b> – Der Betroffen hat seine Einwilligung in die Verarbeitung seiner personenbezogenen Daten 
                            für einen oder mehrere bestimmte Zwecke gegeben.
                        </li>
                        <li>
                            <b>Vertragserfüllung und vorvertragliche Maßnahmen gemäß Art. 6 Abs. 1 lit. b DSGVO</b> – Die Verarbeitung ist für die Erfüllung eines Vertrags, 
                            dessen Vertragspartei der Betroffene ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich.
                        </li>
                        <li><b>Rechtliche Verpflichtung gemäß Art. 6 Abs. 1 lit. c DSGVO</b> – Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich.</li>
                        <li>
                            <b>Schutz lebenswichtiger Interessen gemäß Art. 6 Abs. 1 lit. d DSGVO</b> – Die Verarbeitung ist erforderlich, um lebenswichtige Interessen des 
                            Betroffenen oder einer anderen natürlichen Person zu schützen.
                        </li>
                        <li>
                            <b>Berechtigte Interessen gemäß Art. 6 Abs. 1 lit. f DSGVO</b> - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen 
                            oder eines Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten des Betroffenen überwiegen.
                        </li>
                    </ul>
                    <p>Bitte beachten Sie, dass zusätzlich zu den Regelungen der DSGVO die nationalen Datenschutzbestimmungen in Ihrem bzw. unserem Heimatland gelten können.</p>
                </section>
                <h1>Übermittlung von personenbezogenen Daten</h1>
                <section>
                    <p>Eine Übermittlung Ihrer personenbezogenen Daten an Dritte zu anderen als den in dieser Datenschutzerklärung aufgeführten Zwecken findet nicht statt.</p>
                    <p><u>Wir geben Ihre persönlichen Daten nur an Dritte weiter, sofern:</u></p>
                    <ul>
                        <li>Sie Ihre gemäß <b>Art. 6 Abs. 1 lit. a DSGVO</b> ausdrückliche <b>Einwilligung</b> dazu erteilt haben,</li>
                        <li>
                            die Weitergabe gemäß <b>Art. 6 Abs. 1 lit. f DSGVO</b> zur Wahrung der <b>berechtigten Interessen</b> sowie zur Geltendmachung, Ausübung oder Verteidigung 
                            von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der 
                            Nichtweitergabe Ihrer Daten haben,
                        </li>
                        <li>für die Weitergabe nach <b>Art. 6 Abs. 1 lit. c DSGVO</b> eine <b>rechtliche Verpflichtung</b> besteht, sowie dies gesetzlich zulässig ist und / oder</li>
                        <li>es gemäß <b>Art. 6 Abs. 1 lit. b DSGVO</b> für die <b>Abwicklung von Vertragsverhältnissen</b> mit Ihnen erforderlich ist.</li>
                    </ul>
                </section>
                <h1>Zusammenarbeit mit Auftragsverarbeitern</h1>
                <section>
                    <p>
                        Wir wählen unsere Dienstleister, die in unserem Auftrag personenbezogene Daten verarbeiten, sorgfältig aus. Sofern wir Dritte mit der Verarbeitung 
                        von personenbezogenen Daten auf Grundlage eines Auftragsverarbeitungsvertrages beauftragen, geschieht dies gemäß <b>Art. 28 DSGVO</b>.
                    </p>
                </section>
                <h1>Übermittlung in Drittländer</h1>
                <section>
                    <p>
                        Sofern wir Daten in einem Drittland verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder Offenlegung bzw. Übermittlung von 
                        Daten an andere Personen oder Unternehmen geschieht, erfolgt dies nur auf Basis der oben für die Weitergabe von Daten dargestellten Rechtsgrundlagen.
                    </p>
                    <p>
                        Vorbehaltlich ausdrücklicher Einwilligung oder vertraglicher Erforderlichkeit, verarbeiten oder lassen wir die Daten im Einklang mit Art. 44-49 DSGVO 
                        nur in Drittländern mit einem als angemessen anerkannten Datenschutzniveau oder auf Grundlage besonderer Garantien, wie z.B. einer vertraglichen 
                        Verpflichtung durch sogenannte Standardvertragsklauseln der EU-Kommission, dem Vorliegen von Zertifizierungen oder verbindlichen internen 
                        Datenschutzvorschriften, verarbeiten.
                    </p>
                </section>
                <h1>Datenübermittlung in die USA / Wegfall des Privacy Shields</h1>
                <section>
                    <p>
                        Wir möchten ausdrücklich darauf hinweisen, dass mit 16. Juli 2020 aufgrund eines Rechtsstreits einer Privatperson und der irischen Aufsichtsbehörde 
                        das sogenannte „Privacy-Shield“, ein Angemessenheitsbeschluss der EU Kommission nach Art 45 DSGVO, mit dem den USA unter bestimmten Umständen ein 
                        angemessenes Datenschutz Niveau bestätigt wurde, per unverzüglicher Wirkung nicht mehr gültig ist.
                    </p>
                    <p><b>Das Privacy Shield stellt daher keine gültige Rechtsgrundlage für die Übermittlung personenbezogener Daten in die USA mehr dar!</b></p>
                    <p>
                        Sofern eine Datenübermittlung durch uns in die USA überhaupt stattfindet oder ein Dienstleister mit Sitz in den USA von uns eingesetzt wird, 
                        verweisen wir darauf explizit in dieser Datenschutzerklärung (siehe insbesondere die Beschreibung der Technologien auf unserer Website).
                    </p>
                    <p><b>Was kann die Übermittlung von personenbezogenen Daten in die USA für Sie als Nutzer bedeuten und welche Risiken bestehen in diesem Zusammenhang?</b></p>
                    <p>
                        Risiken für Sie als Nutzer sind jedenfalls die Befugnisse der US-Geheimdienste und die Rechtslage in den USA, die ein angemessenes Datenschutz-Niveau 
                        derzeit, nach Ansicht des EuGH, nicht mehr sicherstellen. U.a. handelt es sich dabei um folgende Punkte:
                    </p>
                    <ul>
                        <li>
                            Section 702 des Foreign Intelligence Surveillance Act (FISA) sieht keine Beschränkungen der Überwachungsmaßnahmen der Geheimdienste und keine 
                            Garantien für Nicht-US-Bürger vor.
                        </li>
                        <li>
                            Presidential Policy Directive 28 (PPD-28) gibt Betroffenen keine wirksamen Rechtsbehelfe gegen Maßnahmen der US-Behörden und sieht keine Schranken 
                            für die Sicherstellung verhältnismäßiger Maßnahmen vor.
                        </li>
                        <li>
                            der im Privacy Shield vorgesehene Ombudsmann hat keine genügende Unabhängigkeit von der Exekutive; er kann keine bindenden Anordnungen gegenüber 
                            den Geheimdiensten treffen.
                        </li>
                    </ul>
                    <p><b>Rechtskonforme Übermittlung von Daten in die USA auf Basis der Standardvertragsklauseln?</b></p>
                    <p>
                        Die von der Kommission im Jahr 2010 beschlossenen Standardvertragsklauseln (2010/87/EU vom 05.02.2010), Art. 46 Abs. 2 c DS-GVO, sind weiterhin 
                        gültig, jedoch muss ein Schutzniveau für die personenbezogenen Daten sichergestellt sein, das dem in der Europäischen Union entspricht. 
                        Hier sind also nicht nur die vertraglichen Beziehungen mit unseren Dienstleistern relevant, sondern auch die Zugriffsmöglichkeit auf die 
                        Daten durch Behörden in den USA und das dortige Rechtssystem (Gesetzgebung und Rechtssprechung, Verwaltungspraxis von Behörden).
                    </p>
                    <p>
                        Die Standardvertragsklauseln können Behörden in den USA nicht binden und stellen daher in den Fällen, in denen die Behörden nach dem Recht in 
                        den USA befugt sind, in die Rechte der betroffenen Personen einzugreifen ohne zusätzliche Maßnahme durch uns und unserem Dienstleister noch keinen 
                        angemessenen Schutz dar.
                    </p>
                    <p><b>Rechtskonforme Übermittlung von Daten in die USA auf Basis Ihrer Einwilligung?</b></p>
                    <p>
                        Es ist derzeit umstritten ob eine informierte Einwilligung und damit eine willentliche und wissentliche Einschränkung von Teilen Ihres Grundrechts 
                        auf Datenschutz überhaupt rechtlich möglich ist.
                    </p>
                    <p><b>Welche Maßnahmen ergreifen wir, um eine Datenübermittlung in die USA rechtskonform zu gestalten?</b></p>
                    <p>
                        Soweit US-Anbieter die Möglichkeit anbieten, wählen wir die Verarbeitung von Daten auf EU-Servern. Damit sollte technisch sichergestellt sein, 
                        dass die Daten innerhalb der Europäischen Union liegen und ein Zugriff durch US Behörden nicht möglich ist.
                    </p>
                    <p>
                        Des Weiteren prüfen wir sorgfältig europäische Alternativen zu eingesetzten US-Tools. Dies ist jedoch ein Prozess, der nicht von heute auf morgen geht, 
                        da es auch um technische und wirtschaftliche Konsequenzen für uns geht. Nur sofern aus technischen und / oder wirtschaftlichen Gründen der Einsatz 
                        von europäischen Tools und / oder das sofortige Abschalten der US-Tools für uns unmöglich ist, werden US-Dienstleister derzeit weiterverwendet.
                    </p>
                    <p><b>Für die weitere Verwendung von US-Tools treffen wir folgende Maßnahmen:</b></p>
                    <p>
                        Soweit möglich, wird vor Einsatz eines US-Tools Ihre Einwilligung abgefragt und sie vorab transparent über die Funktionsweise eines Dienstes informiert. 
                        Die Risiken bei Übermittlung von Daten in die USA finden Sie in diesem Punkt.
                    </p>
                    <p>
                        Mit US Dienstleistern bemühen wir uns Standardvertragsklauseln abzuschließen und zusätzliche Garantien einzufordern.  Insbesondere verlangen wir 
                        den Einsatz von Technologien die einen Zugriff auf Daten nicht möglich machen z.B. den Einsatz von Verschlüsselungen, die auch von US-Diensten 
                        nicht gebrochen werden können oder Anonymisierung bzw. Pseudonymisierung der Daten, bei der nur der Dienstleister die Zuordnung vornehmen kann.  
                        Gleichzeitig verlangen wir zusätzliche Informationen vom Dienstleister, falls tatsächlich ein Zugriff auf Daten durch Dritte passiert bzw. das 
                        Ausschöpfen sämtlicher Rechtsmittel durch den Dienstleister, bis ein Zugriff auf Daten überhaupt gewährt wird.
                    </p>
                </section>
                <h1>Speicherdauer im Allgemeinen</h1>
                <section>
                    <p>
                        Sofern bei der Erhebung von Daten (z.B. im Rahmen einer Einwilligungserklärung) keine ausdrückliche Speicherdauer angegeben wird, sind wir gemäß 
                        <b>Art. 5 Abs. 1 lit. e DSGVO</b> verpflichtet personenbezogene Daten zu löschen, sobald der Zweck ihrer Verarbeitung nicht mehr besteht. In diesem 
                        Zusammenhang möchten wir darauf hinweisen, dass gesetzliche Aufbewahrungspflichten, denen wir unterliegen, einen legitimen Zweck für die 
                        Weiterverarbeitung der davon erfassten personenbezogenen Daten darstellen.
                    </p>
                    <p>
                        Daten werden von uns in personenbezogener Form grundsätzlich bis zur Beendigung einer Geschäftsbeziehung oder bis zum Ablauf geltender Garantie-, 
                        Gewährleistungs- oder Verjährungsfristen, darüber hinaus bis zur Beendigung von allfälligen Rechtsstreitigkeiten, bei denen die Daten als Beweis 
                        benötigt werden, oder jedenfalls bis zum Ablauf des dritten Jahres nach dem letzten Kontakt mit einem Geschäftspartner gespeichert und aufbewahrt.
                    </p>
                </section>
                <h1>Speicherdauer im Speziellen</h1>
                <section>
                    <p>
                        Im Rahmen der Beschreibung einzelner Technologien auf unserer Website finden sich konkrete Hinweise auf die Speicherdauer von Daten. In unserer 
                        Cookie-Tabelle werden Sie über die Speicherdauer einzelner Cookies informiert. Zusätzlich haben Sie auch immer die Möglichkeit bei uns direkt die 
                        konkrete Speicherdauer von Daten zu erfragen. Dafür wenden Sie sich bitte an jene in dieser Datenschutzerklärung angeführten Kontaktdaten.
                    </p>
                </section>
                <h1>Rechte von Betroffenen</h1>
                <section>
                    <p><u>Betroffene Personen haben das Recht:</u></p>
                    <ul>
                        <li>
                            (i) <b>gemäß Art. 15 DSGVO, Auskunft</b> über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft 
                            über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt 
                            wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder 
                            Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen 
                            einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftige Informationen zu deren Einzelheiten verlangen;
                        </li>
                        <li>
                            (ii) <b>gemäß Art. 16 DSGVO</b>, unverzüglich die <b>Berichtigung</b> unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten 
                            personenbezogenen Daten zu verlangen;
                        </li>
                        <li>
                            (iii) <b>gemäß Art. 17 DSGVO</b>, unter bestimmten Umständen die <b>Löschung</b> Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, 
                            soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, 
                            aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
                        </li>
                        <li>
                            (iv) <b>gemäß Art. 18 DSGVO</b>, die (vorübergehende) <b>Einschränkung der Verarbeitung</b> Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit 
                            der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen, wir die Daten nicht mehr benötigen, 
                            Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen 
                            die Verarbeitung eingelegt haben;
                        </li>
                        <li>
                            (v) <b>gemäß Art. 20 DSGVO</b>, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren 
                            Format von uns erhalten oder deren direkte Übermittlung an einen anderen Verantwortlichen zu verlangen; Davon sind allerdings nur jene Ihrer 
                            personenbezogenen Daten erfasst, die wir nach Ihrer Einwilligung oder auf Basis eines Vertrages mithilfe automatisierter Verfahren verarbeiten;
                        </li>
                        <li>
                            (vi) <b>gemäß Art. 21 DSGVO</b>, sofern Ihre personenbezogenen Daten auf Grundlage unseres berechtigten Interesses verarbeitet werden, <b>Widerspruch</b> 
                            gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben 
                            oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer 
                            besonderen Situation von uns umgesetzt wird;
                        </li>
                        <li>
                            (vii) <b>gemäß Art. 7 Abs. 3 DSGVO</b> Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu <b>widerrufen</b>. Dies hat zur Folge, dass wir die 
                            Datenverarbeitung, die auf dieser Einwilligung beruhte, zukünftig nicht mehr fortführen dürfen. Unter anderem haben Sie die Möglichkeit Ihre 
                            einmal erteilte Einwilligung zur Nutzung von Cookies auf unserer Website mit Wirkung für die Zukunft zu widerrufen, indem Sie unsere <b>Cookie 
                            Einstellungen</b> aufrufen;
                        </li>
                        <li>
                            (viii) <b>gemäß Art. 77 DSGVO</b> sich hinsichtlich der rechtswidrigen Verarbeitung Ihrer Daten durch uns bei einer Aufsichtsbehörde zu <b>beschweren</b>. 
                            In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres gewöhnlichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes 
                            wenden.
                        </li>
                    </ul>
                    <p><u>Die zuständige Aufsichtsbehörde für Jumug Vehicles GmbH ist:</u></p>
                    <p>
                        <b>Österreichische Datenschutzbehörde</b><br/>
                        Barichgasse 40-42, 1030 Wien, Österreich<br/>
                        Tel.: +43 1 52 152-0, dsb@dsb.gv.at
                    </p>
                </section>
                <h1>Geltendmachung von Betroffenenrechten</h1>
                <section>
                    <p>
                        Sie selbst entscheiden über die Verwendung Ihrer personenbezogenen Daten. Sollten Sie daher eine Ihrer oben genannten Rechte gegenüber uns ausüben wollen, 
                        können Sie sich gerne per E-Mail an <a href="mailto:office@jumug.at">office@jumug.at</a> oder per Post, sowie telefonisch an uns wenden.
                    </p>
                    <p>
                        Bitte unterstützen Sie uns bei der Konkretisierung Ihrer Anfrage durch Beantwortung von Fragen unserer zuständigen Mitarbeiter hinsichtlich der konkreten 
                        Verarbeitung Ihrer personenbezogenen Daten. Bei berechtigten Zweifeln an Ihrer Identität kann ggf. eine Ausweiskopie von uns verlangt werden.
                    </p>
                    <p>
                        Für Fragen zum Thema Datenschutz erreichen Sie uns unter <a href="mailto:office@jumug.at">office@jumug.at</a> oder unter den in dieser 
                        Datenschutzerklärung angeführten sonstigen Kontaktdaten.
                    </p>
                    <p><b>Wien</b>, am  6. Juli 2023</p>
                </section>
            </section>
        </div>
        </>
}