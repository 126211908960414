import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, ThemeProvider, Typography, styled } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

interface CreditNeededProps {
  open: boolean;
  onClose: () => void;
}

export const CreditNeededPopup: React.FC<CreditNeededProps> = ({ open, onClose }) => {
  const theme = useTheme();
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

  const onOpenCreditRecharge = async () => {
    // onOpenPopup("confirmOrder");
  }
  
  return <>
    <ThemeProvider theme={theme}>
      <BootstrapDialog open={open} maxWidth="md" fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }} bgcolor="#fff" color="#000">
          Guthaben nicht ausreichend!
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: "#000"
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            Dein Guthaben reicht leider nicht mehr aus um ein weiteres Paket zum Auftrag hinzuzufügen. Lade dein Guthaben auf um fortfahren zu können.
          </Typography>
          {/* <Typography gutterBottom>
            Die Zustellung bis 20 Uhr.
          </Typography> */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button onClick={onOpenCreditRecharge} variant="contained" color="yellow" fullWidth>
                Guthaben aufladen
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <Button onClick={onFinishOrder} variant="contained" color="success" fullWidth disabled={isLoadingAddPackage || isLoadingFinishOrder}>
                { isLoadingFinishOrder ? <CircularProgress size={34} color="inherit"/> :  "Buchen und beenden"} 
              </Button> */}
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </ThemeProvider>
  </>
}