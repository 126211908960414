import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TypographyHeading } from "../common/StyledMuiComponents";
import useAxios from "../hooks/useAxios";
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from "@mui/material/Divider";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from "@mui/material/CircularProgress";
import { EmailTemplate } from "../interfaces/EmailTemplate";
// import ClearIcon from '@mui/icons-material/Clear';

export const EmailTemplates = () => {
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<number | null>(null);
  const { fetchData, isLoading } = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData("/admin/emailstyles", {}, true, onSuccess, () => {})
  }, [])

  const handleToggle = (value: number) => () => {
    setSelectedEmail(value);
  };

  const onSuccess = (response: any) => {
    console.log(response);
    setEmailTemplates(response);
  }

  const onEditClick = (userId: number) => {
    userId !== null && navigate("../../admin/emailtemplates/edit", { state: { userId: userId} })
  }

  return <>
    <Container>
      <CssBaseline/>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TypographyHeading>
              E-Mail Vorlagen
            </TypographyHeading>
          </Grid>
          <Grid item xs={12} sm={12} mb={2}>
            <Divider />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          { isLoading ?
            <Grid item xs={12} textAlign="left">
              <CircularProgress size={14} color="inherit"/> Liste wird geladen
            </Grid>
            :
            <Grid item xs={12} sm={12}>
              <List sx={{ width: '100%'}}>
                {emailTemplates.map((user: EmailTemplate, index) => {
                  const labelId = `checkbox-list-label-${index}`;

                  return (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <div>
                          <IconButton edge="end" aria-label="edit" onClick={() => onEditClick(user.id)}>
                            <EditIcon />
                          </IconButton>
                          {/* <IconButton edge="end" aria-label="delete">
                            <ClearIcon />
                          </IconButton> */}
                        </div>
                      }
                      disablePadding
                    >
                      <ListItemButton role={undefined} onClick={handleToggle(index)} dense>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={index === selectedEmail}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${user.emailSubject} `} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          }
        </Grid>
      </Box>
    </Container>
  </>
}