import { useCallback, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from '@mui/material/utils'
import { toast } from 'react-toastify';
import { Cookies } from "react-cookie-consent";
import axiosOnlyUrl from "../api/axiosOnlyUrl";
import axiosOriginal from "axios";

interface StreetData {
  street: string;
  lat: number;
  lon: number;
}

export const useGetAddressOptions = () => {
  const [options, setOptions] = useState<StreetData[]>([]);
  const [addressNames, setAddressNames] = useState<string[]>([]);
  const abortControllerRef = useRef<AbortController | null>(null);
  const navigate = useNavigate();

  const getAutocompleteOptions = useCallback(
    debounce(async (searchParam, plz) => {

      if (!searchParam || searchParam === "") {
        return;
      }

      if (!plz || plz <= 0) {
        return;
      }

      // Abort any pending requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create new abort controller
      const newAbortController = new AbortController();
      abortControllerRef.current = newAbortController;

      try {
        const bearerToken = Cookies.get('token');
        const config = {
          headers: { Authorization: `Bearer ${bearerToken}` },
          signal: newAbortController.signal
        };

        const response = await axiosOnlyUrl.get(`/address/search?address=${searchParam}&plz=${plz}`, config);
        setOptions(response?.data);
        const streetNames: string[]= response?.data.map((data: { street: string, lat: number, lon: number; }) => data.street);
        setAddressNames(streetNames);

      } catch (error: any) {
        handleApiError(error);
      }
    }, 500),
    []
  );

  const handleApiError = (error: any) => {
    if(error.response) {
      if(axiosOriginal.isAxiosError(error)) {
        if (error.response) {
          if(error.response.status === 401) {
            toast.error(error.response.data.error || "Benutzerauthentifizierung erforderlich. Bitte melden Sie sich erneut an.", {
              position: toast.POSITION.TOP_RIGHT
            });
            navigate('/login');
          } else if(error.response.status === 404) {
            toast.error(error.response.data.error || "404 - Not Found", {
              position: toast.POSITION.TOP_RIGHT
            });
          } else {
            toast.error(error.response.data.error || "Ein unerwarteter Fehler ist aufgetreten.", {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        }
      }
    }
  };

  return {
    getAutocompleteOptions,
    options,
    setOptions,
    addressNames
  };
};