import { ThemeProvider, styled, useTheme } from '@mui/material/styles';
import { TypographyPlus } from '../common/StyledMuiComponents';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import useProfile from '../hooks/useProfile';

interface CallVeloceProps {
  open: boolean;
  onClose: () => void;
}

export const CallVelocePopup: React.FC<CallVeloceProps> = ({open, onClose }) => {
  const globalTheme = useTheme();
  const profile = useProfile();

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
  
  return <>
    <ThemeProvider theme={globalTheme}>
      <BootstrapDialog open={open} maxWidth="sm" fullWidth>
        {/* <DialogTitle sx={{ m: 0, p: 2 }}></DialogTitle> */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <TypographyPlus gutterBottom mt={3} textAlign="center">
              Bitte rufen Sie die Telefonnummer 
              <b style={{color: profile?.colorMode === "dark" ? "#949494" : ""}}> 52117</b> an, 
              um Buchungen mit einem Warenwert von über 200€ abzuschließen.
            </TypographyPlus>

            <Grid item xs={12} /* textAlign={"center"} */>
              <Button onClick={onClose} variant="contained" color="yellow" fullWidth>
                Ok
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </ThemeProvider>
  </>
}