import React from 'react';
import { Box, Button, Container, CssBaseline, Dialog, Divider, Grid, ThemeProvider, Typography, createTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useNavigate } from 'react-router-dom';

interface VerifyNeededProps {
  open: boolean;
  email: string;
  onClose: () => void;
}

export const VerifyNeededPopup: React.FC<VerifyNeededProps> = ({open, onClose, email}) => {
  const defaultTheme = createTheme();
  const navigate = useNavigate();

  const onNavigateToLogin = () => {
    navigate("/login");
  }
  
  return (
    <Dialog open={open}>
      <ThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <Container component="main" maxWidth="xs">
            <Button
              aria-label="add package"
              color="inherit"
              onClick={onClose}
              sx={{position: "absolute", right: "0px", top:"0px"}}
            >
              <CloseIcon />
            </Button>
            <Box
              sx={{
                padding: 2,
                textAlign: 'center',
              }}
            >
              <Grid container alignItems="center" justifyContent="center" spacing={2}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                  <Divider flexItem orientation="horizontal" sx={{ width: '30%', my: 2, mx: 1, alignSelf:"center" }}/>
                  <Box sx={{ position: 'relative' }}>
                    <Box
                      sx={{
                        position: '',
                        border: '2px solid #000',
                        borderRadius: '50%',
                        padding: 2,
                      }}
                    >
                      <MailOutlineIcon sx={{ fontSize: '64px' }} color="success"/>
                    </Box>
                  </Box>
                  <Divider flexItem orientation="horizontal" sx={{ width: '30%', my: 2, mx: 1, alignSelf:"center" }} />
                </Grid>

                <Grid item xs={12}>
                  <Typography component="h1" variant="h5" gutterBottom>
                    Mit Ihrer E-Mail-Adresse verifizieren
                  </Typography>
                </Grid>
                <Grid item xs={12} mb={5}>
                  <Typography>
                    Wir haben eine E-Mail an {email && email !== "" ? email : "Ihre E-Mail-Adresse"} gesendet. Klicken Sie zum Fortfahren auf den Vertifizierungs-Link in Ihrer E-Mail. 
                  </Typography>
                </Grid>
              </Grid>

              <Button onClick={onNavigateToLogin} variant="text" color="success">
                Zum Login
              </Button>
            </Box>
          </Container>
      </ThemeProvider>
    </Dialog>
  );
};