import Grid from "@mui/material/Grid";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { StyledDatePicker } from "../../common/StyledMuiComponents";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { produce } from "immer";
import dayjs from "dayjs";
import useContent from "../../hooks/useContent";
import 'dayjs/locale/de';

export const DatePicker = () => {
  const content = useContent();
  return <>
    <Grid item xs={12} mt={3} textAlign={"center"}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
        <StyledDatePicker
          label="Lieferdatum"
          value={content?.deliveryDate}
          minDate={content?.minDate}
          slotProps={{
            textField: { 
              size: "small",
              color: "success",
              InputProps: {
                style: {
                  fontSize: "14px",
                  fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                  paddingBottom: "2px"
                }
              }
            },
          }} 
          onChange={(newValue: any) => {
            content?.setDeliveryDate(dayjs(newValue as string));
            content?.setOrder(prev => produce(prev, draft => {
              draft.dateToDeliver = newValue.format('YYYY-MM-DD');
            }));
          }}
        />
      </LocalizationProvider>
    </Grid>
  </>
}