import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Cookies } from "react-cookie-consent";
import axios from "../api/axios";
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import LockIcon from '@mui/icons-material/Lock';

interface Email {
  value: string,
  error: boolean,
  errorMsg: string
}

export const PasswordResetWithEmail = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<Email>({value: "", error: false, errorMsg: ""});

  const handlePasswordReset = async () => {
    setLoading(true);

    const requestData = ({
      Email: email.value
    })

    try {
      const bearerToken = Cookies.get('token');
      await axios.post("/user/resetpassword", requestData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${bearerToken}`
        }
      });

      toast.success("E-Mail wurde versendet", {
        position: toast.POSITION.TOP_RIGHT
      });

      navigate("/login")

    } catch (error: any) {
      toast.error(error.response.data.error || "Bestätigungtoken ist abgelaufen", {
        position: toast.POSITION.TOP_RIGHT
      });
    }

    Cookies.remove('token', { expires: 1 });
    setLoading(false);
  }
  
  return <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LockIcon style={{fontSize: "72px"}}/>
            </Grid>

            <Grid item xs={12}>
              <Typography component="h1" variant="h5">
                Passwort vergessen
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography component="h1" variant="body2">
                Bitte geben Sie Ihre E-Mail-Adresse ein, damit wir Ihnen einen Link zum Zurücksetzen des Passworts schicken können.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField 
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-Mail"
                color="success"
                value={email.value}
                onChange={(e) => {setEmail(prev => ({...prev, value: e.target.value}))}}
                error={email.error}
                helperText={email.error ? email.errorMsg : ""}
                autoComplete="email"
                autoFocus
              />
            </Grid>

            <Grid item xs={12} sm={0}>
              <Button
                onClick={handlePasswordReset}
                fullWidth
                variant="contained"
                color="yellow"
                sx={{ mt: 3, mb: 2 }}
                >
                  { loading ? <CircularProgress color="inherit"/> :  "Link anfordern"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
  </>
}