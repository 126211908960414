import { JwtPayload, jwtDecode } from "jwt-decode";
import { Cookies } from "react-cookie-consent";

interface MyToken extends JwtPayload {
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': string;
  email: string;
  exp: number
}

export const isTokenExpired = () => {
  const bearerToken = Cookies.get('token');
  
  if(bearerToken !== undefined && bearerToken !== null && bearerToken !== "undefined") {
    const decode: MyToken = jwtDecode(bearerToken !== null ? bearerToken : "");
    const isExpired = decode.exp < Date.now() / 1000;
    return isExpired;
  }

  return true;
};