import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cookies } from "react-cookie-consent";
import { toast } from 'react-toastify';
import axios from '../api/axios';
import axiosOriginal from "axios";
import useAuth from './useAuth';

const useLogin = () => {
  const authProps = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLoginSuccess = ({ token, roles }: { token: string, roles: string[] }) => {
    // Bad solution, Should be changed in the future
    roles.length < 1 && roles.push("User");

    authProps?.setAuth({ token, roles });
    navigate("/", { replace: true });
  };

  const loginUser = async (email: string, password: string) => {
    setLoading(true);
    try {
      const response = await axios.post('/auth/Login', { email, password });
      const { token, roles } = response?.data || {};

      // Cookies.set('token', token, { expires: 1 });
      Cookies.set('token', token, { expires: 1, secure: true });

      handleLoginSuccess({ token, roles });

    } catch (error: any) {
      if (error.response) {
        if(axiosOriginal.isAxiosError(error)) {
          // Handle different HTTP error status codes
          switch (error.response.status) {
            case 401:
              // Unauthorized error
              toast.error(error.response.data.error || "Ein Fehler ist aufgetreten", {
                position: toast.POSITION.TOP_RIGHT
              });
              break;
            case 404:
              // Not Found error
              toast.error(error.response.data.error || "404 - Not Found", {
                position: toast.POSITION.TOP_RIGHT
              });
              break;
            // Add more cases for other status codes as needed
            default:
              toast.error(error.response.data.error || "Ein unerwarteter Fehler ist aufgetreten.", {
                position: toast.POSITION.TOP_RIGHT
              });
              break;
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return { loading, loginUser };
};

export default useLogin;