import { useState } from "react";
import { toast } from "react-toastify";
import { produce } from "immer";
import { StyledTextField, TypographyHeading } from "../common/StyledMuiComponents";
import useGetProfile from "../hooks/useGetProfile";
import useValidation from "../hooks/useValidation";
import useAxios from "../hooks/useAxios";
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import FormControlLabel from "@mui/material/FormControlLabel";

export const UserProfile = () => {
  const [newEmail, setNewEmail] = useState("");
  const { profile } = useGetProfile();
  const { error: emailError, errorMsg: emailErrorMsg, validateEmail } = useValidation();
  const { postData: passwordReset } = useAxios();
  const { putData: updateUser, isLoading } = useAxios();
  const { postData } = useAxios();

  const onSuccessChangeEmail = (data: any) => {
    toast.success("E-Mail zum Ändern der E-Mail-Adresse wurde versendet", {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const onSuccessResetPwd = (data: any) => {
    toast.success("E-Mail zum Zurücksetzen des Passworts wurde versendet", {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const onFailure = (error: any) => {}

  const handleChangeEmail = async () => {
    const email = newEmail as string;

    if (!validateEmail(email)) {
      return;
    }

    postData("/user/changeemail", { 'Content-Type': 'application/json' }, { newEmail: newEmail }, true, onSuccessChangeEmail, onFailure);
  }

  const handlePasswordReset = async () => {
    passwordReset("/user/resetpassword", { 'Content-Type': 'application/json' }, {}, true, onSuccessResetPwd, onFailure)
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const email = profile?.account?.email;
    if (!validateEmail(email as string)) {
      return;
    }

    let requestData;
    if(!profile?.account?.company) {
      requestData = ({
        firstName: profile?.account?.firstName,
        lastName: profile?.account?.lastName,
        address: profile?.account?.address,
        city: profile?.account?.city,
        plz: profile?.account?.plz,
        phoneNumber: profile?.account?.phoneNumber,
        isEnabled: true
      });
    } else {
      requestData = ({
        firstName: profile?.account?.firstName,
        lastName: profile?.account?.lastName,
        address: profile?.account?.address,
        city: profile?.account.city,
        plz: profile?.account.plz,
        phoneNumber: profile?.account?.phoneNumber,
        isEnabled: true,
        company: {
          uid: profile?.account?.company?.uid,
          name: profile?.account?.company?.name,
          // gisa: data.get('gisa'),
          // isVerified: true
        }
      });
    }

    const onSuccessUpdateUser = () => {
      toast.success("Benutzer wurde erfolgreich geändert.", {
        position: toast.POSITION.TOP_RIGHT
      });
    }

    updateUser('/User', {'Content-Type': 'application/json'}, requestData, true, onSuccessUpdateUser, () => {});
  };
  
  return <>
    <Container>
      <CssBaseline/>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TypographyHeading>
              Profil
            </TypographyHeading>
          </Grid>
          <Grid item xs={12} sm={12} mb={2}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <StyledTextField 
                margin="normal"
                fullWidth
                name="firstName"
                id="firstName"
                label="Vorname"
                color="success"
                size="small"
                value={profile?.account?.firstName || ""}
                onChange={(e) => profile?.setAccount(prev => ({...prev, firstName: e.target.value}))}
                autoComplete="firstName"
                autoFocus
                /* error={emailError}
                helperText={emailError ? "Ungültiges E-Mail-Format" : ""} */
              />
              <StyledTextField 
                margin="normal"
                fullWidth
                name="lastName"
                id="lastName"
                label="Nachname"
                color="success"
                size="small"
                value={profile?.account?.lastName || ""}
                onChange={(e) => profile?.setAccount(prev => ({...prev, lastName: e.target.value}))}
                autoComplete="lastName"
                /* error={emailError}
                helperText={emailError ? "Ungültiges E-Mail-Format" : ""} */
              />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <StyledTextField 
                    margin="normal"
                    fullWidth
                    name="plz"
                    id="plz"
                    label="PLZ"
                    color="success"
                    size="small"
                    value={profile?.account?.plz || ""}
                    onChange={(e) => profile?.setAccount(prev => ({...prev, plz: parseInt(e.target.value, 10)}))}
                    autoComplete="plz"
                    /* error={passwordError}
                    helperText={passwordError ? passwordErrorMsg : ""} */
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField 
                    margin="normal"
                    fullWidth
                    name="city"
                    id="city"
                    label="Ort"
                    color="success"
                    size="small"
                    value={profile?.account?.city || ""}
                    onChange={(e) => profile?.setAccount(prev => ({...prev, city: e.target.value }))}
                    autoComplete="city"
                    /* error={passwordError}
                    helperText={passwordError ? passwordErrorMsg : ""} */
                  />
                </Grid>
              </Grid>
              <StyledTextField 
                margin="normal"
                fullWidth
                name="address"
                id="address"
                label="Adresse"
                color="success"
                size="small"
                value={profile?.account?.address || ""}
                onChange={(e) => profile?.setAccount(prev => ({...prev, address: e.target.value}))}
                autoComplete="address"
                /* error={passwordError}
                helperText={passwordError ? passwordErrorMsg : ""} */
              />
              <StyledTextField 
                margin="normal"
                fullWidth
                name="phoneNumber"
                id="phoneNumber"
                label="Telefonnummer"
                color="success"
                size="small"
                value={profile?.account?.phoneNumber || ""}
                onChange={(e) => profile?.setAccount(prev => ({...prev, phoneNumber: e.target.value}))}
                autoComplete="phoneNumber"
                /* error={passwordError}
                helperText={passwordError ? passwordErrorMsg : ""} */
              />

              {profile?.account?.company !== undefined && profile?.account?.company !== null ? 
                <>
                  <Typography variant="h6" textAlign="left" marginTop={2} gutterBottom>
                    Firmendaten
                  </Typography>

                  <StyledTextField
                    label="Firmenname"
                    name="companyName"
                    margin="normal"
                    size="small"
                    color="success"
                    disabled
                    fullWidth
                    value={profile?.account?.company?.name !== null ? profile?.account?.company?.name || "" : ""}
                    onChange={(e) => {
                      profile?.setAccount(prev => produce(prev, draft => {
                        if (draft.company !== undefined && draft.company !== null) {
                          draft.company.name = e.target.value;
                        }
                      }))
                    }}
                  />

                  <StyledTextField
                    label="UID-Nummer"
                    name="uid"
                    margin="normal"
                    size="small"
                    color="success"
                    disabled
                    fullWidth
                    value={profile?.account?.company?.uid !== null ? profile?.account?.company?.uid || "" : ""}
                    onChange={(e) => {
                      profile?.setAccount(prev => produce(prev, draft => {
                        if (draft.company !== undefined && draft.company !== null) {
                          draft.company.uid = e.target.value;
                        }
                      }))
                    }}
                  />

                  <StyledTextField
                    label="GISA"
                    name="gisa"
                    margin="normal"
                    fullWidth
                    size="small"
                    color="success"
                    disabled
                    value={profile?.account?.company?.gisa !== null ? profile?.account?.company?.gisa || "" : ""}
                    onChange={(e) => {
                      profile?.setAccount(prev => produce(prev, draft => {
                        if (draft.company !== undefined && draft.company !== null) {
                          draft.company.gisa = parseInt(e.target.value, 10);
                        }
                      }))
                    }}
                  />

                  <FormControlLabel 
                    sx={{ float:"left", width:"100%" }}
                    control={
                      <Checkbox 
                        name="verified"
                        color="success"
                        checked={profile?.account?.company?.isVerified !== null ? profile?.account?.company?.isVerified || false : false}
                        disabled
                      />
                    }
                    label="Unternehmen verifiziert"
                  />
                </>
              : "" }

              <Button
                type="submit"
                variant="contained"
                color="yellow"
                sx={{ mt: 3, mb: 2, float: "left", marginRight: "5px" }}
                >
                  { isLoading ? <CircularProgress size={24} color="inherit"/> :  "Ändern"}
              </Button>

              <Button
                type="button"
                variant="contained"
                color="yellow"
                onClick={handlePasswordReset}
                sx={{ mt: 3, mb: 2, float: "left", marginRight: "5px" }}
                >
                  Passwort zurücksetzen
              </Button>
              
              
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} >
            <Box component="form" sx={{ mt: 1 }}>
              <Grid item xs={12}>
                {/* <Typography component="h1" variant="h6" sx={{float:"left"}}>
                  E-Mail
                </Typography> */}
                <StyledTextField 
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Derzeitige E-Mail"
                  color="success"
                  autoComplete="email"
                  size="small"
                  value={profile?.account?.email || ""}
                  onChange={(e) => profile?.setAccount(prev => ({...prev, email: e.target.value}))}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <StyledTextField 
                  margin="normal"
                  required
                  fullWidth
                  id="newEmail"
                  label="Neue E-Mail"
                  color="success"
                  value={newEmail}
                  size="small"
                  onChange={(e) => setNewEmail(e.target.value)}
                  autoComplete="newEmail"
                  error={emailError}
                  helperText={emailError ? emailErrorMsg : ""}
                />
                <Button
                  type="button"
                  variant="contained"
                  color="yellow"
                  onClick={handleChangeEmail}
                  sx={{ mt: 1, float: "left" }}
                  >
                    E-Mail ändern
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  </>
}