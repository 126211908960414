import axios from 'axios';
import { toast } from 'react-toastify';

const newAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

newAxios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if(error.response) {
    } else if (error.request) {
      toast.error("Server kann nicht erreicht werden", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      toast.error("Ein unerwarteter Fehler ist aufgetreten", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    return Promise.reject(error);
  }
);

export default newAxios;