import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { isTokenExpired } from "./CheckToken";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { UserFlags } from "../interfaces/UserFlags";
import useGetProfile from "../hooks/useGetProfile";

interface MobileNavMenuProps {
  handleOpenCreditRechargeView: () => void
}

export const MobileNavMenu: React.FC<MobileNavMenuProps> = (handleOpenCreditRechargeView) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { profile } = useGetProfile();
  const open = Boolean(anchorEl);
  const authProps = useAuth();
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToHome = () => {
    const tokenExpired = isTokenExpired();
    tokenExpired ? navigate("/login") : navigate("/");
  }

  const handleOnProfile = () => {
    const tokenExpired = isTokenExpired();
    tokenExpired ? navigate("/login") : navigate("/profile/personalData");
  }

  const handleOnOrders = () => {
    const tokenExpired = isTokenExpired();
    tokenExpired ? navigate("/login") : navigate("/orders");
  }

  const handleOnAdmin = () => {
    const tokenExpired = isTokenExpired();
    tokenExpired ? navigate("/login") : navigate("/admin/center");
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={navigateToHome} >
          Buchung
        </MenuItem>

        <MenuItem onClick={handleOnOrders} >
          Track & Trace
        </MenuItem>

        <MenuItem onClick={handleOnProfile} >
          Profil
        </MenuItem>

        {authProps?.auth?.roles === "Admin" ? 
          <MenuItem onClick={handleOnAdmin}>
            Admin
          </MenuItem>
        : "" }

        {(profile?.account?.userFlags !== undefined && !(profile?.account?.userFlags & UserFlags.OsTextil)) && 
          <>
            <MenuItem onClick={() => handleOpenCreditRechargeView}>
              Aktuelles Guthaben: {profile?.account.balance.toFixed(2)} €
            </MenuItem>
            <MenuItem onClick={() => handleOpenCreditRechargeView}>
              Guthaben aufladen
            </MenuItem>
          </>
        }
        
      </Menu>
    </div>
  );
}