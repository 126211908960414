import { TypographyHeading, TypographyPlus, TypographySubheading } from "../common/StyledMuiComponents"
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Order } from "../interfaces/Order";
import { VeloceStatus } from "../interfaces/VeloceStatus";
import { TamburiBox } from "../interfaces/TamburiBox";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import useAxios from "../hooks/useAxios";
import axiosOriginal from "axios";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export enum PaymentStatus {
  Pending = 0,
  Paid = 1,
  PendingPayment = 2,
  Cancelled = 3,
}

interface OrderSum {
  order: Order,
  veloceStatus: VeloceStatus,
  fromRemoteLocationInfo: TamburiBox,
  toRemoteLocationInfo: TamburiBox
}

export const OrderSummary = () => {
  const navigate = useNavigate();
  const id = useParams();
  const [orderSum, setOrderSum] = useState<OrderSum>();
  const { fetchData: getOrderById } = useAxios();

  const onSuccess = (response: any) => {
    response.order && setOrderSum(response);
  }

  const onFailure = (error: any) => {
    if(axiosOriginal.isAxiosError(error)) {
      if (error.response) {
        if(error.response.status === 401) {
          toast.error(error.response.data.error || "Benutzerauthentifizierung erforderlich. Bitte melden Sie sich erneut an.", {
            position: toast.POSITION.TOP_RIGHT
          });
          navigate('/login');
        } else if(error.response.status === 404) {
          toast.error(error.response.data.error || "404 - Not Found", {
            position: toast.POSITION.TOP_RIGHT
          });
        } else {
          toast.error(error.response.data.error || "Ein unerwarteter Fehler ist aufgetreten.", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }
    }
  }

  useEffect(() =>  {
    getOrderById("/Order?orderId=" + id.id, {'Content-Type': 'application/json'}, false, onSuccess, onFailure);
  }, [])

  const NavigateToBookingPage = () => navigate("/")

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          textAlign: "center",
          marginTop: 4
        }}
      >
        <Grid container>
          <Grid item xs={12} textAlign={"left"} sx={{verticalAlign: "center"}}>
            {orderSum?.order?.status === PaymentStatus.Paid &&
              <>
                <TypographyPlus sx={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleOutlineIcon sx={{ marginRight: "8px", color: "#115427" }} />
                  Der Auftrag wurde erfolgreich erstellt und die Zahlung wurde erfolgreich abgeschlossen!
                </TypographyPlus>
              </>
            }
            {orderSum?.order?.status === PaymentStatus.PendingPayment &&
              <>
                <TypographyPlus sx={{ display: "flex", alignItems: "center" }}>
                  <WarningAmberIcon color="warning" sx={{ marginRight: "8px" }} />
                  Der Bezahlvorgang wurde noch nicht abgeschlossen!
                </TypographyPlus>
              </>
            }
            {orderSum?.order?.status === PaymentStatus.Cancelled &&
              <>
                <TypographyPlus sx={{ display: "flex", alignItems: "center" }}>
                  <ErrorOutlineIcon color="error" sx={{ marginRight: "8px" }} />
                  Der Bezahlvorgang wurde abgebrochen!
                </TypographyPlus>
              </>
            }
          </Grid>
          <Grid item xs={12}>
              <TypographyHeading mb={1} mt={2}>
                Zusammenfassung des Auftrags
              </TypographyHeading>
          </Grid>
          <Grid container item>

            <Grid item xs={12} textAlign="left" mt={2}>
              <TypographySubheading>Auftraggeber</TypographySubheading>
            </Grid>

            <Grid item xs={12} textAlign="left">
              <TypographyPlus>{orderSum?.order?.client?.name}</TypographyPlus>
              <TypographyPlus>{orderSum?.order?.client?.email}</TypographyPlus>
              <TypographyPlus>{orderSum?.order?.client?.phoneNumber}</TypographyPlus>
              <TypographyPlus>{orderSum?.order?.client?.plz} {orderSum?.order?.client?.city} {orderSum?.order?.client?.address}</TypographyPlus>
            </Grid>

            <Grid item xs={6} textAlign="left" mt={2}>
              <TypographySubheading>Absender</TypographySubheading><br/>
              <TypographyPlus>{orderSum?.order?.fromName}</TypographyPlus>
              <TypographyPlus>{orderSum?.order?.pickupEmail}</TypographyPlus>
              <TypographyPlus>{orderSum?.order?.fromContactNumber}</TypographyPlus>
              <TypographyPlus>{orderSum?.fromRemoteLocationInfo?.zipCode} {orderSum?.fromRemoteLocationInfo?.street} {orderSum?.fromRemoteLocationInfo?.number}</TypographyPlus>
              <TypographyPlus>Tamburi: {orderSum?.fromRemoteLocationInfo?.locationName}</TypographyPlus>
            </Grid>

            <Grid item xs={6} textAlign="left" mt={2}>
              <TypographySubheading>Empfänger</TypographySubheading><br/>
              <TypographyPlus>{orderSum?.order?.toName}</TypographyPlus>
              <TypographyPlus>{orderSum?.order?.deliveryEmail}</TypographyPlus>
              <TypographyPlus>{orderSum?.order?.toContactNumber}</TypographyPlus>
              <TypographyPlus>{orderSum?.toRemoteLocationInfo?.zipCode} {orderSum?.toRemoteLocationInfo?.street} {orderSum?.toRemoteLocationInfo?.number}</TypographyPlus>
              <TypographyPlus>Tamburi: {orderSum?.toRemoteLocationInfo?.locationName}</TypographyPlus>
            </Grid>

            <Grid item xs={12} textAlign="left" mt={2}>
              <TypographyPlus>Preis: {orderSum?.order?.paidPrice}€</TypographyPlus>
            </Grid>

            <Grid item xs={12} textAlign={"left"}>
              <Button
              onClick={NavigateToBookingPage}
                color="yellow"
                variant="contained"
                sx={{ 
                  mt: 3,
                  mb: 2,
                  textAlign: "center",
                  height: "40px"
                }}
                >
                  Zurück zur Buchungsseite
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}