import React, { useEffect, useState } from "react"
import useAxios from "../hooks/useAxios"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Grid from '@mui/material/Grid';
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Typography from '@mui/material/Typography';

interface Payment {
  id: number,
  transactionId: string,
  paymentId: string,
  amount: number,
  managedUserId: string,
  transactionDate: Date,
  isDone: boolean
}

export const PaymentHistory = () => {
  const [payments, setPayments] = useState<Payment[]>();
  const { fetchData: getPaymentHistory } = useAxios();

  useEffect(() => {
    getPaymentHistory("/payment", { 'Content-Type': 'application/json' }, false, onSuccessGetPaymentHistory, () => {});
  }, [])

  const onSuccessGetPaymentHistory = (response: any) => {
    setPayments(response);
  }

  return <>
    <Container component="main">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography component="h1" variant="h5" sx={{float:"left"}}>
            Guthaben Historie
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} mb={5}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {payments ? (
              payments.map((payment: Payment, index) => {
                const formattedDate = new Date(payment.transactionDate).toISOString().split('T')[0];
                return (
                  <ListItem
                    key={index}
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={() => {}} dense>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography variant="h6" color="text.primary" gutterBottom>
                              {`${payment.amount}€ am ${formattedDate} aufgeladen`}
                            </Typography>
                          </React.Fragment>}
                        secondary={
                          <React.Fragment>
                            <Grid container component="span" spacing={2}>
                              <Grid item component="span" xs={12} sm={4}>
                                <Typography variant="body1" color="text.primary" component="span">
                                  {`Status: ${payment.isDone ? "Abgeschlossen" : "Ausstehend"}`}<br></br>
                                </Typography>
                              </Grid>
                            </Grid>
                          </React.Fragment>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })
            ) : null}
          </List>
        </Grid>
      </Grid>
    </Container>
  </>
}