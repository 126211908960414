import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { produce } from "immer";
import { AutocompleteTextField } from "../../common/StyledMuiComponents";
import useContent from "../../hooks/useContent";

interface StreetData {
  street: string;
  lat: number;
  lon: number;
}

type AutocompleteProps = {
  setGeoData: React.Dispatch<React.SetStateAction<{address: string, lat: number, lon: number}>>,
  addressNames: string[],
  options: StreetData[],
  setOptions: React.Dispatch<React.SetStateAction<StreetData[]>>,
  getAutocompleteOptions: (searchParam: string, plz: number) => void,
  setPlzError: React.Dispatch<React.SetStateAction<boolean>>,
  setPlzErrorMsg: React.Dispatch<React.SetStateAction<string>>,
  idString: string,
  textLabel: string,
  addressType: "to" | "from"
}

export const AutocompleteAddressFields: React.FC<AutocompleteProps> = ({
  setGeoData,
  addressNames,
  options,
  setOptions,
  getAutocompleteOptions,
  setPlzError,
  setPlzErrorMsg,
  idString,
  textLabel,
  addressType
}) => {
  const content = useContent();

  return <>
    <Grid item xs={12}>
      <Autocomplete 
        id={idString}
        options={addressNames}
        isOptionEqualToValue={(option, value) =>
          option === value || value === ""
        }
        loading={false}
        value={content?.order[`${addressType}Address`]}
        noOptionsText="Keine Adressen"
        includeInputInList
        autoComplete
        filterOptions={(x) => x}
        renderInput={(params) => (
          <AutocompleteTextField 
            { ...params }
            label={textLabel}
            color="success"
            size="small"
            fullWidth
            required
            multiline={content?.order.packages[0].pickupType === 0 || content?.order.packages[0].deliveryType === 0}
            rows={2}
          />
        )}
        onChange={(e, value) => {
          if(value){
            const opt: {street: string, lat: number, lon: number}[] = options.filter(x => x.street === value);
            content?.setOrder(prev => produce(prev, draft => {
              draft[`${addressType}Address`] = value;
              addressType === "from" ? draft.packages[0].pickupType = 1 : draft.packages[0].deliveryType = 1
            }));
            setGeoData(prev => produce(prev, draft => {
              if(draft) {
                draft.address = value;
                draft.lat = opt[0].lat;
                draft.lon = opt[0].lon;
              }
            }));
          } else {
            content?.setOrder(prev => produce(prev, draft => {
              draft[`${addressType}Address`] = "";
            }));
          }
        }}
        onInputChange={(e, newInputValue, reason) => {
          if(reason === "clear") {
            // TODO Clear options
          }

          if(reason === "reset") {
            getAutocompleteOptions(newInputValue, content?.order[`${addressType}PLZ`] || 0);
          }

          if (content?.order && content?.order[`${addressType}PLZ`] !== null) {
            getAutocompleteOptions(newInputValue, content?.order[`${addressType}PLZ`] || 0);
          } else {
            setPlzError(true);
            setPlzErrorMsg("PLZ muss eingegeben werden");
          }
        }}
      />
    </Grid>
  </>
}