import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { Client, Order } from "../interfaces/Order";
import { PopupTypesEnum } from "../interfaces/PopupTypes";
import { CreditNeededPopup } from "../popups/CreditNeededPopup";
import { produce} from "immer"
import { debounce } from '@mui/material/utils'
import { usePopup } from "../context/PopupProvider";
import useAxios from "../hooks/useAxios";
import useValidation from "../hooks/useValidation";
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import 'react-toastify/dist/ReactToastify.css';
import 'dayjs/locale/de';
import { isTokenExpired } from "../common/CheckToken";

type LocationState = {
  packageData: Order;
}

export const OrderDetailsView2 = () => {
  const { state } = useLocation() as { state: LocationState };
  const [newPackage, setNewPackage] = useState<Order>(state.packageData);
  const { open, onOpenPopup, onClosePopup } = usePopup();
  const { postDataWithAC } = useAxios();
  const { error: emailError, errorMsg: emailErrorMsg, validateEmail } = useValidation();

  const [clientOptions, setClientOptions] = useState<Client[]>();
  const [clientOptionsName, setClientOptionsName] = useState<string[]>([]);

  // const chooseMapView = () => onOpenPopup(PopupTypesEnum.map);

  // const closeMapView = () => onClosePopup(PopupTypesEnum.map);

  const getClient = useCallback(
    debounce(async (name: string) => {

      if (!name || name === "") {
        return;
      }

      postDataWithAC(`/user/GetByKnownName`, {} , { Name: name, Type: 2 }, true, onSuccessGetClient, () => {})
    }, 500),
    []
  );

  const onSuccessGetClient = (response: any) => {
    const val: Client[] = response;
    setClientOptions(response);

    const name:string[] = [];
    val && val.map((client) => {
      name.push(client.name);
      return null;
    })
    
    setClientOptionsName(name);
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    console.log(newPackage)

    if (!validateEmail(newPackage?.client?.email)) {
      return;
    }

    /* if(newPackage.packageType === null) {
      toast.error("Bitte wählen Sie eine Paketgröße aus!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    } */

    onOpenPopup(PopupTypesEnum.changeOrder);
  }

  return <>
    <Container component="main">
      <CssBaseline />
      <Box 
        component="form"
        onSubmit={handleSubmit}
        sx={{
          marginTop: 8
        }}
      >
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Typography component="h1" variant="h5" sx={{float: "left"}}>
              Auftrag Details &gt; Paket {newPackage.orderId}
            </Typography>
          </Grid>

          <Grid item xs={12} mb={5}>
            <Divider />
          </Grid>

          <Grid container item spacing={2} xs={12} sm={12}>

            <Grid item xs={12}>
              <Typography sx={{float: "left"}}>
                AUFTRAGGEBER
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Autocomplete 
                id="client"
                options={clientOptionsName}
                freeSolo
                isOptionEqualToValue={(option, value) => option === value || value === ""}
                autoHighlight
                autoComplete
                value={newPackage.client.name}
                filterOptions={(x) => x}
                renderInput={(params) => (
                  <TextField 
                    { ...params }
                    label="Name"
                    color="success"
                    fullWidth
                    required
                  />
                )}
                onChange={(event, value) => {
                  if(value){
                    const currentOption = clientOptions?.find((option) => option.name === value);
                    setNewPackage(prev => produce(prev, draft => {
                      draft.client.name = value;
                      draft.client.email = currentOption?.email !== undefined ? currentOption.email : "";
                      draft.client.phoneNumber = currentOption?.phoneNumber !== undefined ? currentOption.phoneNumber : "";
                    }));
                  } else {
                    setNewPackage(prev => produce(prev, draft => {
                      draft.client.name = "";
                    }));
                  }
                }}
                onInputChange={(e, newInputValue) => {
                  setNewPackage(prev => produce(prev, draft => {
                    draft.client.name = newInputValue;
                  }));
                  isTokenExpired() && getClient(newInputValue);
                }}
                renderOption={(props, option) => {
                  const currentOption = clientOptions?.find((opt) => opt.name === option);
                  return (
                    <li {...props}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                        { currentOption?.name }
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" color="text.secondary">{ currentOption?.email }</Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                label="Email"
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                color="success"
                required
                autoFocus
                value={newPackage.client.email || ""}
                error={emailError}
                helperText={emailError ? emailErrorMsg : ""}
                onChange={(e) => {
                  setNewPackage(prev => produce(prev, draft => {
                    draft.client.email = e.target.value;
                  }));
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField 
                label="Telefonnummer"
                fullWidth
                id="phoneNumber"
                name="phoneNumber"
                autoComplete="phoneNumber"
                color="success"
                required
                autoFocus
                value={newPackage.client.phoneNumber || ""}
                onChange={(e) => {
                  setNewPackage(prev => produce(prev, draft => {
                    draft.client.phoneNumber = e.target.value;
                  }));
                }}
              />
            </Grid>
          </Grid>

          {/* <Grid item xs={12} mt={3}>
            <Typography sx={{float: "left"}}>
              PAKETE
            </Typography>
          </Grid>

          <Grid item xs={12} mt={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Pakete</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={packageDropdownNumber}
                label="Pakete"
                onChange={(e) => { setPackageDropdownNumber(e.target.value as number)}}
              >
                { newPackage && newPackage.packages.map((pac: PackageInfo, index: number) => {
                  return (
                    <MenuItem key={index} value={index + 1}>{pac.packageId}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid> */}

          <Grid item xs={12} sm={12} textAlign={"start"}>
            <Button
              type="submit"
              color="success"
              variant="contained"
              sx={{ 
                mt: 3,
                mb: 2,
                width: 200,
                height: 50,
                textAlign: "right"
              }}
              >
                Weiter
            </Button>
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={3} alignItems="center" mt={2} textAlign="start" sx={{verticalAlign:"end"}}>
          <Typography component="h1" variant="body2" >
            {"*Abholung von 15 bis 18 Uhr"}
          </Typography>
          <Typography component="h1" variant="body2" sx={{marginLeft:"6px"}}>
            {"Zustellung bis 21 Uhr"}
          </Typography>
        </Grid>

        <CreditNeededPopup 
          open={open.creditNeeded}
          onClose={() => onClosePopup(PopupTypesEnum.creditNeeded)}
        />
      </Box>
    </Container>
  </>
}