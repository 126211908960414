import { useState, createContext, ReactNode } from "react";
import { Order } from "../interfaces/Order";
import { EmtyOrder } from "../main/NewPackageEmty";
import { TamburiBox as TamburiBoxInterface } from "../interfaces/TamburiBox";
import dayjs, { Dayjs } from "dayjs";

interface ContentContextProps {
  order: Order;
  setOrder: React.Dispatch<React.SetStateAction<Order>>;
  selectedToBox: TamburiBoxInterface | undefined;
  setSelectedToBox: React.Dispatch<React.SetStateAction<TamburiBoxInterface | undefined>>;
  selectedFromBox: TamburiBoxInterface | undefined;
  setSelectedFromBox: React.Dispatch<React.SetStateAction<TamburiBoxInterface | undefined>>;
  selectedOrderType: number | null;
  setSelectedOrderType: React.Dispatch<React.SetStateAction<number | null>>;
  selectedTimeframe: number | null;
  setSelectedTimeframe: React.Dispatch<React.SetStateAction<number | null>>;
  deliveryDate: Dayjs;
  setDeliveryDate: React.Dispatch<React.SetStateAction<Dayjs>>;
  minDate: Dayjs;
  setMinDate: React.Dispatch<React.SetStateAction<Dayjs>>;
  isFromAddress: boolean;
  setIsFromAddress: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ContentProviderProps {
  children: ReactNode;
}

const ContentContext = createContext<ContentContextProps | null>(null);

export const ContentProvider: React.FC<ContentProviderProps> = ({children}) => {
  const [order, setOrder] = useState<Order>(EmtyOrder);
  const [selectedToBox, setSelectedToBox] = useState<TamburiBoxInterface | undefined>();
  const [selectedFromBox, setSelectedFromBox] = useState<TamburiBoxInterface | undefined>();
  const [selectedOrderType, setSelectedOrderType] = useState<number | null>(null);
  const [selectedTimeframe, setSelectedTimeframe] = useState<number | null>(null);
  const [deliveryDate, setDeliveryDate] = useState<Dayjs>(dayjs());
  const [minDate, setMinDate] = useState<Dayjs>(dayjs());
  const [isFromAddress, setIsFromAddress] = useState<boolean>(true);

  return <ContentContext.Provider value={{ 
    order,
    setOrder,
    selectedToBox,
    setSelectedToBox,
    selectedFromBox,
    setSelectedFromBox,
    selectedOrderType,
    setSelectedOrderType,
    selectedTimeframe,
    setSelectedTimeframe,
    deliveryDate,
    setDeliveryDate,
    minDate,
    setMinDate,
    isFromAddress,
    setIsFromAddress
  }}>
          {children}
      </ContentContext.Provider>;
}

export default ContentContext;