import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { produce } from "immer";
import { AutocompleteTextField } from "../../common/StyledMuiComponents";
import { useGetAddressOptions } from "../../hooks/useGetAddressOptions";
import useContent from "../../hooks/useContent";

type AutocompleteProps = {
  setPlzError: React.Dispatch<React.SetStateAction<boolean>>,
  setPlzErrorMsg: React.Dispatch<React.SetStateAction<string>>,
  idString: string,
  textLabel: string,
}

export const AutocompleteClientAddressField: React.FC<AutocompleteProps> = ({
  setPlzError,
  setPlzErrorMsg,
  idString,
  textLabel,
}) => {
  const {getAutocompleteOptions, addressNames} = useGetAddressOptions();
  const content = useContent();

  return <>
    <Grid item xs={12}>
      <Autocomplete 
        id={idString}
        options={addressNames}
        isOptionEqualToValue={(option, value) =>
          option === value || value === ""
        }
        loading={false}
        value={content?.order?.client?.address}
        noOptionsText="Keine Adressen"
        includeInputInList
        autoComplete
        filterOptions={(x) => x}
        renderInput={(params) => (
          <AutocompleteTextField 
            { ...params }
            label={textLabel}
            color="success"
            size="small"
            fullWidth
            required
          />
        )}
        onChange={(e, value) => {
          if(value){
            content?.setOrder(prev => produce(prev, draft => {
              draft.client.address = value;
            }));
          } else {
            content?.setOrder(prev => produce(prev, draft => {
              draft.client.address = "";
            }));
          }
        }}
        onInputChange={(e, newInputValue) => {
          if (content?.order && content?.order?.client?.plz !== null && content?.order?.client?.plz > 0) {
            getAutocompleteOptions(newInputValue, content?.order?.client?.plz || 0);
          } else {
            setPlzError(true);
            setPlzErrorMsg("PLZ muss eingegeben werden");
          }
        }}
      />
    </Grid>
  </>
}