import { useState } from "react";
import { Map } from "./Map";
import { TamburiBox } from "../interfaces/TamburiBox";
import { ThemeProvider, styled, useTheme } from '@mui/material/styles';
import { produce } from "immer";
import { LogoutButton } from "../common/StyledMuiComponents";
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useContent from "../hooks/useContent";
import dayjs from "dayjs";

interface MapViewProps {
  open: boolean,
  streetData: {address: string, lat: number, lon: number},
  onClose: () => void,
}

interface SelectedMarker {
  id: number,
  box: TamburiBox
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const MapView: React.FC<MapViewProps> = ({open, onClose, streetData}) => {
  const [selectedMarker, setSelectedMarker] = useState<SelectedMarker | null>(null);
  const content = useContent();
  const address = content?.isFromAddress ? content?.order.fromAddress : content?.order.toAddress;
  const city = content?.isFromAddress ? content?.order.fromCity : content?.order.toCity;
  const theme = useTheme();

  // console.log(streetData)

  const saveSelectedMarker = () => {
    content?.isFromAddress ? handleGetSelectedFromBox(selectedMarker?.box) : handleGetSelectedToBox(selectedMarker?.box);
    onClose();
  }

  const handleGetSelectedFromBox = (box: TamburiBox | undefined) => {
    if(box && content){
      content.setSelectedFromBox(box);
      content.setDeliveryDate(dayjs());
      content.setMinDate(dayjs());
      content.setSelectedTimeframe(null);
      content.setOrder(prev => produce(prev, draft => {
        draft.packages[0].pickupType = 0; // Tamburi
        draft.fromLocationId = box.locationId;
        draft.fromAddress = `${box.street} ${box.number}`;
        draft.fromPLZ = parseInt(box.zipCode, 10);
        draft.fromCity = box.cityName;
        draft.fromAddressSupplement = "";
        draft.fromPickupTimeFrame = null;
        draft.toPickupTimeFrame = null;
        draft.dateToDeliver = dayjs().format('YYYY-MM-DD');
      }))
    }
  }

  const handleGetSelectedToBox = (box: TamburiBox | undefined) => {
    if(box && content){
      content.setSelectedToBox(box);
      content.setDeliveryDate(dayjs());
      content.setMinDate(dayjs());
      content.setSelectedTimeframe(null);
      content.setOrder(prev => produce(prev, draft => {
        draft.packages[0].deliveryType = 0; // Tamburi
        draft.toLocationId = box.locationId;
        draft.toAddress = `${box.street} ${box.number}`;
        draft.toPLZ = parseInt(box.zipCode, 10);
        draft.toCity = box.cityName;
        draft.toAddressSupplement = "";
        draft.fromPickupTimeFrame = null;
        draft.toPickupTimeFrame = null;
        draft.dateToDeliver = dayjs().format('YYYY-MM-DD');
      }))
    }
  }

  return <>
    <ThemeProvider theme={theme}>
      <BootstrapDialog open={open} maxWidth="lg" fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Wähle einen Paketkasten aus
        </DialogTitle>
        <LogoutButton 
          disabled={selectedMarker ? false : true}
          variant="contained"
          disableElevation
          onClick={saveSelectedMarker}
          color="yellow"
          sx={{
            position: 'absolute',
            right: 50,
            top: 12
          }}
          >
            Kasten bestätigen
          </LogoutButton>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {/* <SimpleMap address={address} city={city} streetData={streetData} setMarker={(marker) => { setSelectedMarker(marker) }}/> */}
          <Map address={address || ""} city={city || ""} streetData={streetData} setMarker={(marker) => { setSelectedMarker(marker) }}/>
        </DialogContent>
      </BootstrapDialog>
    </ThemeProvider>
  </>
}