import Grid from "@mui/material/Grid";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { StyledDatePicker } from "../../common/StyledMuiComponents";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from "dayjs";
import 'dayjs/locale/de';

interface DatePickerProps {
  fromTime: Dayjs | null,
  setFromTime: React.Dispatch<React.SetStateAction<Dayjs | null>>,
  toTime: Dayjs | null,
  setToTime: React.Dispatch<React.SetStateAction<Dayjs | null>>,
}

export const DateSpanPicker: React.FC<DatePickerProps> = ({fromTime, toTime, setFromTime, setToTime}) => {
  return <>
    <Grid container alignItems="center">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
        <span style={{marginRight: "10px"}}>Buchungszeitraum von</span>
        <StyledDatePicker
          label="Datum"
          value={fromTime}
          slotProps={{
            textField: { 
              size: "small",
              color: "success",
              InputProps: {
                style: {
                  fontSize: "14px",
                  fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                  paddingBottom: "2px"
                }
              }
            },
          }} 
          onChange={(value: any) => {
            setFromTime(dayjs(value as string) || null)
          }}
        />
        <span style={{marginLeft: "10px", marginRight: "10px"}}>bis</span>
        <StyledDatePicker 
          label="Datum"
          value={toTime}
          slotProps={{
            textField: { 
              size: "small",
              color: "success",
              InputProps: {
                style: {
                  fontSize: "14px",
                  fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
                  paddingBottom: "2px"
                }
              }
            },
          }} 
          onChange={(value: any) => {
            setToTime(dayjs(value as string) || null)
          }}
        />
      </LocalizationProvider>
    </Grid>
  </>
}