import { useState } from 'react';
import { Button, Container, Grid, Typography, ThemeProvider, CssBaseline, Box, CircularProgress, InputAdornment, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { StyledTextField } from '../common/StyledMuiComponents';
import useLogin from '../hooks/useLogin';

export const Login = () => {
  // const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { loading, loginUser } = useLogin();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get('email') as string;
    const password = data.get('password') as string;

    loginUser(email, password);
  };

  const globalTheme = useTheme();
  return <>
    <ThemeProvider theme={globalTheme}>
      <CssBaseline/>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <img style={{maxWidth:"150px", marginBottom:"20px"}} src="veloce_liefert.svg" alt="veloce logo"></img>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <StyledTextField 
              margin="normal"
              required
              fullWidth
              name="email"
              id="email"
              label="Email"
              autoComplete="email"
              autoFocus
              color="success"
              size="small"
            />
            <StyledTextField 
              margin="normal"
              required
              fullWidth
              name="password"
              id="password"
              label="Passwort"
              autoComplete="current-password"
              color="success"
              size="small"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: 
                <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>,
              }}
            />
            {/* <FormControlLabel 
              sx={{ float:"left" }}
              control={<Checkbox name="rememberMe" color="primary" onChange={(e) => setRememberMe(e.target.checked)}/>}
              label="Angemeldet bleiben"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="yellow"
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
              >
                { loading ? <CircularProgress size={32} color="inherit"/> :  "Anmelden"}
            </Button>
            <Grid container>
              <Grid item>
                <Link to="/forgotpassword" replace={true} style={{textDecoration: "none", color: "#36454F"}}>
                  Passwort vergessen?
                </Link>
              </Grid>
              <Grid item mt={2}>
                <Link to="/register" replace={true} style={{textDecoration: "none", color: "#36454F"}}>
                  {"Du hast noch kein Konto? Registriere dich hier"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  </>
}