import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { User } from "../interfaces/User";
import { StyledTextField, TypographyHeading, TypographySubheading } from "../common/StyledMuiComponents";
import { produce } from "immer";
import { UserFlags } from "../interfaces/UserFlags";
import { toast } from "react-toastify";
import useAxios from "../hooks/useAxios";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from "@mui/material/Divider";
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

interface LocationState {
  userId: string;
}

export const EditUser = () => {
  const [user, setUser] = useState<User>();
  const { state } = useLocation() as { state: LocationState };
  const { putData, isLoading } = useAxios();
  const { fetchData } = useAxios();

  useEffect(() => {
    getUser();
  }, [])

  const getUser = () => {
    fetchData("/admin/user/?userId=" + state.userId, {}, true, onSuccess, () => {})
  }


  const onSuccess = (response: any) => {
    setUser(response);
  }

  /* const createRole = () => {
    postData("/admin/role?name=" + role, {}, {}, true, () => {}, () => {})
  } */

  /* const getRoles = () => {
    fetchData("/admin/roles", {}, true, getRolesSuccess, () => {})
  } */

  /* const getRolesSuccess = (response: any) => {
    console.log("GetRoles Response: " + response)
  } */
  
  /* const onConfirmRoles = (selectedRoles: string[]) => {
    setUsers(prevUsers => {
      // Find the index of the user with the specified userId
      const userIndex = prevUsers.findIndex((user, index) => index === selectedUser);

      // If the user is found, create a new array with the updated user
      if (userIndex !== -1) {
        const updatedUser = { ...prevUsers[userIndex], roles: selectedRoles };
        const updatedUsers = [...prevUsers];
        updatedUsers[userIndex] = updatedUser;
        return updatedUsers;
      }
      return prevUsers;
    });

    onClosePopup(PopupTypesEnum.roleSelection)
  } */

  /* const onDeleteRole = (index: number) => {
    setUsers(prevUsers => {
      // Find the index of the user with the specified userId
      const userIndex = prevUsers.findIndex((user, index) => index === selectedUser);

      // If the user is found, create a new array with the updated user
      if (userIndex !== -1) {

        const updateRoles = prevUsers[userIndex].roles;
        updateRoles.splice(index, 1);

        const updatedUser = { ...prevUsers[userIndex], roles: updateRoles };
        const updatedUsers = [...prevUsers];
        updatedUsers[userIndex] = updatedUser;
        return updatedUsers;
      }
      return prevUsers;
    });
  } */

  const updateUser = () => {
    if(user !== null) {
      putData(
        "/admin/user", { 'Content-Type': 'application/json' },
        user,
        true,
        onSuccessUpdateUser,
        () => {}
      )
    }
  }

  const onSuccessUpdateUser = () => {
    getUser();
    toast.success("Der Benutzer wurde erfolgreich geändert.", {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  return <>
    <Container component="main">
      <CssBaseline />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TypographyHeading>
              Admin Center
            </TypographyHeading>
          </Grid>
          <Grid item xs={12} sm={12} mb={3}>
            <Divider />
          </Grid>
        </Grid>
        
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Vorname"
                  name="firstName"
                  size="small"
                  color="success"
                  fullWidth
                  value={user?.firstName !== null ? user?.firstName || "" : ""}
                  onChange={(e) => {
                    setUser(prev => produce(prev, draft => {
                      if(draft !== undefined)
                        draft.firstName = e.target.value;
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Nachname"
                  name="lastName"
                  size="small"
                  color="success"
                  fullWidth
                  value={user?.lastName !== null ? user?.lastName || "" : ""}
                  onChange={(e) => {
                    setUser(prev => produce(prev, draft => {
                      if(draft !== undefined)
                        draft.lastName = e.target.value;
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="PLZ"
                  name="plz"
                  size="small"
                  color="success"
                  fullWidth
                  value={user?.plz !== null ? user?.plz || "" : ""}
                  onChange={(e) => {
                    setUser(prev => produce(prev, draft => {
                      if(draft !== undefined)
                        draft.plz = parseInt(e.target.value, 10);
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Ort"
                  name="city"
                  size="small"
                  color="success"
                  fullWidth
                  value={user?.city !== null ? user?.city || "" : ""}
                  onChange={(e) => {
                    setUser(prev => produce(prev, draft => {
                      if(draft !== undefined)
                        draft.city = e.target.value;
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Adresse"
                  name="address"
                  size="small"
                  color="success"
                  fullWidth
                  value={user?.address !== null ? user?.address || "" : ""}
                  onChange={(e) => {
                    setUser(prev => produce(prev, draft => {
                      if(draft !== undefined)
                        draft.address = e.target.value;
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Telefonnummer"
                  name="phoneNumber"
                  size="small"
                  color="success"
                  fullWidth
                  value={user?.phoneNumber !== null ? user?.phoneNumber || "" : ""}
                  onChange={(e) => {
                    setUser(prev => produce(prev, draft => {
                      if(draft !== undefined)
                        draft.phoneNumber = e.target.value;
                    }));
                  }}
                />
              </Grid>

              {/* <Grid item xs={12} sm={12}>
                <FormControlLabel 
                  sx={{ float:"left" }}
                  control={
                    <Checkbox 
                      name="active"
                      color="success"
                      value={user?.isEnabled !== null ? user?.isEnabled || "" : ""}
                      onChange={(e) => {}}
                    />
                  }
                  label="Konto Aktiviert"
                />
              </Grid> */}

              { user?.company !== null ? 
                <>
                  <Grid item xs={12} sm={12}>
                    <TypographySubheading>
                      Firmendaten
                    </TypographySubheading>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <StyledTextField
                      label="Firmenname"
                      name="companyName"
                      size="small"
                      color="success"
                      fullWidth
                      value={user?.company?.name !== null ? user?.company?.name || "" : ""}
                      onChange={(e) => {
                        setUser(prev => produce(prev, draft => {
                          if (draft !== undefined && draft.company !== null) {
                            draft.company.name = e.target.value;
                          }
                        }));
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <StyledTextField
                      label="UID-Nummer"
                      name="uid"
                      size="small"
                      color="success"
                      fullWidth
                      value={user?.company?.uid !== null ? user?.company?.uid || "" : ""}
                      onChange={(e) => {
                        setUser(prev => produce(prev, draft => {
                          if (draft !== undefined && draft.company !== null) {
                            draft.company.uid = e.target.value;
                          }
                        }));
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <StyledTextField
                      label="GISA"
                      name="gisa"
                      size="small"
                      color="success"
                      fullWidth
                      value={user?.company?.gisa !== null ? user?.company?.gisa || "" : ""}
                      onChange={(e) => {
                        setUser(prev => produce(prev, draft => {
                          if (draft !== undefined && draft.company !== null) {
                            draft.company.gisa = parseInt(e.target.value, 10);
                          }
                        }));
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <FormControlLabel 
                      sx={{ float:"left" }}
                      control={
                        <Checkbox 
                          name="verified"
                          color="success"
                          checked={user?.company?.isVerified !== null ? user?.company?.isVerified || false : false}
                          onChange={(e) => {
                            if(e.target.checked) {
                              setUser(prev => produce(prev, draft => {
                                if (draft !== undefined && draft.company !== null) {
                                  draft.company.isVerified = true;
                                }
                              }))
                            } else {
                              setUser(prev => produce(prev, draft => {
                                if (draft !== undefined && draft.company !== null) {
                                  draft.company.isVerified = false;
                                }
                              }))
                            }
                          }}
                        />
                      }
                      label="Unternehmen verifiziert"
                    />
                  </Grid> 
                </> : ""
              }

              <Grid item xs={12} sm={12}>
                <FormControlLabel 
                  sx={{ float:"left" }}
                  control={
                    <Checkbox 
                      name="osFlag"
                      color="success"
                      checked={user?.userFlags === UserFlags.OsTextil ? true : false}
                      onChange={(e) => {
                        if(e.target.checked) {
                          setUser(prev => produce(prev, draft => {
                            if(draft !== undefined)
                              draft.userFlags |= UserFlags.OsTextil;
                          }))
                        } else {
                          setUser(prev => produce(prev, draft => {
                            if(draft !== undefined)
                              draft.userFlags &= ~UserFlags.OsTextil;
                          }))
                        }
                      }}
                    />
                  }
                  label="OS Textil"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Stack spacing={1} alignItems="left">
                  <Stack direction="row" spacing={1}>
                    { user !== null ? user?.roles?.map((name, id) => {
                      return (
                        <Chip
                          key={id}
                          label={name} 
                          color={name === "Admin" ? "error" : "success"}
                          // onDelete={() => onDeleteRole(id)}
                        />
                      )
                    })  : ""}
                    {/* <Chip 
                      label="Rolle"
                      color="success"
                      deleteIcon={<AddCircleIcon />}
                      onDelete={() => {selectedUser !== null && onOpenPopup(PopupTypesEnum.roleSelection)}}
                    /> */}
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Button
                  variant="contained"
                  color="yellow"
                  disabled={isLoading}
                  sx={{minWidth: "150px", float: "left"}}
                  onClick={updateUser}
                >
                  { isLoading ? <CircularProgress size={32} color="inherit"/> :  "Änderungen speichern"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
      </Box>

      {/* <RoleSelectionPopup
        open={open.roleSelection}
        selectedUserId={selectedUser}
        currentUser={selectedUser !== null ? users[selectedUser] : null}
        onClose={() => {onClosePopup(PopupTypesEnum.roleSelection)}}
        onConfirmRoles={onConfirmRoles}
      /> */}

    </Container>
  </>
}