import { useEffect } from "react"
import { isTokenExpired } from "./CheckToken"
import { Outlet, useNavigate } from "react-router-dom"
import { CreditRechargePopup } from "../popups/CreditRechargePopup"
import { usePopup } from "../context/PopupProvider"
import { PopupTypesEnum } from "../interfaces/PopupTypes";
import { CreditButton, LogoutButton, NavButtonGroup, StyledAppBar } from "./StyledMuiComponents"
import { Cookies } from "react-cookie-consent";
import useGetProfile from "../hooks/useGetProfile"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import CssBaseline from "@mui/material/CssBaseline"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import useMediaQuery from "@mui/material/useMediaQuery"
import LogoutIcon from '@mui/icons-material/Logout';
import useAuth from "../hooks/useAuth"
import CircularProgress from "@mui/material/CircularProgress"
import { ThemeProvider, useTheme } from "@mui/material/styles"
import { UserFlags } from "../interfaces/UserFlags"
import { MobileNavMenu } from "./MobileNavMenu"

export const NavLayoutAuth = () => {
  const { onOpenPopup, onClosePopup, open } = usePopup();
  const { profile, getProfile, loading } = useGetProfile();
  const authProps = useAuth();
  const navigate = useNavigate();
  const maxWidth = profile?.account?.userFlags !== undefined && !(profile?.account?.userFlags & UserFlags.OsTextil) ? '(max-width:850px)' : '(max-width:520px)';
  const isMobile = useMediaQuery(maxWidth);
  var imageBasePath =
    window.location.protocol + "//" + window.location.host + "/";

  useEffect(() => {
    getProfile();
  },[])

  const globalTheme = useTheme();

  const navigateToHome = () => {
    const tokenExpired = isTokenExpired();
    tokenExpired ? navigate("/login") : navigate("/");
  }

  const Footer = () => {
    return (
      <footer style={{marginTop:"20px", backgroundColor: `${profile?.colorMode === "light" ? "#fff" : "#1E1E1E"}`}}>
        <ButtonGroup>
            <Button onClick={navigateToImprint} variant="text" color="success">
              Impressum
            </Button>
            <Button onClick={navigateToAgb} variant="text" sx={{ my: 1, mx: 1.5 }} color="success">
              AGB
            </Button>
          </ButtonGroup>
      </footer>
    );
  };

  const navigateToImprint = () => {
    navigate("/imprint");
  }

  const navigateToAgb = () => {
    navigate("/agb");
  }

  const handleOpenCreditRechargeView = () => onOpenPopup(PopupTypesEnum.creditRecharge);

  const handleCloseCreditRechargeView = () => onClosePopup(PopupTypesEnum.creditRecharge);

  const handleLogout = () => {
    Cookies.remove('token', { expires: 1 });
    localStorage.removeItem('colorMode');
    navigate("/login");
  }

  const handleOnProfile = () => {
    const tokenExpired = isTokenExpired();
    tokenExpired ? navigate("/login") : navigate("/profile/personalData");
  }

  const handleOnOrders = () => {
    const tokenExpired = isTokenExpired();
    tokenExpired ? navigate("/login") : navigate("/orders");
  }

  const handleOnAdmin = () => {
    const tokenExpired = isTokenExpired();
    tokenExpired ? navigate("/login") : navigate("/admin/center");
  }

  return <>
    <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <StyledAppBar
        position="static"
        color="inherit"
        elevation={0}
      >
        <CssBaseline/>
        <Toolbar sx={{ flexWrap: 'wrap', justifyContent: "center" }}>
          <img 
            style={{maxWidth:"100px", marginBottom:"20px", margin: "5px", cursor: "pointer"}}
            alt="veloce-logo"
            src={imageBasePath + "veloce_liefert.svg"}
            onClick={navigateToHome}
          />
          
          <NavButtonGroup size="small" sx={{alignItems: "center"}}>
            {isMobile && <MobileNavMenu handleOpenCreditRechargeView={handleOpenCreditRechargeView}/>}
            {!isMobile &&
              <>
                <Button onClick={navigateToHome} variant="text" sx={{ my: 1, mx: 1.5 }} color="success">
                  Buchung
                </Button>
                <Button onClick={handleOnOrders} variant="text" sx={{ my: 1, mx: 1.5 }} color="success">
                  Track & Trace
                </Button>
                <Button onClick={handleOnProfile} variant="text" sx={{ my: 1, mx: 1.5 }} color="success">
                  Profil
                </Button>
                {authProps?.auth?.roles === "Admin" ? 
                  <Button onClick={handleOnAdmin} variant="text" sx={{ my: 1, mx: 1.5 }} color="success">
                    Admin
                  </Button>
                : "" }

                {(!loading && profile?.account?.userFlags !== undefined && !(profile?.account?.userFlags & UserFlags.OsTextil)) && 
                  <>
                    <ThemeProvider theme={globalTheme}>
                      <CreditButton onClick={handleOpenCreditRechargeView} variant="text" sx={{ my: 1, mx: 1.5}} disabled>
                        Aktuelles Guthaben: {profile?.account.balance.toFixed(2)} €
                      </CreditButton>
                    </ThemeProvider>
                    <Button onClick={handleOpenCreditRechargeView} variant="text" sx={{ my: 1, mx: 1.5 }} color="success">
                      Guthaben aufladen
                    </Button>
                  </>
                }
              </>
            }



            {/* <AccountMenu /> */}
            <IconButton sx={{ mr: 1.5 }} onClick={() => { 
                profile?.setColorMode(profile?.colorMode === "light" ? "dark" : "light")
                localStorage.setItem('colorMode', profile?.colorMode === "light" ? "dark" : "light");
              }} 
              >
              {profile?.colorMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            <LogoutButton onClick={handleLogout} variant="contained" sx={{ my: 1, mx: 1.5 }} color="yellow" disableElevation>
              {isMobile ? <LogoutIcon /> : 'AUSLOGGEN'}
            </LogoutButton>
          </NavButtonGroup>
        </Toolbar>
      </StyledAppBar>
      
      {loading ? (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
          <CircularProgress color="success"/>
        </div>
      ) :
        <Outlet/>
      }

      <CreditRechargePopup
        open={open.creditRecharge}
        onClose={handleCloseCreditRechargeView}
      />
      <div style={{ flex: 1 }} />
      <Footer />
    </div>
  </>
}