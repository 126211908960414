import { Route, Routes } from 'react-router-dom';
import { Login } from './components/auth/Login';
import { Register } from './components/auth/Register';
import { PersistLogin } from './components/auth/PersistLogin';
import { EmailVerification } from './components/auth/EmailVerification';
import { ToastContainer } from 'react-toastify';
import { UserProfile } from './components/profile/UserProfile';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorHandler } from './components/common/ErrorHandler';
import { PasswordReset } from './components/profile/PasswordReset';
import { PasswordResetWithEmail } from './components/profile/PasswordResetWithEmail';
import { PaymentVerify } from './components/profile/PaymentVerify';
import { NavLayout } from './components/common/NavLayout';
import { Imprint } from './components/Imprint';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { AdminCenter } from './components/admin/AdminCenter';
import { UserProfileNav } from './components/profile/UserProfileNav';
import { SavedBoxes } from './components/profile/SavedBoxes';
import { SavedStores } from './components/profile/SavedStores';
import { CookieBanner } from './components/common/CookieBanner';
import { TrackAndTrace } from './components/profile/TrackAndTrace';
import { PaymentHistory } from './components/profile/PaymentHistory';
import { ChangeEmail } from './components/profile/ChangeEmail';
import { OrderDetailsView2 } from './components/profile/OrderDetailsView2';
import { AdminNav } from './components/admin/AdminNav';
import { EditUser } from './components/admin/EditUser';
import { BookingLayout } from './components/main/BookingLayout';
import { VerifyCompanyTable } from './components/admin/VerifyCompany';
import { OrderSummary } from './components/main/OrderSummary';
import { Agb } from './components/AGB';
import { EmailTemplates } from './components/admin/EmailTemplates';
import { EditEmailTemplate } from './components/admin/EditEmailTemplate';
import ErrorPage from './components/common/ErrorPage';
import Layout from './components/common/Layout';
import ScrollToTop from './components/hooks/ScrollToTop';
import OrderViewTable from './components/profile/OrderViewTable';
import RequireAuth from './components/auth/RequireAuth';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <div className="App">
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Layout/>}>
            { /* public routes */}
            <Route path="login" element={<Login/>}/>
            <Route path="register" element={<Register/>}/>
            <Route path="verify" element={<EmailVerification/>}/>
            <Route path="payment/verify" element={<PaymentVerify/>}/>
            <Route path="reset" element={<PasswordReset/>}/>
            <Route path="forgotpassword" element={<PasswordResetWithEmail/>}/>
            <Route path="changeemail" element={<ChangeEmail/>}/>

            <Route path="/" element={<NavLayout/>}>
              <Route path="order/:id" element={<OrderSummary/>}/>
              <Route path="imprint" element={<Imprint/>}/>
              <Route path="agb" element={<Agb/>}/>
              <Route path="privacyPolicy" element={<PrivacyPolicy/>}/>

              { /* protected routes */}
              <Route element={<PersistLogin/>}>
                <Route path="/" element={<BookingLayout/>}/>
                <Route element={<RequireAuth allowedRoles={["Admin"]}/>}>
                  <Route path="admin" element={<AdminNav/>}>
                    <Route path="center" element={<AdminCenter/>}/>
                    <Route path="verify" element={<VerifyCompanyTable/>}/>
                    <Route path="edit" element={<EditUser/>}/>
                    <Route path="emailtemplates" element={<EmailTemplates/>}/>
                    <Route path="emailtemplates/edit" element={<EditEmailTemplate/>}/>
                  </Route>
                </Route>
                <Route element={<RequireAuth allowedRoles={["Admin", "User"]}/>}>
                  <Route path="profile" element={<UserProfileNav/>}>
                    <Route path="personalData" element={<UserProfile/>}/>
                    <Route path="savedBoxes" element={<SavedBoxes/>}/>
                    <Route path="savedStores" element={<SavedStores/>}/>
                    <Route path="paymentHistory" element={<PaymentHistory/>}/>
                  </Route>
                  <Route path="orders" element={<OrderViewTable/>}/>
                  <Route path="orders/:id"  element={<OrderDetailsView2/>}/>
                  <Route path="trackAndTrace"  element={<TrackAndTrace/>}/>
                </Route>
              </Route>
            </Route>

            {/* catch all */}
            <Route path="*" element={<ErrorPage/>}/>
          </Route>
        </Routes>
        <CookieBanner />
        <ToastContainer />
      </div>
    </ErrorBoundary>
  );
}

export default App;
