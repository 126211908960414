import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { TypographyHeading } from "../common/StyledMuiComponents"
import { VeloceStatus } from "../interfaces/VeloceStatus"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import useAxios from "../hooks/useAxios"

export const TrackAndTrace = () => {
  const [ orderStatus, setOrderStatus ] = useState<VeloceStatus>();
  const { fetchData } = useAxios();
  const { state } = useLocation() as { state: {orderId: number}}

  useEffect(() => {
    fetchData("/Order?orderId=" + state.orderId, {'Content-Type': 'application/json'}, true, onSuccess, () => {});
  }, [])

  const onSuccess = (result: any) => {
    setOrderStatus(result.veloceStatus)
  }

  return <>
    <Container>
      <CssBaseline/>
      <Box
        sx={{
          marginTop: 8
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <TypographyHeading>
              Track & Trace
            </TypographyHeading>
          </Grid>
          <Grid item xs={12} sm={12} mb={3}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} mb={5} textAlign="left">
            <Typography>
              Auftragsnummer: {orderStatus?.orderNumber}
            </Typography>
            <Typography>
              Aktiver Status: {orderStatus?.deliveryStatus}
            </Typography>
            <br/>

            {orderStatus?.statusEvents?.map((status, index) => (
              <div key={index}>
                <Typography>
                  {status.eventDate} {status.eventTime} - {status.orderState}
                </Typography>
              </div>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Container>
  </>
}