import React, { useEffect, useState } from 'react';
import { GridColDef, deDE } from '@mui/x-data-grid';
import { DataGridStyled, StyledTextField } from '../common/StyledMuiComponents';
import { UserFlags } from '../interfaces/UserFlags';
import { User } from '../interfaces/User';
import { produce } from 'immer';
import { toast } from 'react-toastify';
import useAxios from '../hooks/useAxios';
import useProfile from '../hooks/useProfile';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';

export const VerifyCompanyTable = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>(users);
  const { fetchData } = useAxios();
  const { putData: verifyCompany } = useAxios();
  const profile = useProfile();

  useEffect(() => {
    fetchData("/admin/users", {}, true, onSuccess, () => {})
  }, [])

  const onSuccess = (response: any) => {
    const newUsers = response as User[];
    setUsers(newUsers.filter((user) => user.company !== null));
    setFilteredUsers(newUsers.filter((user) => user.company !== null));
  }

  const handleVerificationToggle = (id: string) => {
    setUsers(prev => produce(prev, draft => {
      const userToUpdate = draft.find(user => user.id === id);
      if (userToUpdate && userToUpdate.company) {
        userToUpdate.company.isVerified = !userToUpdate.company.isVerified;
        onVerify(userToUpdate);
      }
    }))
  };

  const handleSetOSTextil = (id: string, e: React.ChangeEvent<HTMLInputElement>) => {
    setUsers(prev => produce(prev, draft => {
      const userToUpdate = draft.find(user => user.id === id);
      if (userToUpdate) {
        if(e.target.checked) {
          userToUpdate.userFlags |= UserFlags.OsTextil
        } else {
          userToUpdate.userFlags &= ~UserFlags.OsTextil
        }
        onVerify(userToUpdate);
      }
    }))
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase();
    const filtered = users.filter(user => 
      user.firstName.toLowerCase().includes(searchTerm) ||
      user.lastName.toLowerCase().includes(searchTerm) ||
      user.company?.name.toLowerCase().includes(searchTerm)
    );
    setFilteredUsers(filtered);
  };

  const onVerify = async (user: User) => {
    const { roles, ...userToSend } = user;
    userToSend.isEnabled = true;
    await verifyCompany("Admin/User", {}, userToSend, true, onSuccessVerify, () => {});
  }

  const onSuccessVerify = () => {
    toast.success("Änderung erfolgreich.", {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'Vorname', width: 150 },
    { field: 'lastName', headerName: 'Nachname', width: 150 },
    { field: 'companyName', headerName: 'Firma', width: 200 },
    { field: 'uid', headerName: 'UID-Nummer', width: 150 },
    { field: 'isVerified', headerName: 'Ist Verifiziert', width: 120, renderCell: (params) => (
      <Checkbox
        color="success"
        checked={users.find(user => user.id === params.row.id)?.company?.isVerified || false}
        onChange={() => handleVerificationToggle(params.row.id || "")}
      />
    )},
    { field: 'userFlags', headerName: 'OS Textil', width: 120, renderCell: (params) => (
      <Checkbox
        color="success"
        checked={users.find(user => user.id === params.row.id)?.userFlags === UserFlags.OsTextil || false}
        onChange={(e) => handleSetOSTextil(params.row.id || "", e)}
      />
    )},
  ];

  const rows = filteredUsers.map(user => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    uid: user.company?.uid || '',
    companyName: user.company?.name || '',
    isVerified: user.company?.isVerified || false,
    userFlags: user.userFlags === UserFlags.OsTextil ? true : false
  }));

  return (
    <Grid container spacing={2}>
      <Grid item>
        <StyledTextField
          color="success"
          type="text"
          placeholder="Suche"
          size="small"
          onChange={handleFilterChange} 
        />
      </Grid>

      <Grid item style={{ height: "100%", width: '100%' }}>
        <DataGridStyled
          sx={{backgroundColor: profile?.colorMode === "light" ? "white" : "transparent"}}
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          pageSizeOptions={[5, 10]}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
        />
      </Grid>
    </Grid>
  );
};