import { produce } from "immer";
import { useGetAutocompleteNames } from "../../hooks/useGetAutocompleteNames";
import { AutocompleteTextField, BookingTextField, TypographySubheading } from "../../common/StyledMuiComponents";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import useValidation from "../../hooks/useValidation";
import useContent from "../../hooks/useContent";
import { Client } from "../../interfaces/Order";
import { isTokenExpired } from "../../common/CheckToken";
import { AutocompleteClientAddressField } from "./AutocompleteClientAddressField";
import { useState } from "react";

interface ClientProps {
  disabled: boolean
}

export const ClientComponent: React.FC<ClientProps> = ({disabled}) => {
  const {getAutocompleteOptions: getClient2, options: clientOptions, optionNames: clientOptionsName} = useGetAutocompleteNames();
  const { error: emailError, errorMsg: emailErrorMsg } = useValidation();
  const [plzError, setPlzError] = useState<boolean>(false);
  const [plzErrorMsg, setPlzErrorMsg] = useState<string>("");
  const content = useContent();

  return <>
    <Grid item xs={12} >
      <Paper sx={{marginLeft: "30px", marginRight: "30px", marginTop: "30px"}} elevation={0}>
        <Grid container item spacing={2} pr={2} pb={2} pl={2}>
          <Grid item xs={12}>
            <TypographySubheading>
              Auftraggeber
            </TypographySubheading>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Autocomplete 
              id="client"
              options={clientOptionsName}
              freeSolo
              isOptionEqualToValue={(option, value) => option === value || value === ""}
              autoHighlight
              autoComplete
              disabled={disabled}
              value={content?.order.client.name}
              filterOptions={(x) => x}
              renderInput={(params) => (
                <AutocompleteTextField 
                  { ...params }
                  label="Name"
                  color="success"
                  size="small"
                  fullWidth
                  required
                />
              )}
              onChange={(event, value) => {
                if(value){
                  const clientopt =  clientOptions as Client[];
                  const currentOption = clientopt?.find((option) => option.name === value);
                  content?.setOrder(prev => produce(prev, draft => {
                    draft.client.name = value;
                    draft.client.email = currentOption?.email !== undefined ? currentOption.email : "";
                    draft.client.phoneNumber = currentOption?.phoneNumber !== undefined ? currentOption.phoneNumber : "";
                  }));
                } else {
                  content?.setOrder(prev => produce(prev, draft => {
                    draft.client.name = "";
                  }));
                }
              }}
              onInputChange={(e, newInputValue) => {
                content?.setOrder(prev => produce(prev, draft => {
                  draft.client.name = newInputValue;
                }));
                !isTokenExpired() && getClient2(newInputValue, 2);
              }}
              renderOption={(props, option) => {
                const clientopt =  clientOptions as Client[];
                const currentOption = clientopt?.find((opt) => opt.id.toString() === option);
                return (
                  <li {...props}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                      { currentOption?.name }
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="text.secondary">{ currentOption?.email }</Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <BookingTextField 
              label="Email"
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              color="success"
              size="small"
              required
              disabled={disabled}
              autoFocus
              value={content?.order.client.email || ""}
              error={emailError}
              helperText={emailError ? emailErrorMsg : ""}
              onChange={(e) => {
                console.log(content?.order)
                content?.setOrder(prev => produce(prev, draft => {
                  draft.client.email = e.target.value;
                }));
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <BookingTextField 
              label="Telefonnummer"
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              autoComplete="phoneNumber"
              color="success"
              size="small"
              required
              disabled={disabled}
              autoFocus
              value={content?.order.client.phoneNumber || ""}
              onChange={(e) => {
                content?.setOrder(prev => produce(prev, draft => {
                  draft.client.phoneNumber = e.target.value;
                }));
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <BookingTextField 
              label="PLZ"
              fullWidth
              id="clientPLZ"
              name="clientPLZ"
              autoComplete="clientPLZ"
              color="success"
              size="small"
              required
              disabled={disabled}
              error={plzError}
              helperText={plzError ? plzErrorMsg : ""}
              value={content?.order.client.plz || ""}
              onChange={(e) => {
                plzError && setPlzError(false);
                content?.setOrder(prev => produce(prev, draft => {
                  draft.client.plz = parseInt(e.target.value, 10);
                }));
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <BookingTextField 
              label="Ort"
              fullWidth
              id="clientCity"
              name="clientCity"
              autoComplete="clientCity"
              color="success"
              size="small"
              required
              disabled={disabled}
              value={content?.order.client.city || ""}
              onChange={(e) => {
                content?.setOrder(prev => produce(prev, draft => {
                  draft.client.city = e.target.value;
                }));
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <AutocompleteClientAddressField 
              setPlzError={setPlzError}
              setPlzErrorMsg={setPlzErrorMsg}
              idString="clientAddress"
              textLabel="Straße"
            />
          </Grid>

        </Grid>
      </Paper>
    </Grid>
  </>
}