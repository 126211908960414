import { useCallback, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from '@mui/material/utils'
import { toast } from 'react-toastify';
import axiosOnlyUrl from "../api/axiosOnlyUrl";
import axiosOriginal from "axios";
import { KnownNameData } from "../interfaces/KnownNameData";
import { Cookies } from "react-cookie-consent";
import { Client } from '../interfaces/Order';

export const useGetAutocompleteNames = () => {
  const [options, setOptions] = useState<KnownNameData | Client[]>();
  const [optionNames, setOptionNames] = useState<string[]>([]);
  const abortControllerRef = useRef<AbortController | null>(null);
  const navigate = useNavigate();

  const getAutocompleteOptions = useCallback(
    debounce(async (name: string, type: number) => {

      if (!name || name === "") {
        return;
      }

      // Abort any pending requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create new abort controller
      const newAbortController = new AbortController();
      abortControllerRef.current = newAbortController;

      try {
        const bearerToken = Cookies.get('token');
        const config = {
          headers: { Authorization: `Bearer ${bearerToken}` },
          signal: newAbortController.signal
        };

        const response = await axiosOnlyUrl.post(`/user/GetByKnownName`, { Name: name, Type: type }, config);
        setOptions(response?.data)
        setNameOptions(response?.data, type);
      
      } catch (error: any) {
        handleApiError(error);
      }
    }, 500),
    []
  );

  const handleApiError = (error: any) => {
    if(error.response) {
      if(axiosOriginal.isAxiosError(error)) {
        if (error.response) {
          if(error.response.status === 401) {
            toast.error(error.response.data.error || "Benutzerauthentifizierung erforderlich. Bitte melden Sie sich erneut an.", {
              position: toast.POSITION.TOP_RIGHT
            });
            navigate('/login');
          } else if(error.response.status === 404) {
            toast.error(error.response.data.error || "404 - Not Found", {
              position: toast.POSITION.TOP_RIGHT
            });
          } else {
            toast.error(error.response.data.error || "Ein unerwarteter Fehler ist aufgetreten.", {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        }
      }
    }
  };

  const setNameOptions = (data: KnownNameData | Client[], type: number) => {
    if(type === 1 || type === 0) {
      const val: KnownNameData | Client[] = data as KnownNameData;
      const names:string[] = [];

      val?.rememberedOrders && val?.rememberedOrders.map((order) => {
        names.push(order.orderId);
        return null;
      })

      val?.rememberedTamburiBoxes && val?.rememberedTamburiBoxes.map((box) => {
        names.push(box.id.toString());
        return null;
      })

      setOptionNames(names);
    } else if(type === 2) {
      const val: KnownNameData | Client[] = data as Client[];
      const names:string[] = [];

      val && val.map((client) => {
        names.push(client.id.toString());
        return null;
      })

      setOptionNames(names);
    } else {
      setOptionNames([]);
    }
  }

  return {
    getAutocompleteOptions,
    options,
    optionNames
  };
};