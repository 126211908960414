import { useNavigate } from "react-router-dom";
import { isTokenExpired } from "./CheckToken";
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function ErrorPage() {
  const navigate = useNavigate();
  const onNavigateToHome = () => {
    const tokenExpired = isTokenExpired();
    tokenExpired ? navigate("/login") : navigate("/");
  }

  return (
    <Container component="main" maxWidth="xs" sx={{marginTop: "5%"}}>
      <Grid container>
        <Grid item xs={12} sm={12} textAlign="center">
          <ErrorOutlineIcon sx={{fontSize: "52px"}} color="error"/>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography component="h1" variant="h5" align="center" mb={3}>
            Ups, leider ist etwas schief gelaufen...
          </Typography >
          {/* <Typography align="center" color="textSecondary">
            Fehler: {error.message}
          </Typography> */}
          <Button onClick={onNavigateToHome} variant="text" color="error">
            Zurück
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}