import { ThemeProvider, styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { TypographyPlus } from '../common/StyledMuiComponents';
import { Price } from '../interfaces/Price';
import useProfile from "../hooks/useProfile";
import useContent from "../hooks/useContent";
import useAxios from "../hooks/useAxios";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

interface AskUserToRegisterProps {
  open: boolean,
  loading: boolean,
  onClose: () => void
  onBookNow: (useCredit: boolean) => void
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const AskUserToRegister: React.FC<AskUserToRegisterProps> = ({onBookNow, open, onClose, loading}) => {
  const [register, setRegister] = useState<boolean | null>(null);
  const [agbChecked, setAgbChecked] = useState(false);
  const [prices, setPrices] = useState<Price[]>();
  const profile = useProfile();
  const theme = useTheme();
  const content = useContent();
  const { fetchData } = useAxios();
  const price = prices?.find(price => price.packageType === content?.order?.packages[0].packageType)?.price;

  useEffect(() => {
    fetchData("/Payment/CheckPrices", {}, true, onSuccess, () => {})
    console.log(loading)
  }, [])

  const onSuccess = (response: any) => {
    setPrices(response)
  }

  const handleAgbChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgbChecked(event.target.checked);
  };

  return <>
    <ThemeProvider theme={theme}>
      <BootstrapDialog open={open}>
        {/* <DialogTitle sx={{ m: 0, p: 2 }} bgcolor="#fff" color="#115427">
          Zahlungsmethode wählen
        </DialogTitle> */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item>
              <TypographyPlus gutterBottom fontSize="18px" mt={2}>
                Wussten Sie schon, dass ...
              </TypographyPlus>
            </Grid>

            <Grid item>
              <TypographyPlus gutterBottom>
                Sie als registrierter Benutzer ein Guthaben aufladen können und Aufträge günstiger buchen können?
              </TypographyPlus>
            </Grid>

            <Grid item xs={6}>
              <Card
                variant='outlined'
                onClick={() => (setRegister(true))}
                sx={{
                  cursor: 'pointer',
                  border: register ? '2px solid black' : '1px solid rgba(0, 0, 0, 0.2)',
                  borderColor: profile?.colorMode === "light" ? (register ? "black" : "rgba(0, 0, 0, 0.2)") : (register ? "#66BB6A" : "rgba(255, 255, 255, 0.23)"),
                  color: profile?.colorMode === "light" ? "black" : (register ? "#66BB6A" : "white"),
                  backgroundColor: profile?.colorMode === "light" ? (register ? "#E8E226" : "white") : "#121212",
                  boxShadow: 'none',
                  ":hover": {
                    color: profile?.colorMode === "light" ? "black" : "#66BB6A",
                    backgroundColor: profile?.colorMode === "light" ? "#E8E226" : "#121212",
                    borderColor: profile?.colorMode === "dark" ? "#66BB6A" : ""
                  }
                }}
              >
                <CardActionArea>
                  <CardContent>
                    <TypographyPlus gutterBottom textAlign="center">
                      Jetzt Registrieren und buchen
                    </TypographyPlus>
                    <TypographyPlus gutterBottom textAlign="center">
                      {price?.toLocaleString()}€
                    </TypographyPlus>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Card
                variant='outlined'
                onClick={() => setRegister(false)}
                sx={{
                  cursor: 'pointer',
                  border: register === false ? '2px solid black' : '1px solid rgba(0, 0, 0, 0.2)',
                  borderColor: profile?.colorMode === "light" ? (register === false ? "black" : "rgba(0, 0, 0, 0.2)") : (register === false ? "#66BB6A" : "rgba(255, 255, 255, 0.23)"),
                  color: profile?.colorMode === "light" ? "black" : (register === false ? "#66BB6A" : "white"),
                  backgroundColor: profile?.colorMode === "light" ? (register === false ? "#E8E226" : "white") : "#121212",
                  boxShadow: 'none',
                  ":hover": {
                    color: profile?.colorMode === "light" ? "black" : "#66BB6A",
                    backgroundColor: profile?.colorMode === "light" ? "#E8E226" : "#121212",
                    borderColor: profile?.colorMode === "dark" ? "#66BB6A" : ""
                  }
                }}
              >
                <CardActionArea>
                  <CardContent>
                    <TypographyPlus gutterBottom textAlign="center">
                      Als Gast fortfahren und buchen
                    </TypographyPlus>
                    <TypographyPlus gutterBottom textAlign="center">
                      {price ? (price + 1).toLocaleString() : "0,00"}€
                    </TypographyPlus>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={agbChecked} onChange={handleAgbChange} color="success"/>}
                label={
                  <span style={{fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif", fontSize:"14px"}}>
                    Ich habe die{' '}
                    <Link to="agb" target="_blank" rel="noopener noreferrer">
                      AGB
                    </Link>{' '}
                    gelesen und bin mit ihnen einverstanden.
                  </span>
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Button 
                variant="contained" 
                color="yellow" 
                disabled={!agbChecked || register === null || loading} 
                onClick={() => onBookNow(register || false)} 
                fullWidth 
                sx={{marginTop: "20px"}}
              >
                { loading ? <CircularProgress size={24} color="inherit"/> :  "Weiter"} 
              </Button>
              <Button onClick={onClose} color="success" fullWidth sx={{marginTop: "5px"}}>
                Schließen
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </ThemeProvider>
  </>
}