import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { StyledTextField, TypographyHeading } from "../common/StyledMuiComponents";
import { EmailTemplate } from "../interfaces/EmailTemplate";
import { produce } from "immer";
import { toast } from "react-toastify";
import useAxios from "../hooks/useAxios";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from "@mui/material/Divider";
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

interface LocationState {
  userId: string;
}

export const EditEmailTemplate = () => {
  const [emailTemp, setEmailTemp] = useState<EmailTemplate>();
  const { state } = useLocation() as { state: LocationState };
  const { putData, isLoading } = useAxios();
  const { fetchData } = useAxios();

  useEffect(() => {
    getEmail();
  }, [])

  const getEmail = () => {
    fetchData("/admin/emailstyle/?id=" + state.userId, {}, true, onSuccess, () => {})
  }

  const onSuccess = (response: any) => {
    console.log(response);
    setEmailTemp(response);
  }

  const updateEmail = () => {
    if(emailTemp !== null) {
      putData(
        "/admin/emailstyle", { 'Content-Type': 'application/json' },
        emailTemp,
        true,
        onSuccessUpdateEmail,
        () => {}
      )
    }
  }

  const onSuccessUpdateEmail = () => {
    getEmail();
    toast.success("Das E-Mail Template wurde erfolgreich geändert.", {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  return <>
    <Container component="main">
      <CssBaseline />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TypographyHeading>
              Admin Center
            </TypographyHeading>
          </Grid>
          <Grid item xs={12} sm={12} mb={3}>
            <Divider />
          </Grid>
        </Grid>
        
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Email Absender"
                  name="emailSender"
                  size="small"
                  color="success"
                  fullWidth
                  value={emailTemp?.emailSender !== null ? emailTemp?.emailSender || "" : ""}
                  onChange={(e) => {
                    setEmailTemp(prev => produce(prev, draft => {
                      if(draft !== undefined)
                        draft.emailSender = e.target.value;
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Email Betreff"
                  name="emailSubject"
                  size="small"
                  color="success"
                  fullWidth
                  value={emailTemp?.emailSubject !== null ? emailTemp?.emailSubject || "" : ""}
                  onChange={(e) => {
                    setEmailTemp(prev => produce(prev, draft => {
                      if(draft !== undefined)
                        draft.emailSubject = e.target.value;
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Email Benutzer"
                  name="emailUser"
                  size="small"
                  color="success"
                  fullWidth
                  value={emailTemp?.emailUser !== null ? emailTemp?.emailUser || "" : ""}
                  onChange={(e) => {
                    setEmailTemp(prev => produce(prev, draft => {
                      if(draft !== undefined)
                        draft.emailUser = e.target.value;
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Email Passwort"
                  name="emailPassword"
                  size="small"
                  color="success"
                  fullWidth
                  value={emailTemp?.emailPassword !== null ? emailTemp?.emailPassword || "" : ""}
                  onChange={(e) => {
                    setEmailTemp(prev => produce(prev, draft => {
                      if(draft !== undefined)
                        draft.emailPassword = e.target.value;
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledTextField
                  label="Email Server"
                  name="emailHost"
                  size="small"
                  color="success"
                  fullWidth
                  value={emailTemp?.emailHost !== null ? emailTemp?.emailHost || "" : ""}
                  onChange={(e) => {
                    setEmailTemp(prev => produce(prev, draft => {
                      if(draft !== undefined)
                        draft.emailHost = e.target.value;
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControlLabel 
                  sx={{ float:"left" }}
                  control={
                    <Checkbox 
                      name="enableSSL"
                      color="success"
                      checked={emailTemp !== undefined ? emailTemp.enableSSL : false}
                      onChange={() => {
                        setEmailTemp(prev => produce(prev, draft => {
                          if(draft !== undefined)
                            draft.enableSSL = !prev?.enableSSL;
                        }));
                      }}
                    />
                  }
                  label="SSL aktiviert"
                />
              </Grid>

              <Grid item xs={12}>
                <ReactQuill theme="snow" value={emailTemp?.html} onChange={(content) => 
                  setEmailTemp(prev => produce(prev, draft => {
                    if(draft !== undefined)
                      draft.html = content;
                  }))} 
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Button
                  variant="contained"
                  color="yellow"
                  disabled={isLoading}
                  sx={{minWidth: "150px", float: "left"}}
                  onClick={updateEmail}
                >
                  { isLoading ? <CircularProgress size={32} color="inherit"/> :  "Änderungen speichern"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  </>
}