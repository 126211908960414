import { Outlet, useNavigate } from "react-router-dom"
import { LogoutButton, NavButtonGroup, StyledAppBar } from "./StyledMuiComponents"
import useGetProfile from "../hooks/useGetProfile"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import CssBaseline from "@mui/material/CssBaseline"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import useMediaQuery from "@mui/material/useMediaQuery"
import LoginIcon from '@mui/icons-material/Login';

export const NavLayoutNoAuth = () => {
  const { profile } = useGetProfile();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:450px)');
  var imageBasePath =
    window.location.protocol + "//" + window.location.host + "/";

  const navigateToHome = () => navigate("/");

  const Footer = () => {
    return (
      <footer style={{marginTop:"20px", backgroundColor: `${profile?.colorMode === "light" ? "#fff" : "#1E1E1E"}`}}>
        <ButtonGroup>
            <Button onClick={navigateToImprint} variant="text" color="success">
              Impressum
            </Button>
            <Button onClick={navigateToAgb} variant="text" sx={{ my: 1, mx: 1.5 }} color="success">
              AGB
            </Button>
          </ButtonGroup>
      </footer>
    );
  };

  const navigateToImprint = () => {
    navigate("/imprint");
  }

  const navigateToAgb = () => {
    navigate("/agb");
  }

  const NavigateToLogin = () => navigate("/login");

  return <>
    <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <StyledAppBar
        position="static"
        color="inherit"
        elevation={0}
      >
        <CssBaseline/>
        <Toolbar sx={{ flexWrap: 'wrap', justifyContent: "center" }}>
          <img 
            style={{maxWidth:"100px", marginBottom:"20px", margin: "5px", cursor: "pointer"}}
            alt="veloce-logo"
            src={imageBasePath + "veloce_liefert.svg"}
            onClick={navigateToHome}
          />
          <NavButtonGroup size="small">
            <Button onClick={navigateToHome} variant="text" sx={{ my: 1, mx: 1.5 }} color="success">
              Buchung
            </Button>
            {/* <Button onClick={NavigateToLogin} variant="text" sx={{ my: 1, mx: 1.5 }} color="success">
              Guthaben aufladen
            </Button>
            <Button onClick={NavigateToLogin} variant="text" sx={{ my: 1, mx: 1.5 }} color="success">
              Track & Trace
            </Button> */}

            {/* <AccountMenu /> */}
            <IconButton sx={{ ml: 1, mr: 1.5 }} onClick={() => { 
                profile?.setColorMode(profile?.colorMode === "light" ? "dark" : "light")
                localStorage.setItem('colorMode', profile?.colorMode === "light" ? "dark" : "light");
              }} 
              >
              {profile?.colorMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
            <LogoutButton onClick={NavigateToLogin} variant="contained" sx={{ my: 1, mx: 1.5 }} color="yellow" disableElevation>
              {isMobile ? <LoginIcon /> : 'Einloggen'}
            </LogoutButton>
          </NavButtonGroup>
        </Toolbar>
      </StyledAppBar>
      
      <Outlet/>
      
      <div style={{ flex: 1 }} />
      <Footer />
    </div>
  </>
}