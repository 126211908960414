import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import "../styles/Impressum.css";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const Agb = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.substring(1);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  return <>
    <Container component="main" >
      <CssBaseline/>
      <Box className="impressum-container" sx={{ width: '100%', textAlign: "justify" }}>
        <Typography variant="h2" mt={3} gutterBottom>
          agb.
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>ALLGEMEINE TRANSPORTBEDINGUNGEN der veloce liefert gmbh A-1110 Wien, Döblerhofstrasse 16 (Stand: 3.5.2024)</b>
        </Typography>
        
        <br/>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          Über die veloce liefert-APP gebuchte Beförderungsleistungen erbringen wir ausschließlich auf Grund dieser 
          allgemeinen Transportbedingungen. Soweit durch unsere allgemeinen Transportbedingungen nichts anderes geregelt 
          ist, gelten die einschlägigen gesetzlichen Vorschriften und auch das Übereinkommen über den Beförderungsvertrag 
          im internationalen Straßengüterverkehr (CMR).<br/><br/>
        </Typography>

        <Typography variant="h5" gutterBottom mt={3}>
          <b>1. LEISTUNGEN UND PREISE</b>
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          1.1 Wir übernehmen die Beförderung von Dokumenten oder Gütern. Wir erbringen die Beförderungsleistung entweder 
          selbst oder durch uns geeignet erscheinende Dritte.
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          1.2 Wir übernehmen nicht die Beförderung von • Kostbarkeiten, Kunstgegenständen, Geld und Wertpapieren 
          (insbesondere Schecks, Wechsel, Sparbücher, Aktien und dergleichen), sowie ganz allgemein Sendungen im 
          Wert von mehr als EUR 2.500,-; gefährlichen Gütern, die den einschlägigen Vorschriften über die Beförderung 
          gefährlicher Güter auf der Straße unterliegen und nur mit besonderer Kennzeichnung transportiert werden dürfen; 
          • verderblichen Gütern oder lebenden Tieren; • Waffen, Drogen, sonstige illegale Güter.
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          1.3 Wir sind darüber hinaus jederzeit berechtigt, die Übernahme von Sendungen ohne Angaben von Gründen abzulehnen 
          oder das Service einzuschränken oder einzustellen.
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          1.4 Bitte beachten Sie, dass sämtliche Beförderungszeiten und Zustellfristen immer nur „nach Möglichkeit“, aber ohne 
          rechtliche Gewähr unsererseits, zugesagt werden. Die angezeigten Preise sind brutto-Preise incl. USt. Und schließen 
          das Abholen, den Transport und die Zustellung mit ein. Bei Transporten bis 50kg sind für den Abholstopp und den 
          Zustellstopp je 5 Minuten inkludiert, bei größeren Transporten je 15 Minuten. Für darüber hinaus gehende Stoppzeiten 
          werden pro angefangene 30 Minuten 20.-€ verrechnet.
        </Typography>

        <Typography variant="h5" gutterBottom mt={3}>
          <b>2. DURCHFÜHRUNG DER BEFÖRDERUNG, KOSTEN</b>
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          2.1 Wir sind nicht verpflichtet, den Inhalt von Sendungen zu überprüfen. Sofern der Auftraggeber uns anlässlich 
          der Übergabe des Gutes zur Beförderung keine anderen Angaben macht, sind wir berechtigt anzunehmen, dass das Gut 
          den Bestimmungen des Punktes 1.2 entspricht, insbesondere dass es sich um kein von der Beförderung ausgeschlossenes Gut handelt.
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          2.2 Für die ordnungsgemäße und transportsichere Verpackung der Sendung ist ausschließlich der Auftraggeber verantwortlich. 
          Uns obliegt keinerlei Überprüfungs[1]oder Hinweispflicht.
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          2.3 Soweit nichts anderes vereinbart ist, werden Sendungen, deren Annahme vom Empfänger verweigert wird, oder die aus 
          sonstigen Gründen nicht zugestellt werden können, auf Gefahr und Kosten des Auftraggebers an ihn zurückbefördert. 
          Postwurfsendungen können, sofern sie als solche bezeichnet sind, auch durch Einwurf in Briefkasten zugestellt werden.
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          2.4 Sollte sich während des Transportes herausstellen, dass uns der Auftraggeber ein von der Beförderung ausgeschlossenes 
          Gut übergeben hat, sind wir berechtigt, nach eigenem Ermessen jede uns geeignete Maßnahmen zu setzen, um Sach- und 
          Personenschäden, den Eintritt von Gefahren und/oder illegale Handlungen zu vermeiden, einschließlich des Notverkaufs, 
          der Einlagerung, der Übergabe des Gutes an staatliche Stellen, und des Rücktransportes an den Auftraggeber bzw. den 
          Absender auf dessen Gefahr und Kosten.
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          2.5 Für das Beförderungsentgelt, zuzüglich aller im Zusammenhang mit der Beförderung entstehenden sonstigen Kosten, 
          Steuern und Abgaben, haftet der Auftraggeber. Das Beförderungsentgelt ist grundsätzlich bei der Abholung im voraus 
          in bar zu bezahlen. Nur wenn der Auftraggeber der Empfänger ist, wird das Frachtengelt bei Zustellung beim Auftraggeber 
          fällig. Im Übrigen sind unsere Rechnungen sofort nach Erhalt ohne jeden Abzug zur Zahlung fällig. Im Verzugsfall sind 
          wir berechtigt, Verzugszinsen von 1 % pro begonnenem Monat des Verzugs zu verrechnen.
        </Typography>

        <Typography variant="h5" gutterBottom mt={3}>
          <b>3. HAFTUNG</b>
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          3.1 Der Auftraggeber haftet uns verschuldensunabhängig dafür, dass alle Angaben, einschließlich der Wertangaben, 
          die er uns anlässlich der Beauftragung gemacht hat , vollständig und richtig sind, dass es sich bei seiner Sendung 
          um kein gemäß Punkt I. von der Beförderung ausgeschlossenes Gut handelt, die Sendung ordnungsgemäß verpackt, 
          gekennzeichnet und adressiert ist und durch die Beförderung keine geltenden gesetzlichen Bestimmungen verletzt werden. 
          Für alle Schäden und Nachteile, die uns als Folge unrichtiger oder unvollständiger Angaben oder nicht ordnungsgemäßer 
          Verpackung entstehen, hält uns der Auftraggeber vollkommen schad- und klaglos.
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          3.2 Von Personenschäden abgesehen, ist unsere Haftung in jedem Fall für leichte Fahrlässigkeit ausgeschlossen.
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          3.3 Im Übrigen gelten, sofern nicht andere gesetzliche oder in internationalen Übereinkommen enthaltene zwingende 
          Bestimmungen vorgehen, ungeachtet der Transportart die Haftungsgrenzen des Art 23 CMR. Demnach haben wir, sofern 
          uns nicht Vorsatz oder dem Vorsatz gleichstehendes Verschulden zur Last liegt, im Fall eines von uns verschuldeten 
          gänzlichen Verlustes des Gutes den Wert des Gutes am Ort und zur Zeit der Übernahme zur Beförderung, höchstens 
          jedoch den Gegenwert von 8,33 Sonderziehungsrechten des Internationalen Währungsfonds für jedes fehlende Kilogramm 
          des Rohgewichts, zu ersetzen und die Fracht, Zölle und sonstige aus Anlass der Beförderung entstandene Kosten 
          zurückzuerstatten. Bei teilweisem Verlust oder Beschädigung erfolgt die Ersatzleistung anteilig.
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          3.4 Höhere als die vorgenannten Entschädigungen kann der Auftraggeber nicht beanspruchen. Die Ersatzansprüche sind 
          mit der Versicherungsleistung betraglich begrenzt. Im Fall des Verlustes oder der Beschädigung wird ausschlißlich 
          der Wert des Gutes, nicht aber Verspätungs- oder sonstige Vermögensschäden, wie Folgeschäden, entgangenen Gewinn, etc., ersetzt.
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          3.5 Unsere Haftung für Verluste, Schäden und Verspätungen aus Gründen, die außerhalb unserer Kontrolle liegen 
          (wie höhere Gewalt, Verkehrs- und Witterungsverhältnisse), ist ausgeschlossen.
        </Typography>

        <Typography variant="h5" gutterBottom mt={3}>
          <b>4. DATENSCHUTZ</b>
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          4.1. Wir sind berechtigt, personenbezogene Daten, die wir im Zusammenhang mit unserer Dienstleistung erhalten haben, 
          zu sammeln, zu speichern und zu verarbeiten und an andere Partnergesellschaften von Veloce weiterzugeben, soweit und 
          solange dies für die Erbringung unserer Dienstleistungen erforderlich ist. Die Verwendung und die Verarbeitung der 
          Daten erfolgt unter Einsatz von elektronischen Datenverarbeitungssystemen. Die Datenverarbeitung kann im Hinblick 
          auf weitere Leistungen und Angeboten von uns oder Veloce Partnergesellschaften erfolgen. Der Auftraggeber ist mit 
          dieser Datenerfassung und –verarbeitung sowie Übermittlung, insbesondere auch an staatliche Stellen einverstanden. 
          Der Auftraggeber ist berechtigt, dieses Einverständnis jederzeit zu widerrufen. Der Auftraggeber stimmt zu, dass 
          die vom Auftraggeber angegebene Mobiltelefonnummer gemäß § 107 Telekommunikationsgesetz zum Zweck der Werbung für 
          eigene Leistungen von uns verwendet werden darf, solange der Auftraggeber dem nicht widerspricht.
        </Typography>

        <Typography variant="h5" gutterBottom mt={3}>
          <b>5. SONSTIGES</b>
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          5.1 Es gilt österreichisches Recht. 5.2 Ausschließlicher Gerichtsstand für alle im Zusammenhang mit der von uns 
          übernommenen Beförderung ergebende Streitigkeiten ist das für den ersten Bezirk in Wien sachlich zuständige Gericht. 
          Für Auftraggeber, die Verbraucher sind, ist dieser Gerichtsstand nicht ausschließlich.
        </Typography>
      </Box>
    </Container>
  </>
}