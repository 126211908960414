import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Cookies } from "react-cookie-consent";
import axios from '../api/axios';

export const PaymentVerify = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() =>  {
    const verifyUser = async () => {
      const params = new URLSearchParams(location.search);
      const transactionId = encodeURIComponent(params.get("transactionId") as string);
      
      if(transactionId) {
        try {
          const bearerToken = Cookies.get('token');

          var data = { transactionId: encodeURIComponent(transactionId)};

          const response = await axios.post("/payment/verify", data, { headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            Authorization: `Bearer ${bearerToken}`
          }});

          toast.success(response.data, {
            position: toast.POSITION.TOP_RIGHT
          });
        } catch (error: any) {
          toast.error(error.response.data.error || "Ein unerwarteter Fehler ist aufgetreten.", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }

      navigate("/")
    }
    verifyUser();
  }, [location.search])

  return (
    <div></div>
  );
};