import { NavLink, Outlet } from 'react-router-dom';
import useProfile from '../hooks/useProfile';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

export const AdminNav = () => {
  const profile = useProfile();
  return <>
      <Grid container mt={4} maxWidth="lg" alignSelf="center">
        <Grid item xs={12} sm={2.5} textAlign={"left"}>
          <MenuList>
            <NavLink 
              to="/admin/center" 
              relative={"route"}
              style={{
                color: '#36454F', background: '#f0f0f0', textDecoration: "none"
              }}
            >
              {({ isActive }) => (
                <MenuItem
                  style={{
                    background: isActive ? (profile?.colorMode === "light" ? '#D3D3D3' : '#252525') : profile?.colorMode === "light" ? "#F2F2F2": "#121212",
                    color: isActive ? (profile?.colorMode === "light" ? '#36454F' : '#fff') : profile?.colorMode === "light" ? "#36454F": "#fff",
                  }}
                >
                  <ListItemText>Admin Center</ListItemText>
                </MenuItem>
              )}
            </NavLink>
            <NavLink 
              to="/admin/verify" 
              relative={"route"}
              style={{
                color: '#36454F', background: '#f0f0f0', textDecoration: "none"
              }}
            >
              {({ isActive }) => (
                <MenuItem
                  style={{
                    background: isActive ? (profile?.colorMode === "light" ? '#D3D3D3' : '#252525') : profile?.colorMode === "light" ? "#F2F2F2": "#121212",
                    color: isActive ? (profile?.colorMode === "light" ? '#36454F' : '#fff') : profile?.colorMode === "light" ? "#36454F": "#fff",
                  }}
                >
                  <ListItemText>Unternehmensverifikation</ListItemText>
                </MenuItem>
              )}
            </NavLink>
            <NavLink 
              to="/admin/emailtemplates" 
              relative={"route"}
              style={{
                color: '#36454F', background: '#f0f0f0', textDecoration: "none"
              }}
            >
              {({ isActive }) => (
                <MenuItem
                  style={{
                    background: isActive ? (profile?.colorMode === "light" ? '#D3D3D3' : '#252525') : profile?.colorMode === "light" ? "#F2F2F2": "#121212",
                    color: isActive ? (profile?.colorMode === "light" ? '#36454F' : '#fff') : profile?.colorMode === "light" ? "#36454F": "#fff",
                  }}
                >
                  <ListItemText>E-Mail Vorlagen</ListItemText>
                </MenuItem>
              )}
            </NavLink>
          </MenuList>
        </Grid>
        <Grid item xs={12} sm={9.5}>
          <Outlet/>
        </Grid>
    </Grid>
  </>
}