import { Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputAdornment, OutlinedInput, ThemeProvider, Typography } from "@mui/material"
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import VisaImg from '../../images/visa.png';
import MastercardImg from '../../images/mastercard.png';
import AmericanExpressImg from '../../images/americanExpress.png';
import { useState } from "react";
import axios from "../api/axios";
import axiosOriginal from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie-consent";
import { useTheme } from "@emotion/react";

interface CreditRechargeProps {
  open: boolean,
  onClose: () => void
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const CreditRechargePopup: React.FC<CreditRechargeProps> = ({open, onClose}) => {
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  const onCreatePayment = async () => {
    
    const isNumber = /^[0-9]+([.,])?([0-9]{1,2})?$/.test(amount);
    if(!isNumber) {
      return;
    }

    try {
      const bearerToken = Cookies.get('token');
      const response = await axios.post("/payment?amount=" + amount.replace(',', '.'), {},  { headers: { Authorization: `Bearer ${bearerToken}` }});
      window.open(response?.data, '_blank');

    } catch (error) {
      if(axiosOriginal.isAxiosError(error)) {
        if (error.response) {
          if(error.response.status === 401) {
            toast.error(error.response.data.error || "Benutzerauthentifizierung erforderlich. Bitte melden Sie sich erneut an.", {
              position: toast.POSITION.TOP_RIGHT
            });
            navigate('/login');
          } else if(error.response.status === 404) {
            toast.error(error.response.data.error || "404 - Not Found", {
              position: toast.POSITION.TOP_RIGHT
            });
          } else {
            toast.error(error.response.data.error || "Ein unerwarteter Fehler ist aufgetreten.", {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        }
      }
    }
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Backspace' && amount.length === 1) {
      setAmount('');
    }
  };

  return <>
    <ThemeProvider theme={theme}>
      <BootstrapDialog open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          GUTHABEN AUFLADEN
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
            // color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography variant="body1" gutterBottom align="left">
            Hier kannst du ganz einfach dein Guthaben aufladen.
            Wähle einen gewünschten Betrag und eine Bezahlmethode.
            Im Anschluss ist dein Guthaben sofort verfügbar!
          </Typography>
          <Typography variant="h6" gutterBottom mt={3}>
            Gib einen Betrag an:
          </Typography>
          <FormControl variant="outlined" color="success">
            <OutlinedInput
              id="creditAmount"
              autoFocus
              value={amount}
              onChange={(e) => {
                const userInput = e.target.value;
                const regex = /^[0-9]+([,.])?([0-9]{1,2})?$/;
                if (regex.test(userInput)) {
                  setAmount(userInput);
                }
              }}
              onKeyDown={handleKeyPress}
              placeholder="0,00"
              endAdornment={<InputAdornment position="end">€</InputAdornment>}
              inputProps={{
                'aria-label': 'euro',
                inputMode: 'numeric',
              }}
            />
          </FormControl>
          
          <Typography variant="h6" gutterBottom mt={3}>
            Bezahlung mit Kreditkarte
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <img src={VisaImg} alt="Visa" />
            </Grid>
            <Grid item>
              <img src={MastercardImg} alt="Mastercard" />
            </Grid>
            <Grid item>
              <img src={AmericanExpressImg} alt="Mastercard" />
            </Grid>
          </Grid>
          <Typography variant="caption" gutterBottom>
            Die Bezahlung erfolgt über einen externen Anbieter.
          </Typography>
          <Button variant="contained" color="yellow" onClick={onCreatePayment} fullWidth sx={{marginTop: "20px"}}>
            Jetzt aufladen
          </Button>
          {/* <Button onClick={onClose} color="success" fullWidth sx={{marginTop: "5px"}}>
            Schließen
          </Button> */}
        </DialogContent>
        
        {/* <DialogActions>
          <Button autoFocus onClick={onClose} fullWidth>
            Schließen
          </Button>
          <Button autoFocus onClick={onClose}>
            Schließen
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </ThemeProvider>
  </>
}