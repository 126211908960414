import { useState } from "react";
import { AutocompleteNameFields } from "./common/AutocompleteNameFields";
import { produce } from "immer";
import { useGetAutocompleteNames } from "../hooks/useGetAutocompleteNames";
import { AutocompleteAddressFields } from "./common/AutocompleteAddressFields";
import { AutocompleteBookingTextField, BookingTextField, ButtonAutocomplete, TypographySubheading } from "../common/StyledMuiComponents";
import { useGetAddressOptions } from "../hooks/useGetAddressOptions";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import useContent from "../hooks/useContent";
import dayjs from "dayjs";
import { isTokenExpired } from "../common/CheckToken";

interface StreetData {
  street: string;
  lat: number;
  lon: number;
}

interface AddressBlockProps {
  setGeoData: React.Dispatch<React.SetStateAction<{address: string, lat: number, lon: number}>>,
  chooseMapView: (addressType: string, options: StreetData[]) => void,
  // setToHomeDelivery: (addressType: string) => void,
  pickupEmailError: boolean,
  pickupEmailErrorMsg: string,
  deliveryEmailError: boolean,
  deliveryEmailErrorMsg: string,
}

export const AddressBlock: React.FC<AddressBlockProps> = (
  {
    setGeoData,
    chooseMapView,
    pickupEmailError,
    pickupEmailErrorMsg,
    deliveryEmailError,
    deliveryEmailErrorMsg
  }) => {
  const {getAutocompleteOptions: getFromOptions, options: fromOptions, setOptions: setFromOptions, addressNames: fromAddressNames} = useGetAddressOptions();
  const {getAutocompleteOptions: getToOptions, options: toOptions, setOptions: setToOptions, addressNames: toAddressNames} = useGetAddressOptions();
  const {getAutocompleteOptions: getFromName2, options: fromNameOptions, optionNames: fromNameOptionsName} = useGetAutocompleteNames();
  const {getAutocompleteOptions: getToName2, options: toNameOptions, optionNames: toNameOptionsName} = useGetAutocompleteNames();
  const [plzFromError, setPlzFromError] = useState<boolean>(false);
  const [plzFromErrorMsg, setPlzFromErrorMsg] = useState<string>("");
  const [plzToError, setPlzToError] = useState<boolean>(false);
  const [plzToErrorMsg, setPlzToErrorMsg] = useState<string>("");
  const content = useContent();

  const getFromName = (name: string) => !isTokenExpired() && getFromName2(name, 0);

  const getToName = (name: string) => !isTokenExpired() && getToName2(name, 1);

  const getAutocompleteFromOptions = (searchParam: string, plz: number) => getFromOptions(searchParam, plz);

  const getAutocompleteToOptions = (searchParam: string, plz: number) => getToOptions(searchParam, plz);

  const setToHomeDelivery = (addressType: string) => {
    if(addressType === "from") {
      content?.setSelectedFromBox(undefined);
      content?.setDeliveryDate(dayjs().add(1, "day"));
      content?.setMinDate(dayjs().add(1, "day"));
      content?.setOrder(prev => produce(prev, draft => {
        draft.packages[0].pickupType = 1;
        draft.fromLocationId = 0;
        draft.fromAddress = "";
        draft.packages[0].packageType = 4;
        draft.fromPLZ = null;
        draft.fromCity = "";
        draft.dateToDeliver = dayjs().add(1, "day").format('YYYY-MM-DD');
      }))
    } else {
      content?.setSelectedToBox(undefined);
      content?.setDeliveryDate(dayjs().add(1, "day"));
      content?.setMinDate(dayjs().add(1, "day"));
      content?.setOrder(prev => produce(prev, draft => {
        draft.packages[0].deliveryType = 1;
        draft.toLocationId = 0;
        draft.toAddress = "";
        draft.packages[0].packageType = 4;
        draft.toPLZ = null;
        draft.toCity = "";
        draft.dateToDeliver = dayjs().add(1, "day").format('YYYY-MM-DD');
      }))
    }
  }

  return <>
    <Grid item xs={12} mt={2}>
      <Paper sx={{marginLeft: "30px", marginRight: "30px"}} elevation={0}>
        <Grid container item spacing={2} pr={2} pb={2} pl={2}>

          <Grid container item xs={12} sm={6} spacing={1.5}>
            <Grid item xs={12}>
              <TypographySubheading>
                Absender
              </TypographySubheading>
            </Grid>

            <Grid item xs={12}>
              <AutocompleteNameFields 
                setSelectedBox={content?.setSelectedFromBox}
                optionsNamesString={fromNameOptionsName}
                options={fromNameOptions}
                getName={getFromName}
                idString="fromName"
                textLabel="Absender-Name"
                addressType="from"
              />
            </Grid>

            <Grid item xs={12}>
              <BookingTextField 
                label="Telefonnummer"
                fullWidth
                id="fromContactNumber"
                name="fromContactNumber"
                autoComplete="contactNumber"
                color="success"
                size="small"
                required
                value={content?.order.fromContactNumber}
                onChange={(e) => {
                  content?.setOrder(prev => produce(prev, draft => {
                    draft.fromContactNumber = e.target.value;
                  }));
                }}
              />
            </Grid>

            <Grid container item spacing={2}>
              <Grid item xs={12} sm={6}>
                <BookingTextField 
                  label="PLZ"
                  fullWidth
                  id="fromPLZ"
                  name="fromPLZ"
                  autoComplete="plz"
                  color="success"
                  size="small"
                  required
                  value={content?.order.fromPLZ || ""}
                  error={plzFromError}
                  helperText={plzFromError ? plzFromErrorMsg : ""}
                  onChange={(e) => {
                    plzFromError && setPlzFromError(false);
                    setPlzFromErrorMsg("");
                    content?.setOrder(prev => produce(prev, draft => {
                      draft.fromPLZ = parseInt(e.target.value, 10);
                    }));
                  }}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <BookingTextField 
                  label="Ort"
                  fullWidth
                  id="fromCity"
                  name="fromCity"
                  autoComplete="city"
                  color="success"
                  size="small"
                  required
                  value={content?.order.fromCity}
                  onChange={(e) => {
                    content?.setOrder(prev => produce(prev, draft => {
                      draft.fromCity = e.target.value;
                    }));
                  }}
                />
              </Grid>
            </Grid>

            {content?.order.packages[0].pickupType !== 0 ? 
              <Grid container item xs={12} visibility={content?.order.packages[0].pickupType === 0 ? "hidden" : "visible"}>
                <Grid item xs={12} sm={(content?.selectedOrderType === null || content?.selectedOrderType === 1) ? 9 : 12}>
                  <AutocompleteAddressFields 
                    setGeoData={setGeoData}
                    setPlzError={setPlzFromError}
                    setPlzErrorMsg={setPlzFromErrorMsg}
                    addressNames={fromAddressNames}
                    options={fromOptions}
                    setOptions={setFromOptions}
                    getAutocompleteOptions={getAutocompleteFromOptions}
                    idString="fromAddressAutocomplete"
                    textLabel="Absenderadresse"
                    addressType="from"
                  />
                </Grid>

                {(content?.selectedOrderType === null || content?.selectedOrderType === 1) ? (
                  <Grid item xs={12} sm={3}>
                    <ButtonAutocomplete 
                      variant="contained"
                      color="yellow"
                      disableElevation
                      disabled={fromOptions.length < 1}
                      fullWidth 
                      onClick={() => chooseMapView("from", fromOptions)}
                    >
                      Paketkasten auswählen
                    </ButtonAutocomplete>
                  </Grid>
                ):""}
              </Grid> : ""
            }

            {content?.order.packages[0].pickupType === 0 ?
              <Grid container item xs={12}>
                <Grid item xs={12} sm={(content?.selectedOrderType === null || content?.selectedOrderType === 1) ? 9 : 12}>
                  <AutocompleteBookingTextField 
                    label="Tamburi Kasten"
                    fullWidth
                    id="fromAddressText"
                    name="fromAddressText"
                    color="success"
                    multiline
                    rows={2}
                    size="small"
                    value={`Tamburi: ${content?.selectedFromBox?.locationName}\n${content?.selectedFromBox?.street} ${content?.selectedFromBox?.number}`}
                  />
                </Grid>

                {(content?.selectedOrderType === null || content?.selectedOrderType === 1) ? (
                  <Grid item xs={12} sm={3}>
                    <ButtonAutocomplete 
                      variant="contained"
                      color="yellow"
                      disableElevation
                      fullWidth 
                      onClick={() => setToHomeDelivery("from")}
                    >
                      Paketkasten abwählen
                    </ButtonAutocomplete>
                  </Grid>
                ):""}
              </Grid> : ""
            }
          </Grid>

          <Grid container item xs={12} sm={6} spacing={1.5}>
            <Grid item xs={12}>
              <TypographySubheading>
                Empfänger
              </TypographySubheading>
            </Grid>

            <Grid item xs={12}>
              <AutocompleteNameFields
                setSelectedBox={content?.setSelectedToBox}
                optionsNamesString={toNameOptionsName}
                options={toNameOptions}
                getName={getToName}
                idString="toName"
                textLabel="Empfänger-Name"
                addressType="to"
              />
            </Grid>

            <Grid item xs={12}>
              <BookingTextField
                label="Telefonnummer"
                fullWidth
                id="toContactNumber"
                name="toContactNumber"
                autoComplete="contactNumber"
                color="success"
                size="small"
                required
                value={content?.order.toContactNumber}
                onChange={(e) => {
                  content?.setOrder(prev => produce(prev, draft => {
                    draft.toContactNumber = e.target.value;
                  }));
                }}
              />
            </Grid>

            <Grid container item spacing={2}>
              <Grid item xs={12} sm={6}>
                <BookingTextField 
                  label="PLZ"
                  fullWidth
                  id="toPLZ"
                  name="toPLZ"
                  autoComplete="plz"
                  color="success"
                  size="small"
                  required
                  value={content?.order.toPLZ || ""}
                  error={plzToError}
                  helperText={plzToError ? plzToErrorMsg : ""}
                  onChange={(e) => {
                    setPlzToError(false);
                    setPlzToErrorMsg("");
                    content?.setOrder(prev => produce(prev, draft => {
                      draft.toPLZ = parseInt(e.target.value, 10);
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <BookingTextField
                  label="Ort"
                  fullWidth
                  id="toCity"
                  name="toCity"
                  autoComplete="city"
                  color="success"
                  size="small"
                  required
                  value={content?.order.toCity}
                  onChange={(e) => {
                    content?.setOrder(prev => produce(prev, draft => {
                      draft.toCity = e.target.value;
                    }));
                  }}
                />
              </Grid>
            </Grid>

            {content?.order.packages[0].deliveryType !== 0 ? 
              <Grid container item xs={12}>
                <Grid item xs={12} sm={(content?.selectedOrderType === null || content?.selectedOrderType === 1) ? 9 : 12}>
                  <AutocompleteAddressFields 
                    setGeoData={setGeoData}
                    setPlzError={setPlzToError}
                    setPlzErrorMsg={setPlzToErrorMsg}
                    addressNames={toAddressNames}
                    options={toOptions}
                    setOptions={setToOptions}
                    getAutocompleteOptions={getAutocompleteToOptions}
                    idString="toAddress"
                    textLabel="Zustelladresse"
                    addressType="to"
                  />
                </Grid>

                {(content?.selectedOrderType === null || content?.selectedOrderType === 1) ? (
                  <Grid item xs={12} sm={3}>
                    <ButtonAutocomplete 
                      variant="contained"
                      color="yellow"
                      disableElevation
                      disabled={toOptions.length < 1}
                      fullWidth 
                      onClick={() => chooseMapView("to", toOptions)}
                    >
                      Paketkasten auswählen
                    </ButtonAutocomplete>
                  </Grid>
                ):""}
              </Grid>
            : ""}

            {content?.order.packages[0].deliveryType === 0 ? 
              <Grid container item xs={12}>
                <Grid item xs={12} sm={(content?.selectedOrderType === null || content?.selectedOrderType === 1) ? 9 : 12}>
                  <AutocompleteBookingTextField 
                    label="Tamburi Kasten"
                    fullWidth
                    id="toAddressText"
                    name="toAddressText"
                    color="success"
                    size="small"
                    multiline
                    rows={2}
                    value={`Tamburi: ${content?.selectedToBox?.locationName}\n${content?.selectedToBox?.street} ${content?.selectedToBox?.number}`}
                  />
                </Grid>

                {(content?.selectedOrderType === null || content?.selectedOrderType === 1) ? (
                  <Grid item xs={12} sm={3}>
                    <ButtonAutocomplete 
                      variant="contained"
                      color="yellow"
                      disableElevation
                      fullWidth 
                      onClick={() => setToHomeDelivery("to")}
                    >
                      Paketkasten abwählen
                    </ButtonAutocomplete>
                  </Grid>
                ):""}
              </Grid>
            : ""}
          </Grid>

          <Grid item xs={6}>
            <BookingTextField
              label="Absender E-Mail"
              fullWidth
              id="pickupEmail"
              name="pickupEmail"
              autoComplete="email"
              color="success"
              size="small"
              required={content?.selectedOrderType === 3 ? true : false}
              value={content?.order.pickupEmail}
              onChange={(e) => {
                content?.setOrder(prev => produce(prev, draft => {
                  draft.pickupEmail = e.target.value;
                }));
              }}
              error={pickupEmailError}
              helperText={pickupEmailError ? pickupEmailErrorMsg : ""}
            />
          </Grid>

          <Grid item xs={6}>
            <BookingTextField
              label="Empfänger E-Mail"
              fullWidth
              id="deliveryEmail"
              name="deliveryEmail"
              autoComplete="email"
              color="success"
              size="small"
              required={content?.selectedOrderType === 2 ? true : false}
              value={content?.order.deliveryEmail}
              onChange={(e) => {
                content?.setOrder(prev => produce(prev, draft => {
                  draft.deliveryEmail = e.target.value;
                }));
              }}
              error={deliveryEmailError}
              helperText={deliveryEmailError ? deliveryEmailErrorMsg : ""}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <BookingTextField
              label="Referenznummer"
              fullWidth
              id="refOrderId"
              name="refOrderId"
              autoComplete="refOrderId"
              color="success"
              size="small"
              value={content?.order.refOrderId}
              onChange={(e) => {
                content?.setOrder(prev => produce(prev, draft => {
                  draft.refOrderId = e.target.value;
                }));
              }}
            />
          </Grid> */}

          <Grid item xs={12}>
            <BookingTextField
              label="Kommentar"
              fullWidth
              id="comment"
              name="comment"
              autoComplete="comment"
              color="success"
              size="small"
              value={content?.order.comment}
              onChange={(e) => {
                content?.setOrder(prev => produce(prev, draft => {
                  draft.comment = e.target.value;
                }));
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  </>
}