import { useState } from 'react';
import { Button, Container, Grid, Typography, FormControlLabel, CssBaseline, Box, RadioGroup, Radio, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { VerifyNeededPopup } from '../popups/VerifyNeededPopup';
import { StyledTextField } from '../common/StyledMuiComponents';
import useEmailValidation from '../hooks/useValidation';
import useAxios from '../hooks/useAxios';
import axiosOriginal from "axios";
import 'react-toastify/dist/ReactToastify.css';

export const Register = () => {
  const [registrationType, setRegistrationType] = useState('private');
  const [open, setOpen] = useState(false);
  const { error: emailError, errorMsg: emailErrorMsg, validateEmail } = useEmailValidation();
  const { error: passwordError, errorMsg: passwordErrorMsg, validatePassword } = useEmailValidation();
  const { error: uidError, errorMsg: uidErrorMsg, validateUIDNumber } = useEmailValidation();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const onSuccess = (data: any) => {
    setOpen(true);
  };

  const onFailure = (error: any) => {
    try{
      if(axiosOriginal.isAxiosError(error)) {
        if (error.response) {
          if(error.response.status === 401) {
            toast.error(error.response.data.error || "Unauthorized", {
              position: toast.POSITION.TOP_RIGHT
            });
            navigate('/login');
          } else if(error.response.status === 404) {
            toast.error(error.response.data.error || "404 - Not Found", {
              position: toast.POSITION.TOP_RIGHT
            });
          } else {
            if(error.response.data.errors) {
              error?.response?.data?.errors?.map((error: { code: string, description: string }) => {
                if(error.code === "DuplicateEmail"){
                  toast.error("Email ist bereits vergeben" , {
                    position: toast.POSITION.TOP_RIGHT
                  });
                }
                return null;
              });
            }
          }
        }
      }
    } catch(error: any) {
      toast.error(error.response.data.error || "Ein unerwarteter Fehler ist aufgetreten.", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const { isLoading, postData } = useAxios();

  const handleClose = () => setOpen(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get('email');
    if (!validateEmail(email as string)) {
      return;
    }

    const password = data.get('password');
    if (!validatePassword(password as string)) {
      return;
    }

    if(registrationType === "company") {
      const uidNumber = data.get("uid");
      if(!validateUIDNumber(uidNumber as string)) {
        return;
      }
    }

    let requestData;
    if(registrationType === "private") {
      requestData = ({
        firstName: data.get('firstName'),
        lastName: data.get('lastName'),
        email: data.get('email'),
        phoneNumber: data.get('phone'),
        address: data.get('address'),
        city: data.get('city'),
        plz: data.get('plz'),
        password: data.get('password'),
      });
    } else {
      requestData = ({
        firstName: data.get('firstName'),
        lastName: data.get('lastName'),
        email: data.get('email'),
        phoneNumber: data.get('phone'),
        address: data.get('address'),
        city: data.get('city'),
        plz: data.get('plz'),
        password: data.get('password'),
        company: {
          gisa: data.get('gisa'),
          uid: data.get('uid'),
          name: data.get('companyName')
        }
      });
    }

    postData("/auth/Register", { 'Content-Type': 'application/json' }, requestData, false, onSuccess, onFailure);
  };
  return <>
    <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <img style={{maxWidth:"150px", marginBottom:"20px"}} src="veloce.svg" alt="veloce logo"></img>
          <Typography component="h1" variant="h5">
            Registrierung
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <StyledTextField  
                  autoComplete="given-name"
                  name="firstName"
                  color="success"
                  required
                  fullWidth
                  id="firstName"
                  label="Vorname"
                  size="small"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField  
                  autoComplete="family-name"
                  name="lastName"
                  color="success"
                  required
                  fullWidth
                  id="lastName"
                  label="Nachname"
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField 
                margin="normal"
                required
                color="success"
                fullWidth
                name="address"
                id="address"
                label="Adresse"
                size="small"
                autoComplete="address"
              />
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  margin="normal"
                  required
                  fullWidth
                  color="success"
                  name="plz"
                  id="plz"
                  label="PLZ"
                  size="small"
                  autoComplete="plz"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  margin="normal"
                  required
                  fullWidth
                  color="success"
                  name="city"
                  id="city"
                  label="Ort"
                  size="small"
                  autoComplete="city"
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField 
                margin="normal"
                required
                fullWidth
                color="success"
                name="email"
                id="email"
                label="Email"
                size="small"
                autoComplete="email"
                value={email} onChange={(e) => setEmail(e.target.value)}
                error={emailError}
                helperText={emailError ? emailErrorMsg : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField 
                margin="normal"
                required
                fullWidth
                color="success"
                name="phone"
                id="phone"
                size="small"
                label="Telefonnummer"
                autoComplete="phone"
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField 
                margin="normal"
                required
                fullWidth
                color="success"
                name="password"
                id="password"
                label="Passwort"
                type="password"
                size="small"
                autoComplete="new-password"
                error={passwordError}
                helperText={passwordError ? passwordErrorMsg : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                name="registrationType"
                value={registrationType}
                onChange={(e) => setRegistrationType(e.target.value)}
                row
              >
                <FormControlLabel value="private" control={<Radio color="success"/>} label="Privat" />
                <FormControlLabel value="company" control={<Radio color="success"/>} label="Unternehmen" />
              </RadioGroup>
            </Grid>
            { registrationType === "company" && (
              <>
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    {/* <TextField 
                      margin="normal"
                      required
                      fullWidth
                      name="gisa"
                      id="gisa"
                      label="GISA"
                      autoComplete="gisa"
                    /> */}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <StyledTextField 
                      margin="normal"
                      required
                      fullWidth
                      color="success"
                      name="uid"
                      id="uid"
                      label="UID"
                      size="small"
                      autoComplete="uid"
                      error={uidError}
                      helperText={uidError ? uidErrorMsg : ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTextField 
                      margin="normal"
                      required
                      fullWidth
                      color="success"
                      name="companyName"
                      id="companyName"
                      label="Firmenname"
                      size="small"
                      autoComplete="companyName"
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="yellow"
              disabled={isLoading}
              sx={{mt: 3, mb: 2}}
              >
                { isLoading ? <CircularProgress size={32} color="inherit"/> :  "Registrieren"}
            </Button>
            <Grid container>
              <Grid item>
                <Link to="/login" replace={true} style={{textDecoration: "none", color: "#36454F"}}>
                  Du hast bereits ein Konto? Melde dich an
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <VerifyNeededPopup open={open} onClose={handleClose} email={email}></VerifyNeededPopup>
      </Container>
  </>
}