import React from 'react';
import Card from "@mui/material/Card"
import CardActionArea from "@mui/material/CardActionArea"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import useProfile from '../../hooks/useProfile';

interface OrderTypeBoxProps {
  id: number;
  content: string;
  selected: boolean;
  height: string,
  onClick: (id: number) => void;
}

export const OrderTypeBox: React.FC<OrderTypeBoxProps> = ({id, content, selected, height, onClick}) => {
  const profile = useProfile();
  return (
      <Card
        variant={'outlined'}
        onClick={() => onClick(id)}
        sx={{
          /* cursor: 'pointer',
          border: selected ? '2px solid black' : '1px solid rgba(0, 0, 0, 0.2)',
          borderColor: profile?.colorMode === "light" ? (selected ? "black" : "rgba(0, 0, 0, 0.2)") : (selected ? "#E0DA25" : "rgba(255, 255, 255, 0.23)"),
          color: profile?.colorMode === "light" ? "black" : (selected ? "#115427" : "white"),
          backgroundColor: profile?.colorMode === "light" ? (selected ? "#E0DA25" : "white") : (selected ? "#E0DA25" : "#121212"),
          boxShadow: 'none',
          height: "100%",
          justifyContent: "center",
          minHeight: height,
          ":hover": {
            color: profile?.colorMode === "light" ? "black" : "#115427",
            backgroundColor: "#E8E226"
          } */
          cursor: 'pointer',
          border: selected ? '2px solid black' : '1px solid rgba(0, 0, 0, 0.2)',
          borderColor: profile?.colorMode === "light" ? (selected ? "black" : "rgba(0, 0, 0, 0.2)") : (selected ? "#66BB6A" : "rgba(255, 255, 255, 0.23)"),
          color: profile?.colorMode === "light" ? "black" : (selected ? "#66BB6A" : "white"),
          // backgroundColor: profile?.colorMode === "light" ? "white" : "#121212",
          backgroundColor: profile?.colorMode === "light" ? (selected ? "#E8E226" : "white") : "#121212",
          boxShadow: 'none',
          height: "100%",
          justifyContent: "center",
          minHeight: height,
          ":hover": {
            color: profile?.colorMode === "light" ? "black" : "#66BB6A",
            backgroundColor: profile?.colorMode === "light" ? "#E8E226" : "#121212",
            borderColor: profile?.colorMode === "dark" ? "#66BB6A" : ""
          }
        }}
      >
        <CardActionArea sx={{height:"100%"}}>
          <CardContent>
            <Typography variant="body2" component="div" sx={{fontSize: "14px", fontWeight: "bold", fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif"}}>
              {content}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>

  );
};