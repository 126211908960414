import { UserFlags } from "../interfaces/UserFlags";
import { Booking } from "./Booking";
import { BookingOSTextil } from "./BookingOSTextil";
import useProfile from "../hooks/useProfile";

export const BookingLayout = () => {
  const profile = useProfile();
  /* const { open, onOpenPopup, onClosePopup } = usePopup(); */

  /* useEffect(() => {
    const hasSeenStartupSurvey = Cookies.get('startupSurvey');
    if (!hasSeenStartupSurvey) {
      onOpenPopup(PopupTypesEnum.survey);
      Cookies.set('startupSurvey', 'true', { expires: 365 });
    }
  }, []) */

  return <>
    { profile?.account.userFlags === UserFlags.OsTextil ?
      <BookingOSTextil/> : <Booking/>
    }
    {/* <SurveyPopup open={open.survey} onClose={() => onClosePopup(PopupTypesEnum.survey)}/> */}
  </>
}