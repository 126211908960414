import { useState, createContext, ReactNode, useContext } from "react";
import { PopupTypes, PopupTypesEnum } from "../interfaces/PopupTypes";

interface PopupContextProps {
  onOpenPopup: (popupType: PopupTypesEnum) => void;
  onClosePopup: (popupType: PopupTypesEnum) => void;
  open: PopupTypes
}

interface ProfileProviderProps {
  children: ReactNode;
}

const PopupContext = createContext<PopupContextProps | undefined>(undefined);

export const usePopup = (): PopupContextProps => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error('usePopup must be used within a PopupProvider');
  }
  return context;
};

export const PopupProvider: React.FC<ProfileProviderProps> = ({ children }) => {
  const [open, setOpen] = useState<PopupTypes>({
    creditNeeded: false,
    creditRecharge: false,
    verifyNeeded: false,
    map: false,
    cancelOrder: false,
    changeOrder: false,
    survey: false,
    redirectPayment: false,
    choosePaymentMethod: false,
    callVeloce: false,
    askUserToRegister: false
  })

  const onOpenPopup = (popupType: PopupTypesEnum) => {
    setOpen(prev => ({ ...prev, [popupType]: true }));
  };

  const onClosePopup = (popupType: PopupTypesEnum) => {
    setOpen(prev => ({ ...prev, [popupType]: false }));
  };

  const value = {
    onOpenPopup,
    onClosePopup,
    open
  };

  return (
    <PopupContext.Provider value={value}>
      {children}
    </PopupContext.Provider>
  );
};