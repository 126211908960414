export enum PopupTypesEnum {
  creditNeeded = "creditNeeded",
  creditRecharge = "creditRecharge",
  verifyNeeded = "verifyNeeded",
  map = "map",
  cancelOrder = "cancelOrder",
  changeOrder = "changeOrder",
  survey = "survey",
  redirectPayment = "redirectPayment",
  choosePaymentMethod = "choosePaymentMethod",
  callVeloce = "callVeloce",
  askUserToRegister = "askUserToRegister"
}

export interface PopupTypes {
  creditNeeded: boolean,
  creditRecharge: boolean,
  verifyNeeded: boolean,
  map: boolean,
  cancelOrder: boolean,
  changeOrder: boolean,
  survey: boolean,
  redirectPayment: boolean,
  choosePaymentMethod: boolean,
  callVeloce: boolean,
  askUserToRegister: boolean
}