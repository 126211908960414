import { RemoteLocation } from "../interfaces/UserData"
import useProfile from "../hooks/useProfile"
import React from "react"
import Container from "@mui/material/Container"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"

export const SavedBoxes = () => {
  const profile = useProfile()
  return <>
    <Container component="main">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography component="h1" variant="h5" sx={{float:"left"}}>
            Gespeicherte Paketkasten
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} mb={5}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {profile?.account?.savedRemoteLocations.map((value: RemoteLocation, index) => {
              return (
                <ListItem
                  key={index}
                  secondaryAction={
                    <div>
                      {/* <IconButton edge="end" aria-label="edit" onClick={onEditClick}>
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete">
                        <ClearIcon />
                      </IconButton> */}
                    </div>
                  }
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={() => {}} dense>
                    {/* <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={index === 0}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon> */}

                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography variant="h6" color="text.primary" gutterBottom>
                            { value.remoteLocationInfo.locationName }
                          </Typography>
                        </React.Fragment>}
                      secondary={
                        <React.Fragment>
                          <Grid container component="span" spacing={2}>
                            <Grid item component="span" xs={12} sm={4}>
                              <Typography variant="body1" color="text.primary" component="span">
                                {`${value.remoteLocationInfo.zipCode} ${value.remoteLocationInfo.cityName}`}<br></br>
                              </Typography>

                              <Typography variant="body1" color="text.primary" component="span">
                                {`${value.remoteLocationInfo.street} ${value.remoteLocationInfo.number}`}<br></br>
                              </Typography>

                              <Typography variant="body1" color="text.primary" component="span">
                                {`${value.remoteLocationInfo.countryName}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        
                        </React.Fragment>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </Container>
  </>
}