import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect } from "react";
import { Outlet } from "react-router-dom"
import useProfile from "../hooks/useProfile";
import "../../fonts/veloce_font.css";

// Augment the palette to include a yellow color
declare module '@mui/material/styles' {
  interface Palette {
    yellow: Palette['primary'];
  }

  interface PaletteOptions {
    yellow?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include a yellow option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    yellow: true;
  }
}

const Layout = () => {
  const profile = useProfile();

  useEffect(() => {
    const color: "dark" | "light" | null = localStorage.getItem('colorMode') as "dark" | "light" | null;
    profile?.setColorMode(color || "light")
  }, [])

const theme = createTheme({
    palette: {
      mode: profile?.colorMode,
      yellow: {
        main: '#E8E226',
        light: '#E8E226',
        dark: '#E8E226',
        contrastText: '#115427',
      },
      background: {
        default: `${profile?.colorMode === "light" ? "#F2F2F2" : "#121212"}`
        
      }
    },
    /* typography: {
      fontFamily: "sans-serif"
    }, */
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 0
          },
          input: {
            "&:-webkit-autofill": {
              WebkitBoxShadow: `0 0 0 100px ${profile?.colorMode === "light" ? "white" : "#121212"} inset`,
              WebkitTextFillColor: `${profile?.colorMode === "light" ? "black" : "white"}`,
            },
          },
        },
      },
    },
  });

  return (
    <main className="App">
      <ThemeProvider theme={theme}>
        <Outlet />
      </ThemeProvider>
    </main>
  )
}

export default Layout