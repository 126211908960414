import { useState } from "react";
import { AutocompleteNameFields } from "./common/AutocompleteNameFields";
import { produce } from "immer";
import { useGetAutocompleteNames } from "../hooks/useGetAutocompleteNames";
import { RememberedStores } from "../interfaces/UserData";
import { AutocompleteAddressFields } from "./common/AutocompleteAddressFields";
import { AutocompleteBookingTextField, BookingTextField, ButtonAutocomplete, TypographySubheading } from "../common/StyledMuiComponents";
import { useGetAddressOptions } from "../hooks/useGetAddressOptions";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import useAxios from "../hooks/useAxios";
import useContent from "../hooks/useContent";
import dayjs from "dayjs";

interface AddressBlockProps {
  setGeoData: React.Dispatch<React.SetStateAction<{address: string, lat: number, lon: number}>>,
  hangingClothes: boolean,
  chooseMapView: (addressType: string) => void,
  pickupEmailError: boolean,
  pickupEmailErrorMsg: string,
  deliveryEmailError: boolean,
  deliveryEmailErrorMsg: string,
}

export const AddressBlockOSTextil: React.FC<AddressBlockProps> = (
  {
    setGeoData,
    hangingClothes,
    chooseMapView,
    pickupEmailError,
    pickupEmailErrorMsg,
    deliveryEmailError,
    deliveryEmailErrorMsg
  }) => {
  const {getAutocompleteOptions: getFromOptions, options: fromOptions, setOptions: setFromOptions, addressNames: fromAddressNames} = useGetAddressOptions();
  const {getAutocompleteOptions: getToOptions, options: toOptions, setOptions: setToOptions, addressNames: toAddressNames} = useGetAddressOptions();
  const {getAutocompleteOptions: getFromName2, options: fromNameOptions, optionNames: fromNameOptionsName} = useGetAutocompleteNames();
  const {getAutocompleteOptions: getToName2, options: toNameOptions, optionNames: toNameOptionsName} = useGetAutocompleteNames();
  const { fetchData: findStoreByPLZ } = useAxios();
  const [plzFromError, setPlzFromError] = useState<boolean>(false);
  const [plzFromErrorMsg, setPlzFromErrorMsg] = useState<string>("");
  const [plzToError, setPlzToError] = useState<boolean>(false);
  const [plzToErrorMsg, setPlzToErrorMsg] = useState<string>("");
  const content = useContent();

/*   const onSwapAddress = () => {
    setOrder(prev => produce(prev, draft => {
      draft.orderInfo.fromName = prev.orderInfo.toName;
      draft.orderInfo.fromAddress = prev.orderInfo.toAddress;
      draft.orderInfo.fromPLZ = prev.orderInfo.toPLZ;
      draft.orderInfo.fromCity = prev.orderInfo.toCity;
      draft.orderInfo.fromContactNumber = prev.orderInfo.toContactNumber;
      draft.orderInfo.fromLocationId = prev.orderInfo.toLocationId;
      draft.orderInfo.toName = prev.orderInfo.fromName;
      draft.orderInfo.toAddress = prev.orderInfo.fromAddress;
      draft.orderInfo.toPLZ = prev.orderInfo.fromPLZ;
      draft.orderInfo.toCity = prev.orderInfo.fromCity;
      draft.orderInfo.toContactNumber = prev.orderInfo.fromContactNumber;
      draft.orderInfo.toLocationId = prev.orderInfo.fromLocationId;
    }))
  } */
  
  const setToHomeDelivery = (addressType: string) => {
    if(addressType === "from") {
      content?.setSelectedFromBox(undefined);
      content?.setDeliveryDate(dayjs().add(1, "day"));
      content?.setMinDate(dayjs().add(1, "day"));

      content?.setOrder(prev => produce(prev, draft => {
        draft.packages[0].pickupType = 1;
        draft.fromLocationId = 0;
        draft.fromAddress = "";
        draft.packages[0].packageType = 4;
        draft.fromPLZ = null;
        draft.fromCity = "";
        draft.dateToDeliver = dayjs().add(1, "day").format('YYYY-MM-DD');
      }))
    } else {
      content?.setSelectedToBox(undefined);
      content?.setDeliveryDate(dayjs().add(1, "day"));
      content?.setMinDate(dayjs().add(1, "day"));

      content?.setOrder(prev => produce(prev, draft => {
        draft.packages[0].deliveryType = 1;
        draft.toLocationId = 0;
        draft.toAddress = "";
        draft.packages[0].packageType = 4;
        draft.toPLZ = null;
        draft.toCity = "";
        draft.dateToDeliver = dayjs().add(1, "day").format('YYYY-MM-DD');
      }))
    }
  }

  const getFromName = (name: string) => getFromName2(name, 0);

  const getToName = (name: string) => getToName2(name, 1);

  const getAutocompleteFromOptions = (searchParam: string, plz: number) => getFromOptions(searchParam, plz);

  const getAutocompleteToOptions = (searchParam: string, plz: number) => getToOptions(searchParam, plz);

  const onFindStoreByPLZ = (value: string) => value !== "" ? findStoreByPLZ(`/Address/FindStoreByPLZ?plz=${value}`, {}, true, onSuccessFindStoreByPLZ, () => {}) : "";

  const onSuccessFindStoreByPLZ = (result: any) => {
    const stores: RememberedStores = result[0] as RememberedStores

    if(stores === null || stores === undefined) {
      return;
    }

    if(content?.selectedOrderType === 1) {
      content?.setOrder(prev => produce(prev, draft => {
        draft.toName = "OS Textil Reinigung";
        draft.toAddress = stores.address;
        draft.toContactNumber = stores.contactNumber;
        draft.toCity = stores.city;
        draft.toPLZ = stores.plz;
      }))
    } else if(content?.selectedOrderType === 2) {
      content?.setOrder(prev => produce(prev, draft => {
        draft.fromName = "OS Textil Reinigung";
        draft.fromAddress = stores.address;
        draft.fromCity = stores.city;
        draft.fromContactNumber = stores.contactNumber;
        draft.fromPLZ = stores.plz;
      }))
    }
  }

  return <>
    <Grid item xs={12} mt={2}>
      <Paper sx={{marginLeft: "30px", marginRight: "30px"}} elevation={0}>
        <Grid container item spacing={2} pr={2} pb={2} pl={2}>
          {/* <Grid container item xs={12} textAlign="center" justifyContent="center">
            <Typography sx={{alignSelf: "center", fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif", fontWeight:"700", fontSize: "16px"}} color="#115427">
              Absender
            </Typography>
            <IconButton onClick={onSwapAddress}>
              <SwapHorizIcon/>
            </IconButton>
            <Typography sx={{alignSelf: "center", fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif", fontWeight:"700", fontSize: "16px"}} color="#115427">
              Empfänger
            </Typography>
          </Grid> */}

          <Grid container item xs={12} sm={6} spacing={1.5}>
            <Grid item xs={12}>
              <TypographySubheading>
                Absender
              </TypographySubheading>
            </Grid>

            <Grid item xs={12}>
              <AutocompleteNameFields 
                setSelectedBox={content?.setSelectedFromBox}
                optionsNamesString={fromNameOptionsName}
                options={fromNameOptions}
                getName={getFromName}
                idString="fromName"
                textLabel="Absender-Name"
                addressType="from"
              />
            </Grid>

            <Grid item xs={12}>
              <BookingTextField 
                label="Telefonnummer"
                fullWidth
                id="fromContactNumber"
                name="fromContactNumber"
                autoComplete="contactNumber"
                color="success"
                size="small"
                required={content?.selectedOrderType === 1 || content?.selectedOrderType === 2 ? false : true}
                value={content?.order.fromContactNumber}
                onChange={(e) => {
                  content?.setOrder(prev => produce(prev, draft => {
                    draft.fromContactNumber = e.target.value;
                  }));
                }}
              />
            </Grid>

            <Grid container item spacing={2}>
              <Grid item xs={12} sm={6}>
                <BookingTextField 
                  label="PLZ"
                  fullWidth
                  id="fromPLZ"
                  name="fromPLZ"
                  autoComplete="plz"
                  color="success"
                  size="small"
                  required
                  value={content?.order.fromPLZ || ""}
                  error={plzFromError}
                  helperText={plzFromError ? plzFromErrorMsg : ""}
                  onChange={(e) => {
                    setPlzFromError(false);
                    setPlzFromErrorMsg("");
                    content?.setOrder(prev => produce(prev, draft => {
                      draft.fromPLZ = parseInt(e.target.value, 10);
                    }));
                  }}
                  onBlur={(e) => {
                    (content?.selectedOrderType === 1) && onFindStoreByPLZ(e.target.value);
                  }}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <BookingTextField 
                  label="Ort"
                  fullWidth
                  id="fromCity"
                  name="fromCity"
                  autoComplete="city"
                  color="success"
                  size="small"
                  required
                  value={content?.order.fromCity}
                  onChange={(e) => {
                    content?.setOrder(prev => produce(prev, draft => {
                      draft.fromCity = e.target.value;
                    }));
                  }}
                />
              </Grid>
            </Grid>

            {content?.order.packages[0].pickupType !== 0 ? 
              <Grid container item xs={12} visibility={content?.order.packages[0].pickupType === 0 ? "hidden" : "visible"}>
                <Grid item xs={12} sm={((content?.selectedOrderType === null || content?.selectedOrderType === 3) && !hangingClothes) ? 9 : 12}>
                  <AutocompleteAddressFields 
                    setGeoData={setGeoData}
                    setPlzError={setPlzFromError}
                    setPlzErrorMsg={setPlzFromErrorMsg}
                    addressNames={fromAddressNames}
                    options={fromOptions}
                    setOptions={setFromOptions}
                    getAutocompleteOptions={getAutocompleteFromOptions}
                    idString="fromAddressAutocomplete"
                    textLabel="Absenderadresse"
                    addressType="from"
                  />
                </Grid>

                {((content?.selectedOrderType === null || content?.selectedOrderType === 3) && !hangingClothes) ? (
                  <Grid item xs={12} sm={3}>
                    <ButtonAutocomplete 
                      variant="contained"
                      color="yellow"
                      disableElevation
                      fullWidth 
                      onClick={() => chooseMapView("from")}
                    >
                      Paketkasten auswählen
                    </ButtonAutocomplete>
                  </Grid>
                ):""}
              </Grid> : ""
            }

            {content?.order.packages[0].pickupType === 0 ?
              <Grid container item xs={12}>
                <Grid item xs={12} sm={((content?.selectedOrderType === null || content?.selectedOrderType === 3) && !hangingClothes) ? 9 : 12}>
                  <AutocompleteBookingTextField 
                    label="Tamburi Kasten"
                    fullWidth
                    id="fromAddressText"
                    name="fromAddressText"
                    color="success"
                    multiline
                    rows={2}
                    size="small"
                    value={`Tamburi: ${content?.selectedFromBox?.locationName}\n${content?.selectedFromBox?.street} ${content?.selectedFromBox?.number}`}
                  />
                </Grid>

                {((content?.selectedOrderType === null || content?.selectedOrderType === 3) && !hangingClothes) ? (
                  <Grid item xs={12} sm={3}>
                    <ButtonAutocomplete 
                      variant="contained"
                      color="yellow"
                      disableElevation
                      fullWidth 
                      onClick={() => setToHomeDelivery("from")}
                    >
                      Paketkasten abwählen
                    </ButtonAutocomplete>
                  </Grid>
                ):""}
              </Grid> : ""
            }

            <Grid item xs={12}>
              <BookingTextField
                label="Stiege/Stock/Türnummer"
                fullWidth
                id="fromAddressSupplement"
                name="fromAddressSupplement"
                autoComplete="fromAddressSupplement"
                color="success"
                size="small"
                disabled={content?.order.packages[0].pickupType === 0}
                value={content?.order.fromAddressSupplement}
                onChange={(e) => {
                  content?.setOrder(prev => produce(prev, draft => {
                    draft.fromAddressSupplement = e.target.value;
                  }));
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <BookingTextField
                label="Absender E-Mail"
                fullWidth
                id="pickupEmail"
                name="pickupEmail"
                autoComplete="email"
                color="success"
                size="small"
                required={content?.selectedOrderType === 3 ? true : false}
                value={content?.order.pickupEmail}
                onChange={(e) => {
                  content?.setOrder(prev => produce(prev, draft => {
                    draft.pickupEmail = e.target.value;
                  }));
                }}
                error={pickupEmailError}
                helperText={pickupEmailError ? pickupEmailErrorMsg : ""}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} sm={6} spacing={1.5}>
            <Grid item xs={12}>
              <TypographySubheading>
                Empfänger
              </TypographySubheading>
            </Grid>

            <Grid item xs={12}>
              <AutocompleteNameFields
                setSelectedBox={content?.setSelectedToBox}
                optionsNamesString={toNameOptionsName}
                options={toNameOptions}
                getName={getToName}
                idString="ToName"
                textLabel="Empfänger-Name"
                addressType="to"
              />
            </Grid>

            <Grid item xs={12}>
              <BookingTextField
                label="Telefonnummer"
                fullWidth
                id="toContactNumber"
                name="toContactNumber"
                autoComplete="contactNumber"
                color="success"
                size="small"
                required={content?.selectedOrderType === 1 || content?.selectedOrderType === 2 ? false : true}
                value={content?.order.toContactNumber}
                onChange={(e) => {
                  content?.setOrder(prev => produce(prev, draft => {
                    draft.toContactNumber = e.target.value;
                  }));
                }}
              />
            </Grid>

            <Grid container item spacing={2}>
              <Grid item xs={12} sm={6}>
                <BookingTextField 
                  label="PLZ"
                  fullWidth
                  id="toPLZ"
                  name="toPLZ"
                  autoComplete="plz"
                  color="success"
                  size="small"
                  required
                  value={content?.order.toPLZ || ""}
                  error={plzToError}
                  helperText={plzToError ? plzToErrorMsg : ""}
                  onChange={(e) => {
                    setPlzToError(false);
                    setPlzToErrorMsg("");
                    content?.setOrder(prev => produce(prev, draft => {
                      draft.toPLZ = parseInt(e.target.value, 10);
                    }));
                  }}
                  onBlur={(e) => {
                    (content?.selectedOrderType === 2) && onFindStoreByPLZ(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <BookingTextField
                  label="Ort"
                  fullWidth
                  id="toCity"
                  name="toCity"
                  autoComplete="city"
                  color="success"
                  size="small"
                  required
                  value={content?.order.toCity}
                  onChange={(e) => {
                    content?.setOrder(prev => produce(prev, draft => {
                      draft.toCity = e.target.value;
                    }));
                  }}
                />
              </Grid>
            </Grid>

            {content?.order.packages[0].deliveryType !== 0 ? 
              <Grid container item xs={12}>
                <Grid item xs={12} sm={((content?.selectedOrderType === null || content?.selectedOrderType === 4) && !hangingClothes) ? 9 : 12}>
                  <AutocompleteAddressFields 
                    setGeoData={setGeoData}
                    setPlzError={setPlzToError}
                    setPlzErrorMsg={setPlzToErrorMsg}
                    addressNames={toAddressNames}
                    options={toOptions}
                    setOptions={setToOptions}
                    getAutocompleteOptions={getAutocompleteToOptions}
                    idString="toAddress"
                    textLabel="Zustelladresse"
                    addressType="to"
                  />
                </Grid>

                {((content?.selectedOrderType === null || content?.selectedOrderType === 4) && !hangingClothes) ? (
                  <Grid item xs={12} sm={3}>
                    <ButtonAutocomplete 
                      variant="contained"
                      color="yellow"
                      disableElevation
                      fullWidth 
                      onClick={() => chooseMapView("to")}
                    >
                      Paketkasten auswählen
                    </ButtonAutocomplete>
                  </Grid>
                ):""}
              </Grid>
            : ""}

            {content?.order.packages[0].deliveryType === 0 ? 
              <Grid container item xs={12}>
                <Grid item xs={12} sm={((content?.selectedOrderType === null || content?.selectedOrderType === 4) && !hangingClothes) ? 9 : 12}>
                  <AutocompleteBookingTextField 
                    label="Tamburi Kasten"
                    fullWidth
                    id="toAddressText"
                    name="toAddressText"
                    color="success"
                    size="small"
                    multiline
                    rows={2}
                    value={`Tamburi: ${content?.selectedToBox?.locationName}\n${content?.selectedToBox?.street} ${content?.selectedToBox?.number}`}
                  />
                </Grid>

                {((content?.selectedOrderType === null || content?.selectedOrderType === 4) && !hangingClothes) ? (
                  <Grid item xs={12} sm={3}>
                    <ButtonAutocomplete 
                      variant="contained"
                      color="yellow"
                      disableElevation
                      fullWidth 
                      onClick={() => setToHomeDelivery("to")}
                    >
                      Paketkasten abwählen
                    </ButtonAutocomplete>
                  </Grid>
                ):""}
              </Grid>
            : ""}

            <Grid item xs={12}>
              <BookingTextField
                label="Stiege/Stock/Türnummer"
                fullWidth
                id="toAddressSupplement"
                name="toAddressSupplement"
                autoComplete="toAddressSupplement"
                color="success"
                size="small"
                disabled={content?.order.packages[0].deliveryType === 0}
                value={content?.order.toAddressSupplement}
                onChange={(e) => {
                  content?.setOrder(prev => produce(prev, draft => {
                    draft.toAddressSupplement = e.target.value;
                  }));
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <BookingTextField
                label="Empfänger E-Mail"
                fullWidth
                id="deliveryEmail"
                name="deliveryEmail"
                autoComplete="email"
                color="success"
                size="small"
                required={content?.selectedOrderType === 4 ? true : false}
                value={content?.order.deliveryEmail}
                onChange={(e) => {
                  content?.setOrder(prev => produce(prev, draft => {
                    draft.deliveryEmail = e.target.value;
                  }));
                }}
                error={deliveryEmailError}
                helperText={deliveryEmailError ? deliveryEmailErrorMsg : ""}
              />
            </Grid>
            
          </Grid>

          {/* {((selectedOrderType === null || selectedOrderType === 3 || selectedOrderType === 4) && !hangingClothes) ? (
            <Grid item xs={12}>
              <BookingTextField
                label="Empfänger E-Mail"
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                color="success"
                size="small"
                required
                value={newPackage.orderInfo.endemail}
                onChange={(e) => {
                  setNewPackage(prev => produce(prev, draft => {
                    draft.orderInfo.endemail = e.target.value;
                  }));
                }}
                error={emailError}
                helperText={emailError ? emailErrorMsg : ""}
              />
            </Grid>
          ):""} */}

          {/* <Grid item xs={12}>
            <BookingTextField
              label="Empfänger E-Mail"
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              color="success"
              size="small"
              required={selectedOrderType === 3 || selectedOrderType === 4 ? true : false}
              value={order.orderInfo.endemail}
              onChange={(e) => {
                setOrder(prev => produce(prev, draft => {
                  draft.orderInfo.endemail = e.target.value;
                }));
              }}
              error={emailError}
              helperText={emailError ? emailErrorMsg : ""}
            />
          </Grid> */}

          <Grid item xs={12} sm={6}>
            <BookingTextField
              label="OS Referenznummer"
              fullWidth
              id="refOrderId"
              name="refOrderId"
              autoComplete="refOrderId"
              color="success"
              size="small"
              value={content?.order.refOrderId}
              onChange={(e) => {
                content?.setOrder(prev => produce(prev, draft => {
                  draft.refOrderId = e.target.value;
                }));
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <BookingTextField
              label="Kommentar"
              fullWidth
              id="comment"
              name="comment"
              autoComplete="comment"
              color="success"
              size="small"
              value={content?.order.comment}
              onChange={(e) => {
                content?.setOrder(prev => produce(prev, draft => {
                  draft.comment = e.target.value;
                }));
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  </>
}