import { useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie-consent";
import axios from "../api/axios";
import useValidation from "../hooks/useValidation";
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import LockIcon from '@mui/icons-material/Lock';

export const PasswordReset = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const { error: passwordError, errorMsg: passwordErrorMsg, validatePassword } = useValidation();

  const handlePasswordReset = async () => {
    const params = new URLSearchParams(location.search);
    const token = (params.get("token") || "").replace(/ /g, "+");
    const user = params.get("p");

    if (!validatePassword(newPassword)) {
      return;
    }

    setLoading(true);
    
    if(token) {
      const requestData = ({
        NewPassword: newPassword,
        Token: token,
        Id: user
      });

      try {
        const bearerToken = Cookies.get('token');
        await axios.post("/user/resetpassword", requestData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearerToken}`
          }
        });
        toast.success("Passwort wurde erfolgreich geändert", {
          position: toast.POSITION.TOP_RIGHT
        });
      } catch (error: any) {
        toast.error(error.response.data.error || "Bestätigungtoken ist abgelaufen", {
          position: toast.POSITION.TOP_RIGHT
        });
      }

      Cookies.remove('token', { expires: 1 });
      navigate("/login")
    }
    setLoading(false);
  }
  
  return <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LockIcon style={{fontSize: "72px"}}/>
            </Grid>

            <Grid item xs={12}>
              <Typography component="h1" variant="h5">
                Passwort zurücksetzen
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography component="h1" variant="body2">
                Geben Sie Ihr neues Passwort ein. Das Passwort muss mindestenes 8 Zeichen lang sein, 
                einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField 
                margin="normal"
                required
                fullWidth
                id="newPassword"
                label="Neues Passwort"
                color="success"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                error={passwordError}
                helperText={passwordError ? passwordErrorMsg : ""}
                autoComplete="newPassword"
                autoFocus
              />
            </Grid>

            <Grid item xs={12} sm={0}>
              <Button
                onClick={handlePasswordReset}
                fullWidth
                variant="contained"
                color="yellow"
                sx={{ mt: 3, mb: 2 }}
                >
                  { loading ? <CircularProgress color="inherit"/> :  "Ändern"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
  </>
}