import { ThemeProvider, styled } from '@mui/material/styles';
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { TypographyPlus } from '../common/StyledMuiComponents';
import useProfile from "../hooks/useProfile";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

interface ChoosePaymentMethodProps {
  open: boolean,
  loading: boolean,
  onClose: () => void
  onConfirmPayment: (useCredit: boolean) => void
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const ChoosePaymentMethod: React.FC<ChoosePaymentMethodProps> = ({onConfirmPayment, open, onClose, loading}) => {
  const [useCredit, setUseCredit] = useState<boolean | null>(null);
  const [agbChecked, setAgbChecked] = useState(false);
  const profile = useProfile();
  const theme = useTheme();

  const handleAgbChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgbChecked(event.target.checked);
  };

  return <>
    <ThemeProvider theme={theme}>
      <BootstrapDialog open={open}>
        {/* <DialogTitle sx={{ m: 0, p: 2 }} bgcolor="#fff" color="#115427">
          Zahlungsmethode wählen
        </DialogTitle> */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TypographyPlus variant="body1" gutterBottom align="left">
                Wähle deine gewünschte Zahlungsmethode aus:
              </TypographyPlus>
            </Grid>

            <Grid item xs={6}>
              <Card
                variant='outlined'
                onClick={() => setUseCredit(true)}
                sx={{
                  cursor: 'pointer',
                  border: useCredit ? '2px solid black' : '1px solid rgba(0, 0, 0, 0.2)',
                  borderColor: profile?.colorMode === "light" ? (useCredit ? "black" : "rgba(0, 0, 0, 0.2)") : (useCredit ? "#66BB6A" : "rgba(255, 255, 255, 0.23)"),
                  color: profile?.colorMode === "light" ? "black" : (useCredit ? "#66BB6A" : "white"),
                  backgroundColor: profile?.colorMode === "light" ? (useCredit ? "#E8E226" : "white") : "#121212",
                  boxShadow: 'none',
                  ":hover": {
                    color: profile?.colorMode === "light" ? "black" : "#66BB6A",
                    backgroundColor: profile?.colorMode === "light" ? "#E8E226" : "#121212",
                    borderColor: profile?.colorMode === "dark" ? "#66BB6A" : ""
                  }
                }}
              >
                <CardActionArea>
                  <CardContent>
                    <TypographyPlus gutterBottom textAlign="center">
                      Mit Guthaben bezahlen
                    </TypographyPlus>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Card
                variant='outlined'
                onClick={() => setUseCredit(false)}
                sx={{
                  cursor: 'pointer',
                  border: useCredit === false ? '2px solid black' : '1px solid rgba(0, 0, 0, 0.2)',
                  borderColor: profile?.colorMode === "light" ? (useCredit === false ? "black" : "rgba(0, 0, 0, 0.2)") : (useCredit === false ? "#66BB6A" : "rgba(255, 255, 255, 0.23)"),
                  color: profile?.colorMode === "light" ? "black" : (useCredit === false ? "#66BB6A" : "white"),
                  backgroundColor: profile?.colorMode === "light" ? (useCredit === false ? "#E8E226" : "white") : "#121212",
                  boxShadow: 'none',
                  ":hover": {
                    color: profile?.colorMode === "light" ? "black" : "#66BB6A",
                    backgroundColor: profile?.colorMode === "light" ? "#E8E226" : "#121212",
                    borderColor: profile?.colorMode === "dark" ? "#66BB6A" : ""
                  }
                }}
              >
                <CardActionArea>
                  <CardContent>
                    <TypographyPlus gutterBottom textAlign="center">
                      Mit Kreditkarte bezahlen
                    </TypographyPlus>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={agbChecked} onChange={handleAgbChange} color="success"/>}
                label={
                  <span style={{fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif", fontSize:"14px"}}>
                    Ich habe die{' '}
                    <Link to="agb" target="_blank" rel="noopener noreferrer">
                      AGB
                    </Link>{' '}
                    gelesen und bin mit ihnen einverstanden.
                  </span>
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Button 
                variant="contained"
                color="yellow"
                disabled={!agbChecked || useCredit === null || loading}
                onClick={() => onConfirmPayment(useCredit || false)}
                fullWidth
                sx={{marginTop: "20px"}}
              >
                { loading ? <CircularProgress size={24} color="inherit"/> :  "Weiter"} 
              </Button>
              <Button onClick={onClose} color="success" fullWidth sx={{marginTop: "5px"}}>
                Schließen
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </ThemeProvider>
  </>
}