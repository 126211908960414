import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from "react-router-dom";
import "../../styles/CookieBanner.css";

export const CookieBanner = () => {
  const handleAcceptAll = () => {
    Cookies.set("cookieconsent_mode", "true");
    Cookies.set("cookieconsent_status", "true");
  };

  const handleAcceptNecessary = () => {
    Cookies.set("cookieconsent_mode", "true");
    Cookies.set("cookieconsent_status", "true");
  }

  return (
    <CookieConsent
      location="bottom"
      cookieName="cookieconsent_mode"
      buttonText="Zustimmen"
      /* declineButtonText="Nur erforderliche Cookies erlauben"
      enableDeclineButton */
      onAccept={handleAcceptAll}
      onDecline={handleAcceptNecessary}
      containerClasses="bannerContainer"
      style={{ background: '#fff', color: "#777"}}
      contentClasses="bannerContent"
      buttonClasses="bannerButton"
      declineButtonClasses="bannerButton"
      disableStyles
      disableButtonStyles
      buttonWrapperClasses="buttonWrapperClass"
      /* overlay */
    >
      <p>
        {/* Diese Website verwendet Cookies, um Ihr Erlebnis zu verbessern. Indem Sie auf "Alle Cookies erlauben" klicken, akzeptieren Sie die 
        Verwendung unserer Cookies. */}
        Cookies ermöglichen eine bestmögliche Bereitstellung unserer Dienste. Mit der Nutzung von veloce.at erklären Sie sich damit einverstanden, dass wir Cookies verwenden. {" "}
        {/* <Link className="bannerLink" to="/privacyPolicy">Datenschutzerklärung</Link> |  */}
        <Link className="bannerLink" to="/imprint#privacyPolicy">Cookie Policy</Link>
      </p>
    </CookieConsent>
  );
}