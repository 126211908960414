import { useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie-consent";
import axios from "../api/axios";
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Container from "@mui/material/Container"
import CssBaseline from "@mui/material/CssBaseline"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import EmailIcon from '@mui/icons-material/Email';
import useValidation from "../hooks/useValidation";

export const ChangeEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const { error: emailError, errorMsg: emailErrorMsg, validateEmail } = useValidation();

  const handleEmailChange = async () => {
    const params = new URLSearchParams(location.search);
    const token = (params.get("token") || "").replace(/ /g, "+");

    if (!validateEmail(newEmail)) {
      return;
    }

    setLoading(true);
    
    if(token) {
      const requestData = ({
        NewEmail: newEmail,
        Token: token,
      });

      try {
        const bearerToken = Cookies.get('token');
        await axios.post("/user/changeemail", requestData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearerToken}`
          }
        });
        toast.success("Email wurde erfolgreich geändert", {
          position: toast.POSITION.TOP_RIGHT
        });
      } catch (error: any) {
        toast.error(error.response.data.error || "Bestätigungtoken ist abgelaufen", {
          position: toast.POSITION.TOP_RIGHT
        });
      }

      navigate("/profile/personalData")
    }
    setLoading(false);
  }
  
  return <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <EmailIcon style={{fontSize: "72px"}}/>
            </Grid>

            <Grid item xs={12}>
              <Typography component="h1" variant="h5">
                E-Mail ändern
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography component="h1" variant="body2">
                Geben Sie eine neue gültige E-Mail-Adresse ein.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField 
                margin="normal"
                required
                fullWidth
                id="newEmail"
                label="Neue Email"
                type="email"
                color="success"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                error={emailError}
                helperText={emailError ? emailErrorMsg : ""}
                autoComplete="newEmail"
                autoFocus
              />
            </Grid>

            <Grid item xs={12} sm={0}>
              <Button
                onClick={handleEmailChange}
                fullWidth
                variant="contained"
                color="yellow"
                sx={{ mt: 3, mb: 2 }}
                >
                  { loading ? <CircularProgress color="inherit"/> :  "Ändern"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
  </>
}