import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie-consent";
import axiosOriginal, { AxiosResponse } from "axios";
import axios from '../api/axios';

type HookReturnType = {
  isLoading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchData: (url: string, header: {}, defaultError: boolean, onSuccess: (data: any) => void, onFailure: (error: any) => void) => Promise<void>;
  postData: (url: string, header: {}, payload: any, defaultError: boolean, onSuccess: (data: any) => void, onFailure: (error: any) => void) => Promise<void>;
  putData: (url: string, header: {}, payload: any, defaultError: boolean, onSuccess: (data: any) => void, onFailure: (error: any) => void) => Promise<void>;
  deleteDataById: (url: string, defaultError: boolean, onSuccess: (data: any) => void, onFailure: (error: any) => void) => Promise<void>;
  postDataWithAC: (url: string, header: {}, payload: any, defaultError: boolean, onSuccess: (data: any) => void, onFailure: (error: any) => void) => Promise<void>;
};

const useAxios = (): HookReturnType => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const bearerToken = Cookies.get('token');
  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchData = async (url: string, header: {}, defaultError: boolean, onSuccess: (data: any) => void, onFailure: (error: any) => void): Promise<void> => {
    setLoading(true);

    const config = {
      headers: { Authorization: `Bearer ${bearerToken}` ,
      ...Object.keys(header).length !== 0 && header}
    };

    try {
      const response: AxiosResponse = await axios.get(url, config);
      onSuccess(response.data);
    } catch (error: any) {
      onFailure(error);
      errorHandling(error, defaultError);
    } finally {
      setLoading(false);
    }
  };

  const postData = async (url: string, header: {}, payload: any, defaultError: boolean, onSuccess: (data: any) => void, onFailure: (error: any) => void): Promise<void> => {
    setLoading(true);

    const config = {
      headers: { Authorization: `Bearer ${bearerToken}` ,
      ...Object.keys(header).length !== 0 && header}
    };

    try {
      const response: AxiosResponse = await axios.post(url, payload, config);
      onSuccess(response.data);
    } catch (error) {
      onFailure(error);
      errorHandling(error, defaultError);
    } finally {
      setLoading(false);
    }
  };

  const putData = async (url: string, header: {}, payload: any, defaultError: boolean , onSuccess: (data: any) => void, onFailure: (error: any) => void): Promise<void> => {
    setLoading(true);

    const config = {
      headers: { Authorization: `Bearer ${bearerToken}` ,
      ...Object.keys(header).length !== 0 && header}
    };
    
    try {
      const response: AxiosResponse = await axios.put(url, payload, config);
      onSuccess(response.data);
    } catch (error) {
      onFailure(error);
      errorHandling(error, defaultError);
    } finally {
      setLoading(false);
    }
  };

  const deleteDataById = async (url: string, defaultError: boolean, onSuccess: (data: any) => void, onFailure: (error: any) => void): Promise<void> => {
    setLoading(true);

    const config = {
      headers: { Authorization: `Bearer ${bearerToken}`}
    };

    try {
      const response: AxiosResponse = await axios.delete(url, config);
      onSuccess(response.data);
    } catch (error: any) {
      onFailure(error);
      errorHandling(error, defaultError);
    } finally {
      setLoading(false);
    }
  };

  const postDataWithAC = async (url: string, header: {}, payload: any, defaultError: boolean, onSuccess: (data: any) => void, onFailure: (error: any) => void): Promise<void> => {
    setLoading(true);

    // Abort any pending requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create new abort controller
      const newAbortController = new AbortController();
      abortControllerRef.current = newAbortController;

    const config = {
      headers: { Authorization: `Bearer ${bearerToken}` ,
      ...Object.keys(header).length !== 0 && header},
      signal: newAbortController.signal
    };

    try {
      const response: AxiosResponse = await axios.post(url, payload, config);
      onSuccess(response.data);
    } catch (error) {
      onFailure(error);
      errorHandling(error, defaultError);
    } finally {
      setLoading(false);
    }
  };

  const errorHandling = (error: any, defaultError: boolean) => {
    if(defaultError) {
      if(axiosOriginal.isAxiosError(error)) {
        if (error.response) {
          if(error.response.status === 401) {
            toast.error(error.response.data.error || "Benutzerauthentifizierung erforderlich. Bitte melden Sie sich erneut an.", {
              position: toast.POSITION.TOP_RIGHT
            });
            navigate('/login');
          } else if(error.response.status === 404) {
            toast.error(error.response.data.error || "404 - Not Found", {
              position: toast.POSITION.TOP_RIGHT
            });
          } else if (error.name === 'AbortError') {
          } else {
            toast.error(error.response.data.error || "Ein unerwarteter Fehler ist aufgetreten.", {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        }
      }
    }
  }

  return { isLoading, setLoading, fetchData, postData, putData, deleteDataById, postDataWithAC };
};

export default useAxios;