import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PopupTypesEnum } from "../interfaces/PopupTypes";
import { MapView } from "../maps/MapView";
import { CreditNeededPopup } from "../popups/CreditNeededPopup";
import { produce} from "immer"
import { usePopup } from "../context/PopupProvider";
import { ClientComponent } from "./common/Client";
import { ValueOfGoodComponent } from "./common/ValueOfGood";
import { PackageTypeComponent } from "./common/PackageType";
import { DatePicker } from "./common/DatePicker";
import { AddressBlock } from "./AddressBlock";
import { EmtyOrder } from "./NewPackageEmty";
import { TypographyHeading } from "../common/StyledMuiComponents";
import { OrderTypesComponent } from "./common/OrderTypes";
import { isTokenExpired } from "../common/CheckToken";
import { RedirectToPayment } from "../popups/RedirectToPayment";
import { ChoosePaymentMethod } from "../popups/ChoosePaymentMethod";
import { CallVelocePopup } from "../popups/CallVelocePopup";
import { AskUserToRegister } from "../popups/AskUserToRegister";
import axiosOriginal from "axios";
import useAxios from "../hooks/useAxios";
import useValidation from "../hooks/useValidation";
import useGetProfile from "../hooks/useGetProfile";
import useContent from "../hooks/useContent";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from "@mui/material/CircularProgress";
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import dayjs from "dayjs";
import 'react-toastify/dist/ReactToastify.css';

interface StreetData {
  street: string;
  lat: number;
  lon: number;
}

const OrderTypeData = [
  { id: 1, content: "Locker to Locker", height: "100"}
];

export const Booking = () => {
  const [geoData, setGeoData] = useState({address: "", lat: 0, lon: 0});
  const [isLoadingFinishOrder, setIsLoadingFinishOrder] = useState(false);
  const { open, onOpenPopup, onClosePopup } = usePopup();
  const { profile } = useGetProfile();
  const { validateEmail } = useValidation();
  const { error: pickupEmailError, errorMsg: pickupEmailErrorMsg, validateEmail: pickupEmailValidation } = useValidation();
  const { error: deliveryEmailError, errorMsg: deliveryEmailErrorMsg, validateEmail: deliveryEmailValidation } = useValidation();
  const { postData: postFinishOrder } = useAxios();
  const navigate = useNavigate();
  const content = useContent();

  useEffect(() => {
    content?.setOrder(prev => produce(prev, draft => {
      draft.client.name = profile?.account?.firstName || profile?.account?.lastName ? `${profile.account.firstName} ${profile.account.lastName}` : prev.client.name;
      draft.client.email = profile?.account?.email || prev.client.email;
      draft.client.phoneNumber =  profile?.account?.phoneNumber || prev.client.phoneNumber;
      draft.client.plz = profile?.account?.plz || prev.client.plz;
      draft.client.city = profile?.account?.city || prev.client.city;
      draft.client.address = profile?.account?.address || prev.client.address
    }))
  }, [profile]);

  const chooseMapView = (addressType: string, options: StreetData[]) => {
    const opt: {street: string, lat: number, lon: number}[] = options.filter(x => x.street === (addressType === "from" ? content?.order?.fromAddress : content?.order?.toAddress));

    if(options.length > 0) {
      setGeoData(prev => produce(prev, draft => {
        if(draft) {
          draft.lat = opt[0]?.lat || 0;
          draft.lon = opt[0]?.lon || 0;
        }
      }));
    }

    addressType === "from" ? content?.setIsFromAddress(true) : content?.setIsFromAddress(false);
    onOpenPopup(PopupTypesEnum.map);
  }

  const closeMapView = () => onClosePopup(PopupTypesEnum.map);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // console.log(newPackage)

    if (content?.selectedOrderType === null) {
      return;
    }

    if (content?.order?.deliveryEmail !== null && content?.order?.deliveryEmail !== "" && !deliveryEmailValidation(content?.order?.deliveryEmail || "")) {
      return;
    }

    if (content?.order?.pickupEmail !== null && content?.order?.pickupEmail !== "" && !pickupEmailValidation(content?.order?.pickupEmail || "")) {
      return;
    }

    if (!validateEmail(content?.order?.client?.email || "")) {
      return;
    }

    /* if (!endemailValidation(order.orderInfo?.endemail)) {
      return;
    } */

    if (content?.order?.packages[0].valueOfGood === null || content?.order?.packages[0].valueOfGood === 0) {
      toast.error("Es muss ein Warenwert eingegeben werden!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    if(content?.order.packages[0].packageType === 4) {
      toast.error("Bitte wählen Sie eine Paketgröße aus!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    if(!content?.order.dateToDeliver) {
      toast.error("Lieferdatum ist erforderlich!", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    isTokenExpired() ? onOpenPopup(PopupTypesEnum.askUserToRegister) : onOpenPopup(PopupTypesEnum.choosePaymentMethod);
  }

  const handleOrderTypeClick = (id: number) => {
    if (id === 1) {
      content?.setDeliveryDate(dayjs().add(1, "day"));
      content?.setMinDate(dayjs().add(1, "day"));
      content?.setOrder(prev => produce(prev, draft => {
        draft.dateToDeliver = dayjs().add(1, "day").format('YYYY-MM-DD'); // Add 1 day?
        draft.orderFlags = 32; // LockerToLocker
        draft.packages[0].pickupType = 1; // Veloce
        draft.packages[0].deliveryType = 1; // Veloce
        draft.packages[0].packageType = 4; // Extra
      }))
    }
  }

  //
  // BOOK ORDER START
  //
  const onSuccessFinishOrder = (response: any) => {
    onOpenPopup(PopupTypesEnum.redirectPayment)
    window.location.href = response;
    setIsLoadingFinishOrder(false);
    content?.setOrder(EmtyOrder);
    content?.setSelectedTimeframe(null);
    content?.setSelectedOrderType(null);
    content?.setSelectedFromBox(undefined);
    content?.setSelectedToBox(undefined);
  }

  const onFailure = (error: any) => {
    if(axiosOriginal.isAxiosError(error)) {
      if (error.response) {
        if(error.response.status === 401) {
          toast.error(error.response.data.error || "Benutzerauthentifizierung erforderlich. Bitte melden Sie sich erneut an.", {
            position: toast.POSITION.TOP_RIGHT
          });
          navigate('/login');
        } else if(error.response.status === 404) {
          toast.error(error.response.data.error || "404 - Not Found", {
            position: toast.POSITION.TOP_RIGHT
          });
        } else if(error.response.data === "Unzureichendes Guthaben") {
          onOpenPopup(PopupTypesEnum.creditRecharge)
        } else {
          toast.error(error.response.data.error || "Ein unerwarteter Fehler ist aufgetreten.", {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }
    }
  }

  const onBookNow = (register: boolean) => {
    if(register) {
      navigate("register");
      onClosePopup(PopupTypesEnum.askUserToRegister);
    } else {
      onFinishOrder(false);
    }
  }

  const onFinishOrder = async (useCredit: boolean) => {
    setIsLoadingFinishOrder(true);

    await postFinishOrder(
      `/order/finish?useCredit=${useCredit}`, // anonym -> true / logged in -> false
      {'Content-Type': 'application/json'},
      content?.order,
      false,
      onSuccessFinishOrder,
      onFailure
    );

    setIsLoadingFinishOrder(false);
  }
  //
  // BOOK ORDER END
  //

  return <>
    <Container component="main" maxWidth="md" disableGutters>
      <CssBaseline />
      <Box 
        component="form"
        onSubmit={handleSubmit}
        sx={{
          marginTop: 4
        }}
      >
        <Grid container spacing={2}>
          <Grid container item xs={12} sx={{marginLeft: "30px", marginRight: "30px", marginBottom: "15px"}} justifyContent="space-between">
            <TypographyHeading mb={1}>
              Auftrag buchen
            </TypographyHeading>

            {/* <Button 
              onClick={() => {
                const newOrder = fillNewOrder(content?.order);
                newOrder && content?.setOrder(newOrder);
              }} 
              color="success"
              >
              Fill Form
            </Button> */}
          </Grid>

          <OrderTypesComponent handleOrderTypeClick={handleOrderTypeClick} OrderTypeData={OrderTypeData}/>

          {content?.selectedOrderType === 1 ? 
            <ClientComponent disabled={false}/>  : ""
          }

          {content?.selectedOrderType === 1 ? 
            <AddressBlock
              setGeoData={setGeoData}
              chooseMapView={chooseMapView}
              pickupEmailError={pickupEmailError}
              pickupEmailErrorMsg={pickupEmailErrorMsg}
              deliveryEmailError={deliveryEmailError}
              deliveryEmailErrorMsg={deliveryEmailErrorMsg}
            /> : ""
          }

          {content?.selectedOrderType === 1 ? (
            <Grid item container xs={12} spacing={2} justifyContent="center">
              <Grid item xs={12} sm={3}>
                <ValueOfGoodComponent />
              </Grid>
              <Grid item xs={12} sm={3}>
                <DatePicker />
              </Grid>
            </Grid>
          ) : "" }

          {(
            (content?.selectedOrderType === 1 && content?.order.packages[0].pickupType === 0) || 
            (content?.selectedOrderType === 1 && content?.order.packages[0].deliveryType === 0)
          ) && 
            <PackageTypeComponent />
          }
          
          <Grid item xs={12} sm={12} textAlign={"center"} sx={{marginLeft: "25px", marginRight: "25px"}}>
            <Button
              type="submit"
              color="yellow"
              variant="contained"
              disabled={isLoadingFinishOrder || content?.selectedOrderType !== 1 || (content?.order?.packages[0].valueOfGood !== null && content?.order?.packages[0].valueOfGood >= 200)}
              sx={{ 
                mt: 3,
                mb: 2,
                width: 200,
                height: 50,
                textAlign: "right"
              }}
              >
                { isLoadingFinishOrder ? <CircularProgress size={24} color="inherit"/> :  "Auftrag buchen"} 
            </Button>
          </Grid>
        </Grid>

        <MapView 
          open={open.map}
          streetData={geoData}
          onClose={closeMapView}
        />

        <CreditNeededPopup 
          open={open.creditNeeded}
          onClose={() => onClosePopup(PopupTypesEnum.creditNeeded)}
        />

        <RedirectToPayment 
          open={open.redirectPayment}
          onClose={() => onClosePopup(PopupTypesEnum.redirectPayment)}
        />

        <ChoosePaymentMethod
          open={open.choosePaymentMethod}
          loading={isLoadingFinishOrder}
          onClose={() => onClosePopup(PopupTypesEnum.choosePaymentMethod)}
          onConfirmPayment={(useCredit: boolean) => onFinishOrder(useCredit)}
        />

        <CallVelocePopup
          open={open.callVeloce}
          onClose={() => onClosePopup(PopupTypesEnum.callVeloce)}
        />

        {open.askUserToRegister &&
          <AskUserToRegister
            open={open.askUserToRegister}
            loading={isLoadingFinishOrder}
            onClose={() => onClosePopup(PopupTypesEnum.askUserToRegister)}
            onBookNow={(register: boolean) => onBookNow(register)}
          />
        }
      </Box>
    </Container>
  </>
}