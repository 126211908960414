import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { produce } from "immer";
import { KnownNameData } from "../../interfaces/KnownNameData";
import { TamburiBox as TamburiBoxInterface } from "../../interfaces/TamburiBox";
import { AutocompleteTextField } from "../../common/StyledMuiComponents";
import { Client } from "../../interfaces/Order";
import useContent from "../../hooks/useContent";

type AutocompleteProps = {
  setSelectedBox: React.Dispatch<React.SetStateAction<TamburiBoxInterface | undefined>> | undefined,
  optionsNamesString: string[],
  options: KnownNameData | Client[] | undefined,
  getName: (name: string, id: number) => void,
  idString: string,
  textLabel: string,
  addressType: "to" | "from"
}

export const AutocompleteNameFields: React.FC<AutocompleteProps> = ({
  setSelectedBox,
  optionsNamesString,
  options,
  getName,
  idString,
  textLabel,
  addressType
}) => {
  const content = useContent();

  return <>
    <Grid item xs={12}>
      <Autocomplete 
        id={idString}
        options={optionsNamesString}
        freeSolo
        isOptionEqualToValue={(option, value) => option === value || value === ""}
        autoHighlight
        value={content?.order[`${addressType}Name`]}
        filterOptions={(x) => x}
        renderInput={(params) => (
          <AutocompleteTextField
            { ...params }
            label={textLabel}
            color="success"
            size="small"
            fullWidth
            required
          />
        )}
        onChange={(event, value) => {
          if(value){
            const nameOpt =  options as KnownNameData;
            const currentOption = nameOpt?.rememberedOrders?.find((option) => option.orderId === value);
            const currentOption2 = nameOpt?.rememberedTamburiBoxes?.find((option) => option.id.toString() === value);
            if(currentOption) {
              setSelectedBox && setSelectedBox(currentOption.remoteLocationInfo);
              content?.setOrder(prev => produce(prev, draft => {
                draft[`${addressType}Name`] = currentOption.name !== undefined ? currentOption.name : "";
                draft[`${addressType}PLZ`] = currentOption.plz !== undefined ? currentOption.plz : null;
                draft[`${addressType}Address`] = currentOption.address !== undefined ? currentOption.address : "";
                draft[`${addressType}City`] = currentOption.city !== undefined ? currentOption.city : "";
                draft[`${addressType}ContactNumber`] = currentOption.contactNumber !== undefined ? currentOption.contactNumber : "";
                // draft.orderInfo[`${addressType}LocationId`] = currentOption.locationId !== undefined ? currentOption.locationId : 0;
                /* if(addressType === "from") {
                  draft.pickupType = currentOption.remoteLocationInfo === null ? 1 : 0
                } else {
                  draft.deliveryType = currentOption.remoteLocationInfo === null ? 1 : 0
                } */
              }));
            }
            if(currentOption2) {
              setSelectedBox && setSelectedBox(currentOption2.remoteLocationInfo);
              content?.setOrder(prev => produce(prev, draft => {
                draft[`${addressType}PLZ`] = currentOption2.remoteLocationInfo?.zipCode !== undefined ? parseInt(currentOption2.remoteLocationInfo?.zipCode, 10) : null;
                draft[`${addressType}Address`] = currentOption2.remoteLocationInfo?.street !== undefined && currentOption2.remoteLocationInfo?.number !== undefined ? 
                  `${currentOption2.remoteLocationInfo?.street} ${currentOption2.remoteLocationInfo?.number}` : "";
                draft[`${addressType}City`] = currentOption2.remoteLocationInfo?.cityName !== undefined ? currentOption2.remoteLocationInfo?.cityName : "";
                draft[`${addressType}ContactNumber`] = "";
                draft[`${addressType}Name`] = "";
                // draft.orderInfo[`${addressType}LocationId`] = currentOption2.remoteLocationInfo?.locationId !== undefined ? currentOption2.remoteLocationInfo?.locationId : null;
                // addressType === "from" ? draft.pickupType = 0 : draft.deliveryType = 0;
              }));
            }
          } else {
            content?.setOrder(prev => produce(prev, draft => {
              draft[`${addressType}Name`] = "";
            }));
          }
        }}
        onInputChange={(event, newInputValue) => {
          content?.setOrder(prev => produce(prev, draft => {draft[`${addressType}Name`] = newInputValue;}));
          getName(newInputValue, 0);
        }}
        renderOption={(props, option) => {
          const nameOpt =  options as KnownNameData;
          const currentOption = nameOpt?.rememberedOrders?.find((opt) => opt.orderId === option);
          const currentOption2 = nameOpt?.rememberedTamburiBoxes?.find((opt) => opt.id.toString() === option);
          return (
            <li {...props}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  { currentOption ? currentOption?.name : currentOption2?.remoteLocationInfo?.locationName }
                  { currentOption2 ? " - Tamburi Kasten" : "" }
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    { currentOption ? currentOption?.address : `${currentOption2?.remoteLocationInfo?.street} ${currentOption2?.remoteLocationInfo?.number}` }
                    <br></br>
                    { currentOption?.remoteLocationInfo ? currentOption?.remoteLocationInfo?.locationName : "" }
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </Grid>
  </>
}