import { useEffect, useState } from "react";
import { CostCategories } from "../../interfaces/CostCategories";
import { TypographySubheading } from "../../common/StyledMuiComponents";
import { isTokenExpired } from "../../common/CheckToken";
import { produce } from "immer";
import Grid from "@mui/material/Grid"
import PackageTypeCard from "./PackageTypeCard"
import packageSmall from '../../../images/packageSmall.svg';
import packageMedium from '../../../images/packageMedium.svg';
import packageLarge from '../../../images/packageLarge.svg';
import packageSmallWhite from '../../../images/packageSmallWhite.svg';
import packageMediumWhite from '../../../images/packageMediumWhite.svg';
import packageLargeWhite from '../../../images/packageLargeWhite.svg';
import useProfile from "../../hooks/useProfile"
import useAxios from "../../hooks/useAxios";
import useContent from "../../hooks/useContent";

export const PackageTypeComponent = () => {
  const [costCategories, setCostCategories] = useState<CostCategories[]>([]);
  const profile = useProfile();
  const content = useContent();
  const { fetchData } = useAxios();

  useEffect(() => {
    !isTokenExpired() && fetchData("/payment/CheckPrices", { 'Content-Type': 'application/json' }, true, onSuccess, () => {})
  },[])

  const onSuccess = (data: CostCategories[]) => {
    setCostCategories(data)
  }

  const PackageTypeData = [
    { id: 1, title: 'Klein', kg: <><span>unter 31,5 kg</span></>,size: <>cm 10 x 40 x 60</>, tarif: <>Tarif: {costCategories[0]?.price} €</>,
      image: profile?.colorMode === "light" ? packageSmall : packageSmallWhite, height: "100" },
    { id: 2, title: 'Mittel', kg: <><span>unter 31,5 kg</span></>, size: <>cm 20 x 40 x 60</>, tarif: <>Tarif: {costCategories[1]?.price} €</>,
      image: profile?.colorMode === "light" ? packageMedium : packageMediumWhite, height: "100" },
    { id: 3, title: 'Groß', kg: <><span>unter 31,5 kg</span></>, size: <>cm 45 x 40 x 60</>, tarif: <>Tarif: {costCategories[2]?.price} €</>,
      image: profile?.colorMode === "light" ? packageLarge : packageLargeWhite, height: "150" },
    /* { id: 4, title: 'Extra', kg: <><span>bis 1000 kg</span></>, size: <>Hauszustellung</>, tarif: <>Tarif: {costCategories[3]?.price} €</>,
      image: profile?.colorMode === "light" ? packageExtraBlack : packageExtraWhite, height: "150" }, */
  ];

  const handlePackageTypeClick = (id: number) => {
    content?.setOrder(prev => produce(prev, draft => {
      draft.packages[0].packageType = id;
    }))
  }

  return <>
    <Grid item xs={12}>
      <Grid container item spacing={2} sx={{justifyContent: "center"}}>
        <Grid container item spacing={2} sx={{justifyContent: "center"}}>
          <Grid item xs={12} mt={3}>
            <TypographySubheading sx={{float: "none"}}>
              So groß ist meine Sendung (bitte auswählen)
            </TypographySubheading>
          </Grid>

          {PackageTypeData.map((card, id) => (
            <Grid item key={card.id} xs={12} sm={2.3}>
              <PackageTypeCard
                title={card.title}
                kg={card.kg}
                size={card.size}
                tarif={card.tarif}
                selected={content?.order.packages[0].packageType === card.id}
                styling={{minHeight: "150px"}}
                image={card.image}
                height={card.height}
                onClick={() => handlePackageTypeClick(card.id)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  </>
}