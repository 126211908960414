import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import "../styles/Impressum.css";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const Imprint = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.substring(1);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  return <>
    <Container component="main">
      <CssBaseline/>
      <Box className="impressum-container" sx={{ width: '100%', textAlign: "justify" }}>
        <Typography variant="h2" mt={3} gutterBottom>
          Impressum
        </Typography>

        <Typography variant="h5" gutterBottom>
          <b>offenlegung gemäß e-commerce-gesetz, unternehmensgesetzbuch und mediengesetz</b>
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          Für den inhalt verantwortlicher, Herausgeber und Medieninhaber:<br/><br/>
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          Veloce Liefert GmbH
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          Döblerhofstraße 16
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          A-1110 Wien
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          T: +43 1 52117 <a href="mailto:tempo@veloce.at">tempo@veloce.at</a>
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          Geschäftsführer: Paul Brandstätter<br/><br/>
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          Firmenbuchnummer FN43875y, HG Wien Wirtschaftskammer Österreich<br/>
          Gewerbebehörde/Aufsichtsbehörde: Magistrat der Stadt Wien<br/>
          Unternehmensgegenstand: Fahrradbotendienst, Spedition einschließlich Transportagentur, Güterbeförderung<br/>
          Gesetzliche berufsmäßige Bestimmungen: Gewerbeordnung 1994, abrufbar auf:<br/>
          <a href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10007517" target="_blank" rel="noreferrer">
            https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10007517
          </a> 
          <br/>
          UID ATU 15403800
        </Typography>

        <Typography variant="h5" gutterBottom mt={3}>
          <b>haftungsausschluss</b>
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          Die veloce liefert gmbh übernimmt keine Garantie für die Richtigkeit, Vollständigkeit 
          und Aktualität der Angaben auf der Homepage. Eine Haftung wird ausgeschlossen. Die 
          Benützung dieser Website erfolgt auf eigene Verantwortung; wir haften nicht für 
          Schäden oder Verluste, die durch die hier angebotenen Informationen oder die zum 
          Download zur Verfügung gestellten Dateien direkt oder indirekt verursacht werden. Die 
          veloce liefert gmbh übernimmt keine Verantwortung für den Inhalt der verlinkten Seiten.
        </Typography>

        <Typography variant="h5" gutterBottom mt={3}>
          <b id='privacyPolicy'>information zum datenschutz und zur datenschutz-grundverordnung (dsgvo)</b>
        </Typography>

        <Typography variant="body1" fontSize={"large"}>
          Veloce nimmt Datenschutz sehr ernst und respektiert das Recht auf Schutz personenbezogener Daten. 
          Wir verarbeiten nur jene personenbezogenen Daten, die wir von unseren Kunden im Zuge der Auftragsbuchung 
          erhalten und für die Erbringung unserer Logistik-Dienstleistungen benötigen. Unsere Kunden sind nicht 
          verpflichtet personenbezogene Daten, die für die Auftragserfüllung nicht erforderlich sind, an uns zu 
          übergeben. Mit der Übergabe der Daten an veloce ist die Einwilligung zu deren gesetzteskonformen 
          Verarbeitung verbunden. Veloce verarbeitet diese Daten im Einklang mit den Bestimmungen der Europäischen 
          Datenschutz-Grundverordnung (DSGVO) und dem Österreichischen Datenschutzgesetz (DSG), zur Erfüllung des 
          Transportauftrages für unsere Kunden. Wir geben Daten nicht an Dritte weiter, es sei denn es ist für die 
          Auftragsdurchführung erforderlich und dann nur in dem Ausmaß, wie es für die Auftragsdurchführung 
          erforderlich ist. Diese (Versandunternehmen, IT-Dienstleister, kontoführendes Kreditinstitut, …) sind 
          verpflichtet Daten vertraulich zu behandeln und nur im Rahmen der Leistungserbringung zu verarbeiten. 
          Nur bei unbedingter gesetzlicher Verpflichtung geben wir personenbezogene Daten an Behörden oder Ämter 
          weiter. Wir speichern personenbezogene Daten soweit es für die Dienstleistungserbringung und gemäß 
          gesetzlicher Aufbewahrungs- und Dokumentationspflichten erforderlich ist. Betroffene Personen haben im 
          gesetzlichen Ausmaß das Recht, Auskunft, Berichtigung, Löschung oder Einschränkung der Verarbeitung der 
          über sie gespeicherten personenbezogenen Daten zu verlangen, sowie das Recht auf Widerruf einer erteilten 
          Einwilligung und ein Widerspruchsrecht gegen die Verarbeitung. Unsere verantwortliche Stelle für 
          Datenschutz ist unter datenschutz@veloce.at zu erreichen. Darüberhinaus können Beschwerden auch an 
          unsere Geschäftsführung (pb@veloce.at) oder die Österreichische Datenschutzbehörde, Wickenburggasse 8-10, 
          1080 Wien, E-Mail dsb@dsb.gv.at, gerichtet werden. Bei Bedarf aktualisieren wir diese Informationen zum 
          Datenschutz. Die jeweils aktuellste Version ist unter www.veloce.at/impressum abrufbar. 1.5.2018  
        </Typography>

        <Typography variant="h5" gutterBottom mt={3}>
          <b>google analytics</b>
        </Typography>

        <Typography variant="body1" fontSize={"large"} gutterBottom>
          Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). 
          Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und 
          die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten 
          Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in 
          den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite, 
          wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen 
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird 
          die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers 
          dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über 
          die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene 
          Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser 
          übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Sie können die Speicherung der 
          Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, 
          dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. 
          Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen 
          Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das 
          unter dem folgenden Link verfügbare Browser-Plugin herunterladen und 
          installieren: <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer">http://tools.google.com/dlpage/gaoptout?hl=de</a>.
        </Typography>
      </Box>
    </Container>
  </>
}