import { StyledOutlinedInput } from "../../common/StyledMuiComponents";
import { useEffect, useState } from "react";
import { produce } from "immer";
import { usePopup } from "../../context/PopupProvider";
import { PopupTypesEnum } from "../../interfaces/PopupTypes";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import useContent from "../../hooks/useContent";

export const ValueOfGoodComponent = () => {
  const [amount, setAmount] = useState("");
  const content = useContent();
  const { onOpenPopup } = usePopup();

  useEffect(() => {
    if(content?.order?.packages[0].valueOfGood !== undefined && content?.order?.packages[0].valueOfGood !== null && content?.order?.packages[0].valueOfGood !== 0)
      setAmount(content?.order?.packages[0].valueOfGood?.toString() || "")
  }, [content?.order?.packages[0].valueOfGood])
  
  const handleValueOfGoodChange = (value: number | undefined) => {
    content?.setOrder(prev => produce(prev, draft => {
      draft.packages[0].valueOfGood = value || 0
    }))

    if(value && value >= 200) {
      onOpenPopup(PopupTypesEnum.callVeloce);
    }
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Backspace' && amount.length === 1) {
      setAmount('');
    }
  };

  return <>
    <Grid item xs={12} mt={3}>
      <FormControl variant="outlined" color="success">
        <StyledOutlinedInput
          id="creditAmount"
          size="small"
          value={amount}
          onChange={(e) => {
            const userInput = e.target.value;
            const regex = /^[0-9]+([,.])?([0-9]{1,2})?$/;
            if (regex.test(userInput)) {
              handleValueOfGoodChange(parseInt(userInput, 10))
            }
          }}
          onKeyDown={handleKeyPress}
          placeholder="Warenwert"
          endAdornment={<InputAdornment position="end">€</InputAdornment>}
          inputProps={{
            'aria-label': 'euro',
            inputMode: 'numeric',
          }}
        />
      </FormControl>
    </Grid>
  </>
}